import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsDebug} from '@ionic-native/is-debug';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {Network} from '@ionic-native/network/ngx';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {CodePush} from '@ionic-native/code-push/ngx';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {FirebaseMessaging} from '@ionic-native/firebase-messaging/ngx';
import {FirebaseAnalytics} from '@ionic-native/firebase-analytics/ngx';
import {NativePageTransitions} from '@ionic-native/native-page-transitions/ngx';
import {File} from '@ionic-native/file/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {OpenNativeSettings} from '@ionic-native/open-native-settings/ngx';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {Device} from '@ionic-native/device/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {ImagePicker} from '@ionic-native/image-picker/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {FirebaseConfig} from '@ionic-native/firebase-config/ngx';
import {Facebook} from '@ionic-native/facebook/ngx';
import {AppPreferences} from '@ionic-native/app-preferences/ngx';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        IsDebug,
        Network,
        GooglePlus,
        Geolocation,
        StatusBar,
        SplashScreen,
        CodePush,
        Deeplinks,
        SocialSharing,
        FirebaseMessaging,
        FirebaseAnalytics,
        NativePageTransitions,
        File,
        InAppBrowser,
        FileOpener,
        OpenNativeSettings,
        Keyboard,
        Device,
        AppVersion,
        ImagePicker,
        Camera,
        FirebaseConfig,
        Facebook,
        AppPreferences
    ]
})
export class CordovaPluginsModule { }
