import {Component, OnInit} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-location-permission',
  templateUrl: './location-permission.component.html',
  styleUrls: ['./location-permission.component.scss'],
})
export class LocationPermissionComponent implements OnInit {

  constructor(private modalController: ModalController,
              private helperService: HelperService) { }

  ngOnInit() {}

  closeModal(message?: string) {
    this.modalController.dismiss(message).then();
  }

  enableLocationClicked() {
    this.helperService.openSettings('application_details').then().finally(() => {
      this.closeModal('enableClicked');
    });
  }

  cancelClicked() {
    this.closeModal('cancelClicked');
  }

}
