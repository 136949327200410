import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API} from '../shared/services/api.url';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Storage} from '@ionic/storage';
import {CartData, CartSummery, PlaceOrderRequest, Product, Timeslot} from './pickup.model';
import {Platform} from '@ionic/angular';
import * as _ from 'lodash';
import {OrderList} from '../models/OrderList';
import {ProductService} from '../product/product.service';
import {HelperService} from '../shared/services/helper.service';
import {FacebookEvents, MyEvents} from '../shared/my-events.const';
import {AnalyticsService} from '../shared/services/analytics.service';
import {Router} from '@angular/router';
import {TradeType} from '../shared/models/common.model';

@Injectable({
    providedIn: 'root'
})
export class PickupService implements OnDestroy {

    catalogData = new BehaviorSubject<any>([]);
    cartData = new BehaviorSubject<CartData>(null);
    cities = new BehaviorSubject([]);
    private cartDataSubscription: Subscription = new Subscription();
    private tradeTypes = TradeType;

    allOrders: OrderList;

    constructor(private httpClient: HttpClient,
                private storage: Storage,
                plt: Platform,
                private helper: HelperService,
                private productService: ProductService,
                private analyticsService: AnalyticsService,
                private router: Router) {
        plt.ready().then(() => {
            this.storage.get('active_pickup_cart').then(data => {
                if (data) {
                    this.setCartData(data);
                }
                this.setSyncOnCartDataWithStorage();
            }, error => {
                this.setSyncOnCartDataWithStorage();
            });
        });
    }

    private setSyncOnCartDataWithStorage() {
        this.cartDataSubscription = this.cartData.subscribe((data) => {
            if (data) {
                this.storage.set('active_pickup_cart', data).then(() => {
                    console.log('Data Saved successfully to storage');
                }, (error) => {
                    console.log('Failed to save data to storage', error);
                });
            }
        });
    }

    getTimeSlots(franchiseId, storeZip) {
        return this.httpClient.get(`${API.TIME_SLOTS()}?franchiseId=` + franchiseId + `&zip=` + storeZip)
            .map((data: any) => {
                return data;
            });
    }


    /*getCatalogs(storeid) {
        return this.httpClient.get(`${API.CATALOG()}?onlyCustomerItems=true&storeId=${storeid}`)
            .map((data: any) => {
                this.catalogData.next(data);
                return data;
            });
    }*/

    getOrders(orderRequest: PlaceOrderRequest) {
        return this.httpClient.post(`${API.ORDERS()}`, orderRequest)
            .map((data: any) => {
                this.getOrdersByFilters(false).subscribe();
                return data;
            });
    }

    checkPickupValidity(cartobj) {
        return this.httpClient.post(`${API.PICKUPVALIDITY()}`, cartobj)
            .map((data: any) => {
                // console.log(data);
                return data;
            }, (error) => {
                // console.log(error);
                return error.status;
            });
    }

    setCartData(cartData: CartData) {
        this.recalculateCartSummery(cartData.cartItems, cartData.cartSummery);
        this.cartData.next(cartData);
    }

    emptyCart() {
        this.cartData.next(new CartData(null, [], new CartSummery(0, 0, 0, 0, false)));
        this.catalogData.next([]);
    }

    setCartStore(storeData: any) {
        // this.cartData.next({...this.cartData.value, storeData: storeData});
        // empty cart when store is changed
        // also fetch time slots
        const promise = new Promise((resolve, reject) => {
            this.getTimeSlots(storeData.partnerInfo.id, storeData.addressInfo.zipcode).subscribe((data) => {
                storeData.timeslots = data;
                if (this.cartData.value && this.cartData.value.storeData && this.cartData.value.storeData.id === storeData.id) {
                    this.cartData.next(new CartData(storeData, this.cartData.value.cartItems, this.cartData.value.cartSummery));
                } else {
                    this.catalogData.next([]);
                    this.cartData.next(new CartData(storeData, [], new CartSummery(0, 0, 0, 0, false)));
                }
                resolve();
            }, (err) => {
                reject(err);
            });
        });
        return promise;
    }

    setCartItems(cartItems) {
        // this.cartData.value.cartSummery = this.recalculateCartSummery(cartItems, this.cartData.value.cartSummery);
        this.cartData.next({
            ...this.cartData.value, cartItems,
            cartSummery: this.recalculateCartSummery(cartItems, this.cartData.value.cartSummery)
        });
    }

    setCartItem(item) {
        // check if item already exists else push
        if (this.cartData.value.cartItems.filter((pr) => {
            return pr.id === item.id;
        }).length > 0) {
            const elementPos = this.cartData.value.cartItems.map(x => {
                return x.id;
            }).indexOf(item.id);
            this.cartData.value.cartItems[elementPos] = item;
        } else {
            this.cartData.value.cartItems.push(item);
        }
        this.cartData.value.cartSummery = this.recalculateCartSummery(this.cartData.value.cartItems, this.cartData.value.cartSummery);
        this.cartData.next({
            ...this.cartData.value,
            cartItems: this.cartData.value.cartItems,
            cartSummery: this.cartData.value.cartSummery
        });
    }

    removeCartItem(itemId: string) {
        this.cartData.value.cartItems = this.cartData.value.cartItems.filter((pr) => {
            return pr.id !== itemId;
        });
        this.cartData.value.cartSummery = this.recalculateCartSummery(this.cartData.value.cartItems, this.cartData.value.cartSummery);
        this.cartData.next({
            ...this.cartData.value,
            cartItems: this.cartData.value.cartItems,
            cartSummery: this.cartData.value.cartSummery
        });
    }

    recalculateCartSummery(cartItems: any[], cartSummery: CartSummery) {
        if (cartSummery && cartItems.length > 0) {
            cartSummery = new CartSummery(0, 0, 0, 0, false);
            cartItems.forEach((value, key) => {
                if (value.tradeType === this.tradeTypes.S) {
                    cartSummery.totalPriceToPay = cartSummery.totalPriceToPay + (value.price * value.quantity);
                } else {
                    cartSummery.totalPrice = cartSummery.totalPrice + (value.price * value.quantity);
                }
                cartSummery.totalQty = cartSummery.totalQty + value.quantity;
                cartSummery.totalWeight = cartSummery.totalWeight + ((value.weight * value.quantity) / 1000);
                if (value.tradeType === 'S') {
                    cartSummery.containsItemWithNegativePricing = true;
                }
            });
            return cartSummery;
        } else {
            return new CartSummery(0, 0, 0, 0, false);
        }
    }

    recalculateCartSummeryAndSave() {
        if (this.cartData.value && this.cartData.value.cartItems.length > 0) {
            // this.cartData.value.cartSummery =
            // this.recalculateCartSummery(this.cartData.value.cartItems, this.cartData.value.cartSummery);
            this.cartData.next({
                ...this.cartData.value,
                cartSummery: this.recalculateCartSummery(this.cartData.value.cartItems, this.cartData.value.cartSummery)
            });
        }
    }

    getCartDetails() {
        this.cartData.getValue();
    }

    getOrdersByFilters(isLoadMore ?: boolean) {
        return new Observable(observer => {
            const orderObj: OrderList = new OrderList();
            if (isLoadMore) {
                orderObj.page++;
            }
            if (orderObj && orderObj.result.length && !isLoadMore) {
                observer.next(orderObj);
                return observer.complete();
            }

            this.query({
                page: orderObj.page,
                size: 10,
                sort: 'createdDate,desc',
            }).subscribe(orders => {
                orderObj.totalItemCount = _.toNumber(orders.headers.get('X-Total-Count'));
                _.merge(orderObj.result, orders.body);
                if (isLoadMore) {
                    observer.next(orders.body);
                    observer.complete();
                } else {
                    observer.next(orderObj);
                    observer.complete();
                }
            });
        });
    }

    query(filterParams) {
        const options = new HttpParams({fromObject: filterParams});
        return this.httpClient.get<any>('/core/customer/orders', {
            params: options,
            observe: 'response'
        }).map((data: any) => {
            return data;
        });
    }

    setCartTimeslot(selectedTime: Timeslot) {
        this.cartData.next({...this.cartData.value, selectedTimeslot: selectedTime});
    }

    getPlaceOrderRequest() {
        const placeOrderRequest = new PlaceOrderRequest([],
            this.cartData.value.storeData.addressInfo,
            this.cartData.value.selectedTimeslot,
            this.cartData.value.storeData.id
        );
        this.cartData.value.cartItems.forEach((value) => {
            placeOrderRequest.orderItems.push({
                itemId: value.id,
                qty: value.quantity,
                total: value.total,
            });
        });
        return placeOrderRequest;
    }

    getCatalogRootCategoriesForSelectedStore(channel: string) {
        if (this.cartData.value &&
            this.cartData.value.storeData &&
            this.catalogData.value.length === 0) {
            this.productService.fetchProductCategoriesForStore(this.cartData.value.storeData.id, channel).subscribe((data) => {
                data = data ? _.orderBy(data, 'seq', 'asc') : data;
                this.catalogData.next(data);
            }, (err) => {
            });
        }
    }

    getProductCategoryDataForSelectedStore(catData, pathToCat, channel: string) {
        if (this.cartData.value &&
            this.cartData.value.storeData &&
            catData &&
            catData.items === undefined &&
            catData.subCategories === undefined) {
            this.productService.fetchProductCategoryDataForStore(catData.id, this.cartData.value.storeData.id, channel).subscribe(
                (data) => {
                    if (data) {
                        data.items = data.items ? _.orderBy(data.items, 'seq', 'asc') : data;
                        data.subCategories = data.subCategories ? _.orderBy(data.subCategories, 'seq', 'asc') : data;
                        // this.catalogData.next(data);
                        this.helper.updateObject(this.catalogData.value, data.items, pathToCat + '>items');
                        this.helper.updateObject(this.catalogData.value, data.subCategories, pathToCat + '>subCategories');
                        this.catalogData.next(this.catalogData.value);
                    }
                }, (err) => {
                });
        }
    }

    getProductCategoryDescendentItemsForSelectedStore(catData, pathToCat, channel) {
        if (this.cartData.value &&
            this.cartData.value.storeData &&
            (catData.items === undefined || (catData.items && catData.items.length === 0))) {
            this.productService.fetchProductCategoryDescendantItems(catData.id, this.cartData.value.storeData.id, channel).subscribe(
                (data) => {
                    if (data) {
                        data.items = data.items ? _.orderBy(data.items, 'seq', 'asc') : data;
                        /*data.items.forEach((item, key) => {
                            if (key % 2) {
                                item.negativePricing = true;
                            }
                        });*/


                        this.helper.updateObject(this.catalogData.value, data, pathToCat + '>items');
                        // pushing empty cat array is required to show not items message in listing.
                        this.helper.updateObject(this.catalogData.value, [], pathToCat + '>subCategories');
                        this.catalogData.next(this.catalogData.value);
                    }
                }, (err) => {
                });
        }
    }

    getProductsByAttributeIdsForSelectedStore(attrValues: any[]) {
        return this.productService.fetchProductsByAttributeIds(attrValues, this.cartData.value.storeData.id, 'B2B');
    }

    getRecentlyUsedProductsForSelectedStore() {
        return this.productService.fetchRecentlyUsedProducts(this.cartData.value.storeData.id, 'B2B').toPromise();
    }

    showProductQtyEditForPickupRequest(productData: Product, navigateToRecent: boolean, showBackdrop?: boolean): Promise<HTMLIonModalElement> {
        return this.productService.showProductAddToCartModal(productData, null, null, showBackdrop).then((modalElement) => {
                modalElement.onDidDismiss().then(
                    result => {
                        if (result.data) {
                            if (result.data.selectedQty > 0) {

                                productData.quantity = result.data.selectedQty;
                                productData.total = Number(productData.price) * productData.quantity;
                                this.setCartItem(productData);
                                this.analyticsService.logEvent(MyEvents.ITEM_ADDED,
                                    {
                                        itemName: productData.name, itemQty: productData.quantity,
                                        itemTotal: productData.total, context: MyEvents.CONTEXT.REQUEST_PICKUP
                                    });
                                this.analyticsService.logFacebookEvent(FacebookEvents.StandardEvents.EVENT_NAME_ADDED_TO_CART, {
                                    CONTENT_TYPE: 'PRODUCT_ITEM',
                                    CONTENT_ID: productData.id,
                                    CONTENT: productData.name,
                                    CURRENCY: 'INR'
                                });
                                if(navigateToRecent) {
                                    this.router.navigate([window.ROUTES.CREATE_PICKUP_SELECTED_PRODUCTS_FROM_RECENTLY_USED_TRUE], {  queryParamsHandling: 'preserve'}).then();
                                } else {
                                    this.router.navigate([window.ROUTES.CREATE_PICKUP_SELECTED_PRODUCTS], {  queryParamsHandling: 'preserve'}).then();
                                }
                            } else {
                                this.helper.showToast('Please input valid quantity', 'dark').then();
                            }
                        }
                    }
                );
                return modalElement;
            }
        );
    }

    searchProductsForSelectedStore(query) {
        return this.productService.searchAndFetchProductsForStore(query, this.cartData.value.storeData.id, 'B2B');
    }

    checkDescendentItemsExistsInProductCategory(pathToCat) {
        /*if (this.catalogData.value) {
            const catData = eval('this.catalogData.value' + pathToCat);
            if (catData && catData.items) {
                return !(catData.items.length);
            } else {
                return false;
            }
        } else {
            return false;
        }*/
    }

    byString(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        const a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    ngOnDestroy() {
        if (this.cartDataSubscription) {
            this.cartDataSubscription.unsubscribe();
        }
    }
}

