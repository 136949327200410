export interface LoginRequest {
    email: string;
    password: string;
    rememberMe: boolean;
}

export class LoginRequest implements LoginRequest {
    constructor(
        public email: string,
        public password: string,
        public rememberMe: boolean = true,
    ) {}
}

export interface LoginSendOtp {
    email?: string;
    mobile?: string;
}

export interface RegisterSendOtp {
    email?: string;
    mobile?: string;
}

export interface RegisterVerifyOtp {
    email?: string;
    mobile?: string;
    verificationCode?: string;
}


export interface LoginSendOtpReqResp {
    email?: string;
    mobile?: string;
    name?: any;
    password?: any;
    verificationCode?: any;
    resetKey?: any;
    sendOtpToMobile?: boolean;
    sendOtpToEmail?: any;
    emailConfirmation?: any;
    mobileConfirmation?: any;
    authentication?: any;
    createPassword?: any;
}


export interface LoginVerifyOtp {
    email?: string;
    mobile?: string;
    verificationCode?: string;
}

export interface CreatePasswordRequest {
    key: string;
    newPassword: string;
}
