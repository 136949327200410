import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {GLOBALS} from '../../constants';
import {Events, LoadingController, ToastController} from '@ionic/angular';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {File} from '@ionic-native/file/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {OpenNativeSettings} from '@ionic-native/open-native-settings/ngx';
import {RCYL_ORDERS_GLOBALS} from 'rcyl-orders';
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor(private toastCtrl: ToastController,
                private statusBar: StatusBar,
                private file: File,
                private iab: InAppBrowser,
                private fileOpener: FileOpener,
                private loadingController: LoadingController,
                private openNativeSettings: OpenNativeSettings,
                private jwtService: JwtHelperService,
                private events: Events) {
        this.events.subscribe(RCYL_ORDERS_GLOBALS.EVENTS_TO_PUBLISH.SHOW_TOAST, (data) => {
            if (data && data.message) {
                this.showToast(data.message, data.color).then();
            }
        });
        this.events.subscribe(RCYL_ORDERS_GLOBALS.EVENTS_TO_PUBLISH.VIEW_BG, (data) => {
            if (data && typeof data === 'string') {
                this.statusBar.backgroundColorByHexString(data);
            }
        });
    }

    decodeRawToken(token) {
        const helperJwt = new JwtHelperService();
         return helperJwt.decodeToken(token);
    }

    getHttpError(err: HttpErrorResponse): string {
        let message;
        if (err) {
            if (err.headers && err.headers.get(GLOBALS.HTTP_APP_KEY + '-error')) {
                message = err.headers.get(GLOBALS.HTTP_APP_KEY + '-error');
            } else if (err.status === 500) {
                message = 'Something went wrong. please try again!!!';
            } else if (err.status === 503) {
                message = 'Server is not reachable. please try after some time.';
            } else if (err.status === 0) {
                message = 'Request timeout. Please check if you are connected to Internet.';
            } else if (err.status === 404) {
                message = 'Requested resource not found.';
            } else {
                message = err.status + ' ' + err.statusText;
            }
        }
        console.log('Error', message);
        return message;
    }

    getHttpErrorKey(err: HttpErrorResponse, showToast?: boolean) {
        const errorCode = '';
        if (err) {
            if (err.headers && err.headers.get(GLOBALS.HTTP_APP_KEY + '-error-key')) {
                return err.headers.get(GLOBALS.HTTP_APP_KEY + '-error-key');
            }
        }
        return errorCode;
    }

    showHttpError(err: HttpErrorResponse, showToast?: boolean, duration?) {
        const message = this.getHttpError(err);
        if (showToast) {
            this.showToast(
                message,
                (err && err.status >= 500) ? 'danger' : 'warning' ,
                duration
            ).then();
        }
    }

    async showToast(message: string, color?: string, duration?: number, closeBtnText?: string) {
        const toast = await this.toastCtrl.create({
            message,
            duration: duration ? duration : 5000,
            color: color ? color : 'dark',
            position: 'top',
            showCloseButton: true,
            cssClass: 'custom-toast',
            closeButtonText: closeBtnText ? closeBtnText : 'DISMISS',
            animated: false,
        });

        toast.present();
        if (color) {
            this.setStatusBarColorByIonicColor(color);
        }
        toast.onDidDismiss().then(() => {
            if (color) {
                this.setStatusBarColorByIonicColor('');
            }
        });
        return toast;
    }

    /*
    * @input options
    *
    * @example options input
    * {
            header: 'Toast header',
            message: 'Click to Close',
            position: 'top',
            buttons: [
                {
                    side: 'start',
                    icon: 'star',
                    text: 'Favorite',
                    handler: () => {
                        console.log('Favorite clicked');
                    }
                }, {
                    text: 'Done',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }
            ]
        }
    *
    * */
    async presentToastWithOptions(options) {
        const toast = await this.toastCtrl.create(options);
        toast.present();
        return toast;
    }

    passwordConfirming(c: AbstractControl): { invalid: boolean } {
        if (c.get('password').value !== c.get('confirmPassword').value) {
            return {invalid: true};
        }
    }

    getGroupBy(data: any[], key: string) {
        const groupedData = {};
        data.forEach((a) => {
            groupedData[a[key]] = groupedData[a[key]] || [];
            groupedData[a[key]].push(a);
        });
        return groupedData;
    }

    setStatusBarColorByIonicColor(color: string) {
        switch (color) {
            case 'dark':
                this.statusBar.backgroundColorByHexString('#27353C');
                break;
            case 'danger':
                this.statusBar.backgroundColorByHexString('#D85757');
                break;
            case 'warning':
                this.statusBar.backgroundColorByHexString('#FCD974');
                break;
            case 'page-bg':
                this.statusBar.backgroundColorByHexString('#F2F2F2');
                break;
            default:
                this.statusBar.backgroundColorByHexString('#ffffff');
        }
    }

    getFilterDate(data: any[]) {
        if (data) {
            const filterData = data.filter((a) => {
                if (a.id === 'PLA101') {
                    return a;
                }
            });
            return filterData[0].id;
        }
    }

    getFieldFromHttpResponse(resp: HttpResponse<any>, fieldName: string) {
        /*if (resp && resp.headers && fieldName) {
            return  resp.headers.get(fieldName);
        }*/
        return (resp && resp.headers && fieldName) ? resp.headers.get(fieldName) : null;
    }

    downloadFile(fileName: string, fileData: Blob) {
        return this.file.writeFile(this.file.cacheDirectory, fileName, fileData, {replace: true}).then(
            (data) => {
                return data;
            },
            (e) => {
                this.showToast('Failed to download file. Please try again.', 'red').then();
            }
        );
    }

    downloadAndOpenFile(fileName: string, fileData: Blob) {
        return this.downloadFile(fileName, fileData).then((data) => {
            return this.openFile(data.nativeURL, 'application/pdf').then(() => {
            }, () => {
                this.showToast('Failed to open file', 'danger').then();
            });
        });
    }


    openInAppBrowser(urlToOpen: string, target?: string) {
        this.iab.create(urlToOpen, target ? target : '_blank', 'location=yes,enableViewportScale=yes,hidden=no');
    }

    openFile(filePath: string, mimeType: string) {
        return this.fileOpener.open(filePath, mimeType);
    }

    async createLoader(message: string) {
        return await this.loadingController.create({
            message: message ? message : 'Loading...',
            mode: 'ios'
        });
    }

    openSettings(settingName: string) {
        return this.openNativeSettings.open(settingName).then(() => {
        }, () => {
            this.showToast('Failed to open settings', 'danger').then();
        });
    }

    updateObject(object, newValue, path) {
        const stack = path.split('>');
        while (stack.length > 1) {
            object = object[stack.shift()];
        }
        object[stack.shift()] = newValue;
    }

    versionCompare(v1, v2, options?) {
        const lexicographical = options && options.lexicographical;
        const zeroExtend = options && options.zeroExtend;
        let v1parts = v1.split('.');
        let v2parts = v2.split('.');

        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }

        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return NaN;
        }

        if (zeroExtend) {
            while (v1parts.length < v2parts.length) { v1parts.push('0'); }
            while (v2parts.length < v1parts.length) { v2parts.push('0'); }
        }

        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }

        for (let i = 0; i < v1parts.length; ++i) {
            if (v2parts.length === i) {
                return 1;
            }

            if (v1parts[i] === v2parts[i]) {
                continue;
            } else if (v1parts[i] > v2parts[i]) {
                return 1;
            } else {
                return -1;
            }
        }

        if (v1parts.length !== v2parts.length) {
            return -1;
        }

        return 0;
    }

    conditionalValidator(condition: (() => boolean), validator: ValidatorFn): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} => {
            if (! condition()) {
                return null;
            }
            return validator(control);
        };
    }

    trimAllLevelZero(obj: any) {
        Object.keys(obj).map(k => obj[k] = typeof obj[k] === 'string' ? obj[k].trim() : obj[k]);
        return obj;
    }

    parseAndShowGoogleLoginError(err) {
        let errorMessage = '';
        switch (err) {
            case 2:
                errorMessage = 'The installed version of Google Play services is out of date. ' +
                    'Please install/update latest version';
                break;
            case 3:
                errorMessage = 'The installed version of Google Play services has been disabled on this device.' +
                    ' Please enable it to continue.';
                break;

            case 4:
                errorMessage = 'The client attempted to connect to the service but the user is not signed in.';
                break;
            case 5:
                errorMessage = 'The client attempted to connect to the service with an invalid account name specified.';
                break;
            case 6:
                errorMessage = 'Completing the operation requires some form of resolution.';
                break;
            case 7:
                errorMessage = 'A network error occurred. Please check if you are connected to Internet or try after some time.';
                break;
            case 8:
                errorMessage = 'An internal error occurred. Please try after sometime.';
                break;
            case 10:
                errorMessage = 'The application is misconfigured.';
                break;
            case 13:
                errorMessage = 'The operation failed with no more detailed information.';
                break;
            case 14:
                errorMessage = 'A call was interrupted while waiting and did not run to completion.';
                break;
            case 15:
                errorMessage = 'Request timeout. Please check if you are connected to Internet.';
                break;
            case 16:
                errorMessage = 'Client disconnected connection or canceled';
                break;
            case 17:
                errorMessage = 'Your device is not supported for google login. Please try alternative methods.';
                break;
        }
        this.showToast(errorMessage ? errorMessage : err, 'danger').then();
    }

    getAllUrlParams(url) {
        let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
        const obj = {};

        if (queryString) {
            queryString = queryString.split('#')[0];
            const arr = queryString.split('&');

            for (let i = 0; i < arr.length; i++) {
                const a = arr[i].split('=');
                let paramName = a[0];
                let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

                paramName = paramName;
                if (typeof paramValue === 'string') {
                    paramValue = paramValue;
                }

                if (paramName.match(/\[(\d+)?\]$/)) {
                    const key = paramName.replace(/\[(\d+)?\]/, '');
                    if (!obj[key]) {
                        obj[key] = [];
                    }

                    if (paramName.match(/\[\d+\]$/)) {
                        const index = /\[(\d+)\]/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    } else {
                        obj[key].push(paramValue);
                    }
                } else {
                    if (!obj[paramName]) {
                        obj[paramName] = paramValue;
                    } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]];
                        obj[paramName].push(paramValue);
                    } else {
                        obj[paramName].push(paramValue);
                    }
                }
            }
        }

        return obj;
    }

}
