import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {Subscription} from 'rxjs';
import {NavigationEnd, Router, Event} from '@angular/router';
import {CodePush} from '@ionic-native/code-push/ngx';
import {environment} from '../environments/environment';
import {IsDebug} from '@ionic-native/is-debug';
import {SharedService} from './shared/shared.service';
import {Logger, LoggingService} from 'ionic-logging-service';
import {Network} from '@ionic-native/network/ngx';

import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {HelperService} from './shared/services/helper.service';
import {Storage} from '@ionic/storage';
import {AnalyticsService} from './shared/services/analytics.service';
import {PushNotificationService} from './shared/push-notification.service';
import { FirebaseConfig } from '@ionic-native/firebase-config/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {AppUpdateModalComponent} from './update/app-update-modal/app-update-modal.component';
import {RemoteConfig} from './shared/models/common.model';
import {AuthService} from './auth/services/auth.service';
import {ForgotPasswordPage} from './auth/pages/forgot-password/forgot-password.page';
import {BUGSNAG_CLIENT} from './third-party/bugsnag/bugsnag.module';
import * as MobileDetect from 'mobile-detect';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    private authStateSubscription: Subscription = new Subscription();
    loadingRouteConfig: boolean;
    isOffline: boolean;
    private logger: Logger;
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private authService: AuthService,
        private sharedService: SharedService,
        private router: Router,
        private codePush: CodePush,
        private isDebug: IsDebug,
        loggingService: LoggingService,
        private network: Network,
        private deeplinks: Deeplinks,
        private helper: HelperService,
        private storage: Storage,
        private analytics: AnalyticsService,
        private notificationService: PushNotificationService,
        private firebaseConfig: FirebaseConfig,
        private appVersion: AppVersion,
        private modalController: ModalController,
        private alertController: AlertController
    ) {
        this.logger = loggingService.getLogger('app.component');
        this.logger.entry('constructor', 'Success');
        this.initializeApp();
        this.logger.exit('constructor', 'Success');
        this.router.events.subscribe( (event: Event) => {
            // close modal on click browser back button
            if (event instanceof NavigationEnd) {
                if (!window.IS_MOBILE) {
                     this.modalController.getTop().then( currentModal => {
                        if (currentModal) {
                            window.history.forward();
                            currentModal.dismiss();
                        }
                    });
                }
            }
        });
    }

    initializeApp() {
        this.logger.entry('initializeApp', 'Success');
        // this.analytics.init();
        if (this.platform.is('cordova')) {
            if (window.IS_MOBILE && !window.IS_MOBILE_WEB) {
                this.notificationService.init();
                if (environment.production) {
                    this.checkRemoteConfigAndShowAppUpdateModal();
                }
            }
        }
        this.platform.ready().then(() => {
            this.checkWebViewAndPromptForUpdate();
            // this.setAppVersionToBugSnag();
            this.statusBar.overlaysWebView(false);
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            if (environment.production) {
                // this.checkCodePush();
            }

            this.isDebug.getIsDebug()
                .then(isDebug => {
                    console.log('App Running in debug:', isDebug);
                })
                .catch(err => console.log(err));

            this.setDeepLinkHandler();
         });
        this.logger.exit('initializeApp', 'Success');
    }

    ngOnInit(): void {
        this.logger.entry('app.component.ngOnInit', 'Success');
        this.statusBar.backgroundColorByHexString('#ffffff');
        this.sharedService.checkAuthAccountDetailsAndRedirect().then(() => {
            // this.isOffline = false;
        }, () => {
            // this.isOffline = true;
            this.sharedService.checkEmailVerificationPendingAndRedirect();
        });
        /*this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.analytics.screenViewChanged(event.urlAfterRedirects);
            } else if (event instanceof RouteConfigLoadEnd) {

            }
        });*/
        this.initData();
        /*this.network.onDisconnect().subscribe(() => {
            this.isOffline = true;
        });
        this.network.onConnect().subscribe(() => {
            this.isOffline = false;
        });*/
        this.logger.exit('app.component.ngOnInit', 'Success');

        // add desktop class
        if (!window.IS_MOBILE) {
            const getBodyElement = document.querySelector( '.recykal' );
            getBodyElement.classList.add('recykal-desktop');
        }
        if (!window.IS_MOBILE || window.IS_MOBILE_WEB) {
            this.loadFreshChatWeb();
        }
    }

    loadFreshChatWeb() {
        const jsElm = document.createElement('script');
        jsElm.type = 'application/javascript';
        jsElm.src = 'https://wchat.freshchat.com/js/widget.js';
        document.body.appendChild(jsElm);
    }

    checkWebViewAndPromptForUpdate() {
        // 45
        if ((this.platform.is('android') || this.platform.is('ios')) && !window.IS_MOBILE_WEB) {
            this.firebaseConfig.fetchAndActivate().then((data) => {
                if (this.platform.is('android')) {
                    const md = new MobileDetect(window.navigator.userAgent);
                    const webviewVersion = md.version('Chrome') || 0;
                    console.log(webviewVersion);
                    if (webviewVersion <= 45) {
                        this.firebaseConfig.getString('android_system_webview').then((linkForUpdate) => {
                            if (linkForUpdate) {
                                this.alertController.create({
                                    header: 'Please update Android System Webview for better experience.',
                                    buttons: [
                                        {
                                            text: 'Continue',
                                            role: 'cancel',
                                            cssClass: 'secondary',
                                            handler: (no) => {
                                                console.log('Confirm Cancel: no');
                                            }
                                        }, {
                                            text: 'Update',
                                            handler: () => {
                                                window.open(linkForUpdate, '_system');
                                            }
                                        }
                                    ]
                                }).then((alertInstance) => {
                                    alertInstance.present();
                                });
                            }
                        }).catch((err) => {
                            this.helper.showToast(err, 'danger').then();
                        });
                    }
                    console.log(md.version('Chrome'));
                }
            });
        }
    }

    checkCodePush() {
        /*check app running in debug mode or not*/
        const downloadProgress = (progress) => { console.log(`Downloaded ${progress.receivedBytes} of ${progress.totalBytes}`); };
        this.codePush.sync({
            installMode: 0,
            deploymentKey: (this.platform.is('ios') ? environment.codePush.keys.ios : environment.codePush.keys.android)
        }, downloadProgress).subscribe((syncStatus) => console.log(syncStatus));
    }

    initData() {
        this.sharedService.getCities().first().subscribe(() => {}, (err) => {
        });
        this.sharedService.getPortalConfig().first().subscribe(() => {}, (err) => {
        });

        /*remove app loader from dom*/
        setTimeout(() => {
            const element = document.getElementById('app-body-loader');
            element.parentElement.removeChild(element);
        }, 2000);
    }

    start() {

    }

    setDeepLinkHandler() {
        this.deeplinks.route({
            '/reset-password': ForgotPasswordPage,
            '/reset-password.html': ForgotPasswordPage
        }).subscribe((match) => {
                if (this.authService.isAuthenticated()) {
                    this.helper.showToast('Please logout to reset password.', 'danger').then();
                } else {
                    // match.$route - the route we matched, which is the matched entry from the arguments to route()
                    // match.$args - the args passed in the link
                    // match.$link - the full link data
                    this.router.navigate([window.ROUTES.AUTH_FORGOT_PASSWORD],  { queryParams: match.$args }).then();
                    console.log('Successfully matched route', match);
                }
            },
            (nomatch) => {
                // nomatch.$link - the full link data
                console.log('Got a deeplink that didn\'t match', nomatch);
            });
    }

    checkRemoteConfigAndShowAppUpdateModal() {
        this.firebaseConfig.fetchAndActivate().then((data) => {
            this.firebaseConfig.getString('current_app_version').then((remoteConfigAppVersion) => {
                if (remoteConfigAppVersion) {
                    const appVersion = JSON.parse(remoteConfigAppVersion);
                    if (this.platform.is('ios')) {
                        return this.appVersion.getVersionNumber().then((currentVersion) => {
                            if (this.helper.versionCompare(appVersion.ios, currentVersion) === 1) {
                                this.showAppUpdateModal(appVersion).then();
                                console.log('need to update', appVersion.ios, currentVersion);
                            }
                        });
                    } else if (this.platform.is('android')) {
                        return this.appVersion.getVersionNumber().then((currentVersion) => {
                            if (this.helper.versionCompare(appVersion.android, currentVersion) === 1) {
                                this.showAppUpdateModal(appVersion).then();
                                console.log(
                                    'need to update',
                                    appVersion.android,
                                    currentVersion,
                                    this.helper.versionCompare(appVersion.android, currentVersion));
                            }
                        });
                    }
                }
            }).catch((err) => {
                this.helper.showToast(err, 'danger').then();
            });
        });
    }

    setAppVersionToBugSnag() {
        this.appVersion.getVersionNumber().then((currentVersion) => {
            BUGSNAG_CLIENT.app = {
                version: currentVersion
            };
        }, (err) => {});
    }

    async showAppUpdateModal(newAppVersion) {
        const updateInfo: RemoteConfig = {};
        updateInfo.current_app_version = newAppVersion;
        await this.firebaseConfig.getString('app_install_link').then((data) => {
            if (data) {
                updateInfo.app_install_link = JSON.parse(data);
            }
        }).catch((err) => {
            this.helper.showToast(err, 'danger');
        });
        await this.firebaseConfig.getString('current_version_release_notes').then((data) => {
            if (data) {
                updateInfo.current_version_release_notes = JSON.parse(data);
            }
         }).catch((err) => {
            this.helper.showToast(err, 'danger');
        });
        const modal = await this.modalController.create({
            component: AppUpdateModalComponent,
            componentProps: {
                updateInfo
            },
            cssClass: ['modal-auto-center', 'small'],
            backdropDismiss: false
        });
        modal.present().then();
    }

    ngOnDestroy(): void {
        this.authStateSubscription.unsubscribe();
    }
}
