import {Injectable} from '@angular/core';
import {API} from '../shared/services/api.url';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {CategoryNode} from '../pickup/pickup.model';
import {BidRequest, ListingStatus, MarketItemStats, OfferBid, OfferRequest} from './market.model';
import {ModalController} from '@ionic/angular';
import * as _ from 'lodash';
import {ProductService} from '../product/product.service';
import {HelperService} from '../shared/services/helper.service';


@Injectable({
    providedIn: 'root'
})
export class MarketService {

    catalogData = new BehaviorSubject<any>([]);

    constructor(private httpClient: HttpClient,
                private modalController: ModalController,
                private productService: ProductService,
                private helperService: HelperService) {
    }

    fetchAllCategories() {
        return this.httpClient.get(`${API.ALL_CATEGORIES()}`).map((data: CategoryNode[]) => {
            return data;
        });
    }

    fetchAllOffersForCat(catId) {
        return this.httpClient.get(`${API.ALL_OFFERS_BY_CAT()}?categoryId=${catId}`).map((data: OfferBid[]) => {
            return data;
        });
    }

    fetchAllBidsForCat(catId) {
        return this.httpClient.get(`${API.ALL_BIDS_BY_CAT()}?categoryId=${catId}`).map((data: OfferBid[]) => {
            return data;
        });
    }

    fetchAllBidsForItem(itmId): Observable<any> {
        return this.httpClient.get(`${API.ALL_BIDS()}?itemId.equals=${itmId}`).map((data: any) => {
            return data;
        });
    }

    fetchAllOffersForItem(itmId): Observable<any> {
        return this.httpClient.get(`${API.ALL_OFFERS()}?itemId.equals=${itmId}`).map((data: any) => {
            return data;
        });
    }

    saveBid(bidData: BidRequest, isUpdate) {
        return isUpdate ? this.httpClient.put(`${API.ALL_BIDS()}`, bidData) : this.httpClient.post(`${API.ALL_BIDS()}`, bidData);
    }

    saveOffer(offerData: OfferRequest, isUpdate) {
        return isUpdate ? this.httpClient.put(`${API.ALL_OFFERS()}`, offerData) : this.httpClient.post(`${API.ALL_OFFERS()}`, offerData);
    }

    fetchBidDetails(bidId: string | number) {
        return this.httpClient.get(`${API.ALL_BIDS()}/${bidId}`);
    }

    fetchOfferDetails(offerId: string | number) {
        return this.httpClient.get(`${API.ALL_OFFERS()}/${offerId}`);
    }

    fetchOffersList(options): Observable<any> {
        const params = new HttpParams({fromObject: options});
        return this.httpClient.get(`${API.ALL_OFFERS()}?sort=modifiedOn,desc`, {
            params,
            observe: 'response'
        }).map((data) => {
            return data;
        });
    }

    fetchBidsList(options): Observable<any> {
        const params = new HttpParams({fromObject: options});
        return this.httpClient.get(`${API.ALL_BIDS()}?sort=modifiedOn,desc`, {
            params,
            observe: 'response'
        }).map((data: any) => {
            return data;
        });
    }

    fetchMarketBidStats(itemId) {
        return this.httpClient.get(`${API.MARKET_BID_STATS()}?itemId=${itemId}`).map((data: MarketItemStats) => {
            return data;
        });
    }

    fetchMarketOfferStats(itemId) {
        return this.httpClient.get(`${API.MARKET_OFFER_STATS()}?itemId=${itemId}`).map((data: MarketItemStats) => {
            return data;
        });
    }

    fetchMarketOfferTransactions(id) {
        return this.httpClient.get(`${API.MARKET_OFFER_TRANSACTIONS()}?channelName=Marketplace&offerId=${id}`).map((data: OfferBid[]) => {
            return data;
        });
    }

    fetchMarketBidTransactions(id) {
        return this.httpClient.get(`${API.MARKET_BID_TRANSACTIONS()}?channelName=Marketplace&bidId=${id}`).map((data: OfferBid[]) => {
            return data;
        });
    }

    fetchAllListingStatuses() {
        /* catalog/listing-statuses*/
        return this.httpClient.get(`${API.LISTING_STATUSES()}`).map((data: ListingStatus []) => {
            return data;
        });
    }

    getCatalogRootCategories() {
        if (this.catalogData.value && this.catalogData.value.length === 0) {
            this.productService.fetchProductCategoriesForMarket().subscribe((data) => {
                data = data ? _.orderBy(data, 'seq', 'asc') : data;
                this.catalogData.next(data);
            }, (err) => {
            });
        }
    }

    getProductCategoryData(catData, pathToCat) {
        if (catData &&
            catData.items === undefined &&
            catData.subCategories === undefined) {
            this.productService.fetchProductCategoryDataForMarket(catData.id).subscribe(
                (data) => {
                    if (data) {
                        data.items = data.items ? _.orderBy(data.items, 'seq', 'asc') : data;
                        data.subCategories = data.subCategories ? _.orderBy(data.subCategories, 'seq', 'asc') : data;
                        // this.catalogData.next(data);
                        this.helperService.updateObject(this.catalogData.value, data.items, pathToCat + '>items');
                        this.helperService.updateObject(this.catalogData.value, data.subCategories, pathToCat + '>subCategories');
                        this.catalogData.next(this.catalogData.value);
                    }
                }, (err) => {
                });
        }

    }

    getProductCategoryDescendentItems(catData, pathToCat) {
        if (catData.items === undefined || (catData.items && catData.items.length === 0)) {
            this.productService.fetchProductCategoryDescendantItemsForMarket(catData.id).subscribe(
                (data) => {
                    if (data) {
                        data.items = data.items ? _.orderBy(data.items, 'seq', 'asc') : data;
                        this.helperService.updateObject(this.catalogData.value, data, pathToCat + '>items');
                        // pushing empty cat array is required to show not items message in listing.
                        this.helperService.updateObject(this.catalogData.value, [], pathToCat + '>subCategories');
                        this.catalogData.next(this.catalogData.value);
                    }
                }, (err) => {
                });
        }
    }

    getProductsByAttributeIds(attrValues: any[]) {
        return this.productService.fetchProductsByAttributeIdsForMarket(attrValues);
    }

    searchProducts(query) {
        return this.productService.searchAndFetchProductsForMarket(query);
    }

}
