import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from '../shared.service';
import {ICustomer} from '../../models/customer.model';
import {FirebaseAnalytics} from '@ionic-native/firebase-analytics/ngx';
import {Facebook} from '@ionic-native/facebook/ngx';
import {Events, NavController, Platform} from '@ionic/angular';
import {FacebookEvents, MyEvents} from '../my-events.const';
import {RCYL_ORDERS_GLOBALS} from 'rcyl-orders';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
      private firebaseAnalytics: FirebaseAnalytics,
      private sharedService: SharedService,
      private route: ActivatedRoute,
      private facebook: Facebook,
      private events: Events,
      private nav: NavController,
      private router: Router,
      private platform: Platform
  ) {

    this.sharedService.fetchCustomerProfile()
        .first()
        .subscribe((data: ICustomer) => {
      this.setUserData(data);
    });
  }


  /**
   * enable or disable analytics
   */
  init() {
    if ((window as any).IS_MOBILE && !(window as any).IS_MOBILE_WEB) {
      if (this.platform.is('cordova') && !this.platform.is('mobileweb') ) {
        this.firebaseAnalytics.setEnabled(environment.analytics.firebase);
        this.logFacebookEvent(FacebookEvents.StandardEvents.EVENT_NAME_ACTIVATED_APP);
        document.addEventListener('PageTracker:PageChanged', (event) => {
          if (this.platform.is('cordova') && !this.platform.is('mobileweb') ) {
            // @ts-ignore
            if (event && event.detail) {
              // @ts-ignore
              this.screenViewChanged(event.detail.pageName);
              this.logFacebookEvent(FacebookEvents.StandardEvents.EVENT_NAME_VIEWED_CONTENT, {
                CONTENT_TYPE: 'PAGE',
                // @ts-ignore
                CONTENT_ID: event.detail.pageName
              });
              // @ts-ignore
              console.log('Screen view change is logged', event.detail);
            }
          }
        });
        this.events.subscribe(RCYL_ORDERS_GLOBALS.EVENTS_TO_PUBLISH.ANALYTICS, (data) => {
          this.logEventsFromRcylOrderListModule(data);
        });
      }
    }

  }

  setUserData(user: any) {
    if ((window as any).IS_MOBILE && !(window as any).IS_MOBILE_WEB) {
      if (this.platform.is('cordova')) {
        this.firebaseAnalytics.setUserId(user.formattedId).then();
        this.firebaseAnalytics.setUserProperty('name', user.name).then();
        this.firebaseAnalytics.setUserProperty('mobile', user.mobile ? '' + user.mobile : '').then();
        this.firebaseAnalytics.setUserProperty('email', user.email ? user.email : '').then();
        this.firebaseAnalytics.setUserProperty('businessType', (user.businessType && user.businessType.title) ? user.businessType.title : '');
      }
    }
  }

  screenViewChanged(screen: string) {
    if ((window as any).IS_MOBILE  && !(window as any).IS_MOBILE_WEB) {
      if (this.platform.is('cordova')  && !this.platform.is('mobileweb') ) {
        this.firebaseAnalytics.setCurrentScreen(screen);
      }
    }
  }

  logEvent(name: string, params?: any, where?: 'FIREBASE' | 'FACEBOOK') {
    if ((window as any).IS_MOBILE && !(window as any).IS_MOBILE_WEB) {
      if (this.platform.is('cordova') && !this.platform.is('mobileweb')) {
        switch (where) {
          case 'FIREBASE':
            this.logFirebaseEvent(name, params);
            break;
          case 'FACEBOOK':
            this.logFacebookEvent(name, params);
            break;
          default:
            this.logFirebaseEvent(name, params);
            this.logFacebookEvent(name, params);
        }
      }
    }

  }

  logPurchase(value: number, currency: string) {
    this.facebook.logPurchase(value, currency).then(() => {

    }, () => {

    });
  }

  logFacebookEvent(name: string, params?: any) {
    if (environment.analytics.facebook) {
      this.facebook.logEvent(name, params).then(() => {

      }, (err) => {
        console.log(err);
      });
    }
  }

  logFirebaseEvent(name: string, params?: any) {
    if ((window as any).IS_MOBILE) {
      this.firebaseAnalytics.logEvent(name, params).then(() => {

      }, () => {

      });
    }
  }

  logEventsFromRcylOrderListModule(data: {eventName: string, params: any}) {
    switch (data.eventName) {
      case 'MARKET_MARK_AS_DISPATCHED_BTN_CLICKED':
        this.logEvent(MyEvents.MARKETPLACE_MARK_DISPATCH_CLICKED, data.params);
        break;
      case 'MARKETPLACE_ORDER_DISPATCHED':
        this.logEvent(MyEvents.MARKETPLACE_ORDER_DISPATCHED_SUCCESS, data.params);
        break;
      case 'MARKETPLACE_SELLER_WEIGH_BRIDGE_OPENED':
        this.logEvent(MyEvents.MARKETPLACE_SELLER_WEIGHBRIDGE_OPENED_BY_BUYER, data.params);
        break;
      case 'MARKETPLACE_BUYER_WEIGH_BRIDGE_OPENED':
        this.logEvent(MyEvents.MARKETPLACE_BUYER_WEIGHBRIDGE_OPENED_BY_SELLER, data.params);
        break;
      case 'MARKETPLACE_RECEIVED_TRANSACTION_OPENED':
        break;
      case 'MARKETPLACE_DELIVERED_TRANSACTION_OPENED':
        this.logEvent(MyEvents.MARKETPLACE_DELIVERED_TRANSACTION_OPENED_BY_SELLER, data.params);
        break;
      default:
        this.logEvent(data.eventName, data.params);
    }
  }

}
