import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Platform} from '@ionic/angular';
import {environment} from '../../../environments/environment';
import {isFunction} from 'rxjs/internal-compatibility';
import {UserAccount} from '../../customer/customer.model';
import {AuthService} from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  account: UserAccount;
  constructor(
      private authService: AuthService,
      private platform: Platform,
  ) {

    /** initialize plugin when device ready */
    this.platform.ready().then(() => {
      console.log('Platform ready: Init Freshchat');
      this.init();
    });

    /** clear data on logout */
    this.authService.authenticationState.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.clearData();
      }
    });
  }

  init() {
    if (window.IS_MOBILE && !window.IS_MOBILE_WEB) {
      if (!(window as any).Freshchat) {
        return;
      }
      (window as any).Freshchat.init({
        appId       : environment.chat.app_id,
        appKey      : environment.chat.app_token
      }, success => {
        console.log('FRESHCHAT INIT', success);
        /** send data to FreshChat when changed */
        this.authService.accountInfo.subscribe(account => {
          this.account = _.cloneDeep(account);
          if (this.account) {
            console.log('Log freshchat account');
            this.updateUser();
          }
        });
      });
    } else {  // web sdk
        this.authService.accountInfo.subscribe(account => {
          this.account = _.cloneDeep(account);
          if (this.account) {
            this.loadWebSdk(false);
            console.log('Log desktop freshchat account');
          }
        });
    }
  }

  loadWebSdk(toBeOpen?: boolean) {
    if ((window as any).fcWidget) {
      (window as any).fcWidget.init({
        token: 'b2034893-6df5-445a-9bb2-416603c06386',
        host: 'https://wchat.freshchat.com',
        siteId: 'Recykal B2B Portal',
        tags: ['recykalportal'],
        open: toBeOpen || false,
        config: {
          headerProperty: {
            hideChatButton: true
          }
        }
      });
    }
    if ((window as any).fcWidget) {
      (window as any).fcSettings = {
        token: 'b2034893-6df5-445a-9bb2-416603c06386',
        host: 'https://wchat.freshchat.com',
        externalId: this.account.profile.id,
        firstName: this.account.firstName,
        lastName: this.account.lastName,
        email: this.account.email,
        phone: this.account.profile ? (this.account.profile.mobile || '') : '',
        properties: {
          Company: this.account.lastName,
        },
        phoneCountryCode: '+91'
      };
    }
    if ((window as any).fcWidget) {
      (window as any).fcWidget.setExternalId(this.account.profile.id);
      (window as any).fcWidget.user.setFirstName(this.account.firstName);
      (window as any).fcWidget.user.setEmail(this.account.email);
      (window as any).fcWidget.user.setProperties({
        Company: this.account.lastName,
      });
    }
  }

  openChatWindow() {
    if (window.IS_MOBILE && !window.IS_MOBILE_WEB) {
      if (!(window as any).Freshchat) {
        return;
      }
      (window as any).Freshchat.showConversations({
        tags: ['recykal_marketplace_app'],
        filteredViewTitle: 'Support'
      });
    } else {
      if (!(window as any).fcWidget) {
        return;
      } else {
        if ((window as any).fcWidget.isLoaded() === true) {
          (window as any).fcWidget.show();
          (window as any).fcWidget.open();
        } else {
          this.loadWebSdk(true);
        }
      }
    }

  }

  updateUser() {
    if (window.IS_MOBILE) {
      if (!(window as any).Freshchat) {
        return;
      }
      (window as any).Freshchat.updateUser({
        firstName : this.account.firstName,
        lastName : this.account.lastName,
        email : this.account.email,
        countryCode : '+91',
        phoneNumber : this.account.profile ? (this.account.profile.mobile || '') : ''
      });

      (window as any).Freshchat.identifyUser( {
        externalId: this.account.profile.id.toString()
      });
    }
    this.updateUserProperty();
  }

  clearData() {
    if (!(window as any).Freshchat) {
      return;
    }
    return (window as any).Freshchat.clearUserData();
  }

  updatePushDeviceToken(token) {
    if (!(window as any).Freshchat) {
      return;
    }
    (window as any).Freshchat.updatePushNotificationToken(token);
  }

  updateUserProperty() {
    if (window.IS_MOBILE) {
      if (!(window as any).Freshchat) {
        return;
      }
      (window as any).Freshchat.updateUserProperties({
        customerId: this.account.profile ? this.account.profile.formattedId : '',
        RegDone: this.account.registrationDone,
        businessType: this.account.profile.businessType ? this.account.profile.businessType.title : ''
      });
    }
  }

  sendMessage(msg, openChat?, callback?) {
    if (!(window as any).Freshchat) {
      return;
    }
    (window as any).Freshchat.sendMessage(
        {tag: 'recykal_marketplace_app', message: msg}, ((success) => {
          if (openChat) {
            this.openChatWindow();
          }
          if (isFunction(callback)) {
            callback(success);
          }
        }));

  }

  isFreshChatNotification(payload) {
    if (!(window as any).Freshchat) {
      return;
    }
    return (window as any).Freshchat.isFreshchatPushNotification(payload, ((success, isFreshchatNotif) => {
      if ( success && isFreshchatNotif ) {
        (window as any).Freshchat.handlePushNotification(payload.additionalData);
        return isFreshchatNotif;
      }
      return false;
    }));
  }




}
