<ion-header no-shodow class="main-header">
    <ion-toolbar no-border>
        <ion-buttons slot="start" text-center>
            <ion-button no-box-shadow (click)="closeModal()">
                <ion-icon name="close" ></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title text-center>
            <div class="custom-title" justify-content-center="">
                <ion-text class="heading-medium" color="primary">{{title}}</ion-text>
            </div>
        </ion-title>
        <ion-buttons slot="end" text-center>
            <ion-button no-box-shadow >
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <iframe padding-start padding-end padding-bottom #frame [src]="src">
    </iframe>
</ion-content>
