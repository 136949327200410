import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductLearnMoreComponent } from '../product-learn-more/product-learn-more.component';
import { TradeType } from '../../../shared/models/common.model';
import { Router } from '@angular/router';
var ProductAddToCartComponent = /** @class */ (function () {
    function ProductAddToCartComponent(modalController, router) {
        this.modalController = modalController;
        this.router = router;
        this.ProductAddToCartComponentViews = ProductAddToCartComponentViews;
        this.tradeTypes = TradeType;
        this.window = window;
    }
    ProductAddToCartComponent.prototype.ngOnInit = function () {
        this.selectedQty = this.setSelectedQty ? this.setSelectedQty : 0;
        this.activeView = ProductAddToCartComponentViews.SET_QTY;
    };
    ProductAddToCartComponent.prototype.closeModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.dismiss({ selectedQty: this.selectedQty, productId: this.product.id })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductAddToCartComponent.prototype.updateQty = function (qty) {
        this.selectedQty = qty;
    };
    ProductAddToCartComponent.prototype.openLearnMoreModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var learnMoreModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: ProductLearnMoreComponent,
                            cssClass: ['bottom-sheet'],
                            showBackdrop: true,
                            backdropDismiss: true,
                            componentProps: {
                                item: this.product
                            }
                        })];
                    case 1:
                        learnMoreModal = _a.sent();
                        learnMoreModal.present().then();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductAddToCartComponent.prototype.isFloat = function (n) {
        return Number(n) === n && n % 1 !== 0;
    };
    ProductAddToCartComponent.prototype.gotoTransactions = function () {
        var _this = this;
        this.router.navigate([window.ROUTES.DASHBOARD_CONTAINER_TRANSACTIONS]).then(function (res) {
            _this.modalController.dismiss(null, undefined);
        });
    };
    return ProductAddToCartComponent;
}());
export { ProductAddToCartComponent };
var ProductAddToCartComponentViews;
(function (ProductAddToCartComponentViews) {
    ProductAddToCartComponentViews[ProductAddToCartComponentViews["SET_QTY"] = 0] = "SET_QTY";
    ProductAddToCartComponentViews[ProductAddToCartComponentViews["LEARN_MORE"] = 1] = "LEARN_MORE";
})(ProductAddToCartComponentViews || (ProductAddToCartComponentViews = {}));
