import {IOrder} from './pickup-order.model';

export interface IOrderList {
    result?: IOrder[];
    totalItemCount?: number;
    page?: number;
}
export class OrderList implements IOrderList {
    constructor(
        public result?: IOrder[],
        public totalItemCount?: number,
        public page?: number
    ) {
        this.page = 0;
        this.totalItemCount = 0;
        this.result = [];
    }
}
