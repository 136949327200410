<ion-header no-shodow class="main-header" >
    <ion-toolbar no-border>
        <ion-buttons slot="end" text-center *ngIf="!this.acceptPolicy" >
            <ion-button no-box-shadow (click)="closeModal()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>

    </ion-toolbar>
</ion-header>
<ion-content>
    <iframe padding-start padding-end padding-bottom #frame [src]="src">
    </iframe>
</ion-content>
<ion-footer *ngIf="this.acceptPolicy">
    <ion-grid>
        <ion-row>
            <ion-col size="12" text-center align-items-center>
                <ion-checkbox color="primary"  style="margin-bottom: -3px;" [(ngModel)]="checkboxChecked"></ion-checkbox>
                <ion-label padding-left-10 class="ion-text-left" text-wrap margin-bottom>
                    I have read and agree to the
                    <ion-text font-bold link >Privacy Policy</ion-text>
                </ion-label>
            </ion-col>

            <ion-col size="12">
                <ion-item lines="none">
                    <ion-button expand="block" size="large" [disabled]="!checkboxChecked" strong (click)="acceptPrivacyPolicy()">
                        Accept
                    </ion-button>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>
