import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PickupService} from '../../../../pickup/pickup.service';
import {Subscription} from 'rxjs';
import {Event, NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-pickup-timeline',
    templateUrl: './pickup-timeline.component.html',
    styleUrls: ['./pickup-timeline.component.scss'],
})
export class PickupTimelineComponent implements OnInit {
    cartDataSubscription: Subscription = new Subscription();
    cardData: any;
    currentState: string;
    selectedMenu: string;
    buttonData: any;
    buttonDisplay: boolean;

    constructor(
        private pickupService: PickupService,
        private router: Router,
        private ref: ChangeDetectorRef
    ) {

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentState = event.urlAfterRedirects;
                this.setSelected(this.currentState);
                this.displayBackButton(this.currentState);
            }
        });
    }

    ngOnInit() {
        this.getCartData();
        if (this.cardData.storeData) {
            this.selectedMenu = 'ITEMS';
        }
    }

    getCartData() {
        this.cartDataSubscription = this.pickupService.cartData.subscribe((cartData) => {
            this.cardData = cartData;
            this.ref.detectChanges();
        });
    }

    gotoSelectStore() {
        this.router.navigate([window.ROUTES.CREATE_PICKUP_SELECT_STORE]).then();
    }

    gotoSelectItems() {
        if (this.cardData.storeData) {
            if (this.cardData && this.cardData.cartItems && this.cardData.cartItems.length) {
                this.router.navigate([window.ROUTES.CREATE_PICKUP_SELECTED_PRODUCTS_FROM_RECENTLY_USED_TRUE]).then();
            } else {
                this.router.navigate([window.ROUTES.CREATE_PICKUP_SELECT_PRODUCTS_FROM_RECENTLY_USED]).then();
            }
        }
    }

    gotoSelectDate() {
        if (this.cardData.cartItems.length > 0) {
            this.router.navigate([window.ROUTES.CREATE_PICKUP_SELECT_TIME_SLOT]).then();
        }
    }

    setSelected(path) {
        switch (path) {
            case '/main/create-pickup-modal/(modal:select-store)':
            case '/main/create-pickup-modal/(modal:add-location)':
                this.selectedMenu = 'STORE';
                break;
            case '/main/create-pickup-modal/(modal:recently-used-products)':
            case '/main/create-pickup-modal/(modal:select-products)':
            case '/main/create-pickup-modal/(modal:selected-products)':
            case '/main/create-pickup-modal/(modal:selected-products)?navigateToRecent=true':
                this.selectedMenu = 'ITEMS';
                break;
            case '/main/create-pickup-modal/(modal:select-timeslot)':
                this.selectedMenu = 'TIMESLOT';
                break;
            default :
                this.selectedMenu = 'ITEMS';
                break;
        }
    }

    displayBackButton(path) {
        switch (path) {
            case '/main/create-pickup-modal/(modal:select-store)':
                this.buttonDisplay = true;
                this.buttonData = {
                    text: 'Cancel',
                    show_icon: 'close',
                    on_click: 'close'
                };
                break;
            case '/main/create-pickup-modal/(modal:add-location)':
                this.buttonDisplay = true;
                this.buttonData = {
                    text: 'Back',
                    show_icon: 'arrow-round-back',
                    on_click: 'back'
                };
                break;
            case '/main/create-pickup-modal/(modal:recently-used-products)':
                this.buttonDisplay = true;
                this.buttonData = {
                    text: 'Back',
                    show_icon: 'arrow-round-back',
                    on_click: 'back'
                };
                break;
            case '/main/create-pickup-modal/(modal:select-products)':
                this.buttonDisplay = true;
                this.buttonData = {
                    text: 'Back',
                    show_icon: 'arrow-round-back',
                    on_click: 'back'
                };
                break;
            case '/main/create-pickup-modal/(modal:selected-products)':
                this.buttonDisplay = true;
                this.buttonData = {
                    text: 'Back',
                    show_icon: 'arrow-round-back',
                    on_click: 'back'
                };
                break;
            case '/main/create-pickup-modal/(modal:selected-products)?navigateToRecent=true':
                this.buttonDisplay = true;
                this.buttonData = {
                    text: 'Back',
                    show_icon: 'arrow-round-back',
                    on_click: 'back'
                };
                break;
            case '/main/create-pickup-modal/(modal:select-timeslot)':
                this.buttonDisplay = true;
                this.buttonData = {
                    text: 'Back',
                    show_icon: 'arrow-round-back',
                    on_click: 'back'
                };
                break;
            default :
                this.buttonDisplay = false;
                this.buttonData = {
                    text: '',
                    show_icon: 'false',
                    url: ''
                };
                break;
        }
    }

  btnClicked(type: string) {
    if (type === 'back') {
        window.history.back();
    } else {
      this.router.navigate([window.ROUTES.TRANSACTIONS_DETAILS]).then();
    }
  }

}
