import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ProductsListComponent} from './components/products-list/products-list.component';
import {ProductItemSmallComponent} from './components/product-item-small/product-item-small.component';
import {ProductItemLargeComponent} from './components/product-item-large/product-item-large.component';
import {ProductLearnMoreComponent} from './components/product-learn-more/product-learn-more.component';
import {ProductQtyEditComponent} from './components/product-qty-edit/product-qty-edit.component';
import {ProductAddToCartComponent} from './components/product-add-to-cart/product-add-to-cart.component';
import {RecentlyUsedProductsComponent} from './components/recently-used-products/recently-used-products.component';
import {CartProductsListComponent} from './components/cart-products-list/cart-products-list.component';
import {SharedModule} from '../shared/shared.module';
import {ProductService} from './product.service';
import {ProductListLazyComponent} from './components/product-list-lazy/product-list-lazy.component';
import {CategoryAttrFilterComponent} from './components/category-attr-filter/category-attr-filter.component';
import {ProductItemSearchComponent} from './components/product-item-search/product-item-search.component';
import {PickupTimelineModule} from '../desktop/shared/components/pickup-timeline/pickup-timeline.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SharedModule,
        ReactiveFormsModule,
        PickupTimelineModule,
    ],
    declarations: [
        ProductListLazyComponent,
        ProductsListComponent,
        ProductItemSmallComponent,
        ProductItemLargeComponent,
        ProductAddToCartComponent,
        CartProductsListComponent,
        RecentlyUsedProductsComponent,
        ProductQtyEditComponent,
        ProductLearnMoreComponent,
        CategoryAttrFilterComponent,
        ProductItemSearchComponent
    ],
    entryComponents: [ProductAddToCartComponent, ProductLearnMoreComponent, ProductItemSearchComponent],
    exports: [
        ProductListLazyComponent,
        ProductsListComponent,
        ProductItemSmallComponent,
        ProductItemLargeComponent,
        ProductAddToCartComponent,
        CartProductsListComponent,
        RecentlyUsedProductsComponent,
        ProductAddToCartComponent,
        ProductQtyEditComponent,
        ProductLearnMoreComponent,
        CategoryAttrFilterComponent,
        ProductItemSearchComponent],
    providers: [ProductService]
})
export class ProductModule {
}
