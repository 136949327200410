import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from '../../../pickup/pickup.model';
import {TradeType} from '../../../shared/models/common.model';


@Component({
  selector: 'product-qty-edit',
  templateUrl: './product-qty-edit.component.html',
  styleUrls: ['./product-qty-edit.component.scss'],
})
export class ProductQtyEditComponent implements OnInit, AfterViewInit {
  @Input() item: Product;
  @Input() setSelectedQty?: number;
  @Output() onItemQtyUpdate = new EventEmitter<number>();
  selectedQty: number;
  tradeTypes = TradeType;

  /*@ViewChild('qtyInput') qtyInput: IonInput;*/

  constructor() { }

  ngOnInit() {
    this.selectedQty = this.setSelectedQty;
    /*this.qtyInput.setFocus();*/
  }
  
  onQtyChange() {
    this.onItemQtyUpdate.emit(this.selectedQty);
  }

  ngAfterViewInit() {
    /*setTimeout(() => {
      this.qtyInput.setFocus();
    }, 800);*/
  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }
 
}
