<ion-header no-box-shadow>
  <ion-toolbar no-box-shadow>
    <ion-buttons slot="start">
      <ion-button no-margin no-box-shadow slot="icon-only" (click)="closeModal(null, 'closed')">
        <ion-icon name="arrow-back" mode="ios"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title text-center>
      <div class="custom-title with-backbtn">
        <ion-text class="heading-medium">{{title}}</ion-text>
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content padding [innerHTML]="htmlContent | safeHtml">
</ion-content>
