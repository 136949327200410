import {APP_INITIALIZER, ErrorHandler, Inject, Injectable, InjectionToken, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoggingService} from 'ionic-logging-service';
import {IonicStorageModule} from '@ionic/storage';
import {AppInitializerService} from './init/services/app-initializer.service';
import {MatchescategoryPipe} from './shared/pipes/matchescategory.pipe';
import {SelectProductsPageModule} from './pickup/select-products/select-products.module';
import {ThirdPartyModule} from './third-party/third-party.module';
import * as Rollbar from 'rollbar';
import {environment} from '../environments/environment';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {AppUpdateModalComponent} from './update/app-update-modal/app-update-modal.component';
import {AuthInterceptorService} from './auth/interceptors/auth-interceptor.service';
import {RcylOrdersModule} from 'rcyl-orders';
import {HttpErrorInterceptorService} from './shared/interceptors/http-error-interceptor.service';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';

// disable console log
if (environment.production) {
    console.log = () => {};
}

export function initApp(appLoadService: AppInitializerService) {
    return () => appLoadService.initApp();
}

/*rollbar plugin*/
const rollbarConfig = {
    accessToken: '8ebfc598601146c78648f09765cbd8a1',
    captureUncaught: true,
    captureUnhandledRejections: true,
};
@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) {}
    handleError(err: any): void {
        this.rollbar.error(err.originalError || err);
    }
}
export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}
export const RollbarService = new InjectionToken<Rollbar>('rollbar');
/*-- rollbar plugin end--*/


export function configureLogging(loggingService: LoggingService): () => void {
    return () => loggingService.configure(environment.logging);
}

@NgModule({
    declarations: [
        AppComponent,
        MatchescategoryPipe,
        AppUpdateModalComponent],
    entryComponents: [AppUpdateModalComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        IonicModule.forRoot({
            mode: 'md',
            backButtonIcon: 'ios-arrow-back'
        }),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        IonicStorageModule.forRoot(),
        SelectProductsPageModule,
        ThirdPartyModule,
        LoadingBarHttpClientModule,
        RcylOrdersModule.forRoot({channel: 'RECYKAL'}),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true },
        { provide: APP_INITIALIZER, useFactory: initApp, deps: [AppInitializerService], multi: true },
        /*{ provide: ErrorHandler, useClass: RollbarErrorHandler, deps: [RollbarService]},*/
        /*{ provide: RollbarService, useFactory: rollbarFactory },*/
        {
            deps: [LoggingService],
            multi: true,
            provide: APP_INITIALIZER,
            useFactory: configureLogging
        },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    // Make the injector to be available in the entire module
    // so we can use it in the custom decorator
    static injector: Injector;

    constructor(injector: Injector) {
        AppModule.injector = injector;
    }
}
