import * as tslib_1 from "tslib";
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { LoggingService } from 'ionic-logging-service';
import { PickupService } from '../pickup.service';
import { AlertController, Events, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HelperService } from '../../shared/services/helper.service';
import { Subject, Subscription } from 'rxjs';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PRODUCT_ITEM_SELECTION_MODE } from '../../product/product.model';
import { ProductService } from '../../product/product.service';
import { MyEvents } from '../../shared/my-events.const';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { ProductItemSearchComponent } from '../../product/components/product-item-search/product-item-search.component';
import { PageTracker } from '../../shared/PageTracker';
var SelectProductsPage = /** @class */ (function () {
    function SelectProductsPage(pickupService, alertCtrl, router, loggingService, helper, analyticsService, modalController, statusBar, analytics, productService, events) {
        var _this = this;
        this.pickupService = pickupService;
        this.alertCtrl = alertCtrl;
        this.router = router;
        this.loggingService = loggingService;
        this.helper = helper;
        this.analyticsService = analyticsService;
        this.modalController = modalController;
        this.statusBar = statusBar;
        this.analytics = analytics;
        this.productService = productService;
        this.events = events;
        this.catalogData = []; // non grouped
        this.catalogDataSubscription = new Subscription();
        this.cartDataSubscription = new Subscription();
        this.SelectProductsPageViews = SelectProductsPageViews;
        this.resetSelection = new Subject();
        this.productItemSelectionMode = PRODUCT_ITEM_SELECTION_MODE;
        this.window = window;
        this.analyticsEventLogger = function (eventName, params, where) {
            _this.analyticsService.logEvent(eventName, params, where);
        };
        this.logger = loggingService.getLogger('all.products.component');
        this.catalogDataObservable = this.pickupService.catalogData.asObservable();
    }
    SelectProductsPage.prototype.ngAfterViewInit = function () {
        this.selectedProductData = null;
    };
    SelectProductsPage.prototype.ionViewDidEnter = function () {
        this.analytics.screenViewChanged(MyEvents.SCREENS.SELECTED_PRODUCTS);
    };
    SelectProductsPage.prototype.ngOnInit = function () {
        var _this = this;
        this.statusBar.backgroundColorByHexString('#ffffff');
        this.activePageView = SelectProductsPageViews.CATEGORIZED;
        this.cartDataSubscription = this.pickupService.cartData.subscribe(function (cartData) {
            if (cartData && cartData.storeData) {
                // re-fetch root categories if store changed.
                if (_this.storeId !== cartData.storeData.id) {
                    _this.storeId = cartData.storeData.id;
                    _this.getRootCategories();
                }
            }
            if (cartData && cartData.cartItems) {
                _this.cartItems = cartData.cartItems;
            }
        });
        this.events.subscribe('OPEN_QTY_EDIT_AND_SELECT_PRODUCT', function (data) {
            _this.showProductAddToCartModal(data, false).then();
        });
    };
    SelectProductsPage.prototype.getRootCategories = function () {
        this.pickupService.getCatalogRootCategoriesForSelectedStore('B2B');
    };
    SelectProductsPage.prototype.getCategoryData = function (catData, pathToCat) {
        this.pickupService.getProductCategoryDataForSelectedStore(catData, pathToCat, 'B2B');
    };
    SelectProductsPage.prototype.getCategoryDescendantItems = function (catData, pathToCat) {
        this.pickupService.getProductCategoryDescendentItemsForSelectedStore(catData, pathToCat, 'B2B');
    };
    SelectProductsPage.prototype.showProductAddToCartModal = function (productData, showBackdrop) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.pickupService.showProductQtyEditForPickupRequest(productData, false, showBackdrop).then(function (modalElement) {
                    modalElement.onDidDismiss().then(function (result) {
                        _this.resetSelection.next(true);
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    SelectProductsPage.prototype.recentlyUsedProductSelected = function (product) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.showProductAddToCartModal(product, false)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectProductsPage.prototype.categorizedProductSelected = function (product) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.selectedProductData = product;
                this.analyticsService.logEvent(MyEvents.ITEM_CLICKED, { context: MyEvents.CONTEXT.REQUEST_PICKUP });
                if (this.selectedProductData != null) {
                    this.showProductAddToCartModal(this.selectedProductData, true);
                }
                return [2 /*return*/];
            });
        });
    };
    SelectProductsPage.prototype.handelLeafClick = function (event) {
        if (event) {
            this.router.navigate([window.ROUTES.CREATE_PICKUP_SELECT_PRODUCTS_SELECT_FROM_CAT], {
                queryParams: {
                    catName: event.name,
                    catId: event.id
                }
            }).then();
        }
    };
    SelectProductsPage.prototype.openSearchItemsModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var searchItemsModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: ProductItemSearchComponent,
                            showBackdrop: true,
                            backdropDismiss: true,
                            componentProps: {
                                autoSelectedItems: this.cartItems,
                                disableAutoSelected: true,
                                analyticsEventLogger: this.analyticsEventLogger
                            }
                        })];
                    case 1:
                        searchItemsModal = _a.sent();
                        searchItemsModal.present().then();
                        return [2 /*return*/, searchItemsModal];
                }
            });
        });
    };
    SelectProductsPage.prototype.ngOnDestroy = function () {
        this.events.unsubscribe('OPEN_QTY_EDIT_AND_SELECT_PRODUCT');
        if (this.cartDataSubscription) {
            this.cartDataSubscription.unsubscribe();
        }
    };
    SelectProductsPage = tslib_1.__decorate([
        PageTracker(MyEvents.SCREENS.SELECT_PRODUCTS),
        tslib_1.__metadata("design:paramtypes", [PickupService,
            AlertController,
            Router,
            LoggingService,
            HelperService,
            AnalyticsService,
            ModalController,
            StatusBar,
            AnalyticsService,
            ProductService,
            Events])
    ], SelectProductsPage);
    return SelectProductsPage;
}());
export { SelectProductsPage };
var SelectProductsPageViews;
(function (SelectProductsPageViews) {
    SelectProductsPageViews[SelectProductsPageViews["RECENTLY_USED"] = 0] = "RECENTLY_USED";
    SelectProductsPageViews[SelectProductsPageViews["CATEGORIZED"] = 1] = "CATEGORIZED";
})(SelectProductsPageViews || (SelectProductsPageViews = {}));
