export interface SignUp {
    email: string;
    mobile: string;
    password: string;
    eventAssociated?: Event;
}

export class SignUp implements SignUp {
    constructor(
        public email: string,
        public mobile: string,
        public password: string,
        public eventAssociated?: Event
    ) {}
}
