import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AppChildRouteGuard = /** @class */ (function () {
    function AppChildRouteGuard(router) {
        this.router = router;
    }
    AppChildRouteGuard.prototype.canActivateChild = function (route, state) {
        /*if (state.url === '') {
            return true;
        }
        if ((window as any).IS_MOBILE) {
            if (state.url.startsWith('/mobile')) {
                return true;
            } else {
                console.log(state.url);
                return new Promise((resolve, reject) => {
                    this.router.navigateByUrl('/mobile' + state.url).then(() => {
                        return resolve(true);
                    }, () => {
                        return resolve(false);
                    });
                });
            }
        }*/
        return true;
    };
    AppChildRouteGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppChildRouteGuard_Factory() { return new AppChildRouteGuard(i0.ɵɵinject(i1.Router)); }, token: AppChildRouteGuard, providedIn: "root" });
    return AppChildRouteGuard;
}());
export { AppChildRouteGuard };
