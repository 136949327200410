/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popover-menus.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./popover-menus.component";
var styles_PopoverMenusComponent = [i0.styles];
var RenderType_PopoverMenusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopoverMenusComponent, data: {} });
export { RenderType_PopoverMenusComponent as RenderType_PopoverMenusComponent };
function View_PopoverMenusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClicked(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ion-label", [["font-bold", ""], ["text-large", ""]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(4, 0, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.displayText; _ck(_v, 4, 0, currVal_0); }); }
export function View_PopoverMenusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-list", [["lines", "none"], ["no-padding", ""]], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(1, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { lines: [0, "lines"] }, null), i1.ɵdid(2, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PopoverMenusComponent_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "none"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.menus; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PopoverMenusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popover-menus", [], null, null, null, View_PopoverMenusComponent_0, RenderType_PopoverMenusComponent)), i1.ɵdid(1, 114688, null, 0, i5.PopoverMenusComponent, [i3.PopoverController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopoverMenusComponentNgFactory = i1.ɵccf("app-popover-menus", i5.PopoverMenusComponent, View_PopoverMenusComponent_Host_0, { menus: "menus" }, {}, []);
export { PopoverMenusComponentNgFactory as PopoverMenusComponentNgFactory };
