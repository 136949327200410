import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../../pickup/pickup.model';
import {PickupService} from '../../../pickup/pickup.service';
import * as _ from 'lodash';
import {MarketService} from '../../../market/market.service';
import {Router} from '@angular/router';
import {FacebookEvents} from '../../../shared/my-events.const';

@Component({
    selector: 'app-product-item-search',
    templateUrl: './product-item-search.component.html',
    styleUrls: ['./product-item-search.component.scss'],
})
export class ProductItemSearchComponent implements OnInit {
    @Input() autoSelectedItems: Product[];
    @Input() disableAutoSelected: boolean;
    @Input() channelName: 'Marketplace' | 'B2B';
    @Input() analyticsEventLogger: (eventName: string, params: any, where?: 'FIREBASE' | 'FACEBOOK') => void;

    categorizedItems: any = {};

    constructor(private modalController: ModalController,
                private pickupService: PickupService,
                private marketService: MarketService,
                private router: Router) {
    }

    selectedItemId: string;
    showNoItems: boolean;

    ngOnInit() {
        this.channelName = !this.channelName ? 'B2B' : this.channelName;
    }

    closeModal() {
        this.modalController.dismiss().then();
    }

    searchItems(textToSearch) {
        if (textToSearch) {
            switch (this.channelName) {
                case 'B2B':
                    this.pickupService.searchProductsForSelectedStore(textToSearch).subscribe((data) => {
                        this.showNoItems = true;
                        this.categorizedItems = data;
                        this.analyticsEventLogger(FacebookEvents.StandardEvents.EVENT_NAME_SEARCHED, {
                            CONTENT_TYPE: 'PRODUCT_ITEM',
                            SEARCH_STRING: textToSearch,
                            SUCCESS: Object.keys(this.categorizedItems).length > 0 ? 'YES' : 'NO'
                        }, 'FACEBOOK');
                    }, () => {
                        this.categorizedItems = {};
                        this.analyticsEventLogger(FacebookEvents.StandardEvents.EVENT_NAME_SEARCHED, {
                            CONTENT_TYPE: 'PRODUCT_ITEM',
                            SEARCH_STRING: textToSearch,
                            SUCCESS: 'NO'
                        }, 'FACEBOOK');
                    });
                    break;
                case 'Marketplace':
                    this.marketService.searchProducts(textToSearch).subscribe((data) => {
                        this.showNoItems = true;
                        this.categorizedItems = data;
                        this.analyticsEventLogger(FacebookEvents.StandardEvents.EVENT_NAME_SEARCHED, {
                            CONTENT_TYPE: 'PRODUCT_ITEM',
                            SEARCH_STRING: textToSearch,
                            SUCCESS: Object.keys(this.categorizedItems).length > 0 ? 'YES' : 'NO'
                        }, 'FACEBOOK');
                    }, () => {
                        this.categorizedItems = {};
                        this.analyticsEventLogger(FacebookEvents.StandardEvents.EVENT_NAME_SEARCHED, {
                            CONTENT_TYPE: 'PRODUCT_ITEM',
                            SEARCH_STRING: textToSearch,
                            SUCCESS: 'NO'
                        }, 'FACEBOOK');
                    });
                    break;
            }

        } else {
            this.categorizedItems = {};
        }
    }

    isAutoSelectable(productItem) {
        return (_.some(this.autoSelectedItems, (item) => {
            return item.id === productItem.id;
        }));
    }

    markAutoSelectedItemDisable(item) {
        return this.disableAutoSelected && this.isAutoSelectable(item);
    }

    itemSelected(event) {
        switch (this.channelName) {
            case 'Marketplace':
                this.router.navigate([window.ROUTES.MARKET_LISTINGS + event.id], {skipLocationChange: true, replaceUrl: true}).then(() => {
                    this.modalController.dismiss().then();
                }, () => {
                    this.modalController.dismiss().then();
                });
                break;
            case 'B2B':
                this.showProductAddToCartModal(event, true).then();
                break;
        }
        this.selectedItemId = event.id;
    }

    async showProductAddToCartModal(productData?: Product, showBackdrop?: boolean) {
        this.pickupService.showProductQtyEditForPickupRequest(productData, false, showBackdrop).then((modalElement) => {
            modalElement.onDidDismiss().then(
                result => {
                    this.selectedItemId = null;
                    if (result.data) {
                        this.closeModal();
                    }
                    // this.resetSelection.next(true);
                }
            );
        });
    }

    isEmpty(obj) {
        return !Object.keys(obj).length;
    }

}
