import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { CodePush } from '@ionic-native/code-push/ngx';
import { environment } from '../environments/environment';
import { IsDebug } from '@ionic-native/is-debug';
import { SharedService } from './shared/shared.service';
import { LoggingService } from 'ionic-logging-service';
import { Network } from '@ionic-native/network/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { HelperService } from './shared/services/helper.service';
import { Storage } from '@ionic/storage';
import { AnalyticsService } from './shared/services/analytics.service';
import { PushNotificationService } from './shared/push-notification.service';
import { FirebaseConfig } from '@ionic-native/firebase-config/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AppUpdateModalComponent } from './update/app-update-modal/app-update-modal.component';
import { AuthService } from './auth/services/auth.service';
import { ForgotPasswordPage } from './auth/pages/forgot-password/forgot-password.page';
import { BUGSNAG_CLIENT } from './third-party/bugsnag/bugsnag.module';
import * as MobileDetect from 'mobile-detect';
var AppComponent = /** @class */ (function () {
    function AppComponent(platform, splashScreen, statusBar, authService, sharedService, router, codePush, isDebug, loggingService, network, deeplinks, helper, storage, analytics, notificationService, firebaseConfig, appVersion, modalController, alertController) {
        var _this = this;
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.authService = authService;
        this.sharedService = sharedService;
        this.router = router;
        this.codePush = codePush;
        this.isDebug = isDebug;
        this.network = network;
        this.deeplinks = deeplinks;
        this.helper = helper;
        this.storage = storage;
        this.analytics = analytics;
        this.notificationService = notificationService;
        this.firebaseConfig = firebaseConfig;
        this.appVersion = appVersion;
        this.modalController = modalController;
        this.alertController = alertController;
        this.authStateSubscription = new Subscription();
        this.logger = loggingService.getLogger('app.component');
        this.logger.entry('constructor', 'Success');
        this.initializeApp();
        this.logger.exit('constructor', 'Success');
        this.router.events.subscribe(function (event) {
            // close modal on click browser back button
            if (event instanceof NavigationEnd) {
                if (!window.IS_MOBILE) {
                    _this.modalController.getTop().then(function (currentModal) {
                        if (currentModal) {
                            window.history.forward();
                            currentModal.dismiss();
                        }
                    });
                }
            }
        });
    }
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        this.logger.entry('initializeApp', 'Success');
        // this.analytics.init();
        if (this.platform.is('cordova')) {
            if (window.IS_MOBILE && !window.IS_MOBILE_WEB) {
                this.notificationService.init();
                if (environment.production) {
                    this.checkRemoteConfigAndShowAppUpdateModal();
                }
            }
        }
        this.platform.ready().then(function () {
            _this.checkWebViewAndPromptForUpdate();
            // this.setAppVersionToBugSnag();
            _this.statusBar.overlaysWebView(false);
            _this.statusBar.styleDefault();
            _this.splashScreen.hide();
            if (environment.production) {
                // this.checkCodePush();
            }
            _this.isDebug.getIsDebug()
                .then(function (isDebug) {
                console.log('App Running in debug:', isDebug);
            })
                .catch(function (err) { return console.log(err); });
            _this.setDeepLinkHandler();
        });
        this.logger.exit('initializeApp', 'Success');
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.logger.entry('app.component.ngOnInit', 'Success');
        this.statusBar.backgroundColorByHexString('#ffffff');
        this.sharedService.checkAuthAccountDetailsAndRedirect().then(function () {
            // this.isOffline = false;
        }, function () {
            // this.isOffline = true;
            _this.sharedService.checkEmailVerificationPendingAndRedirect();
        });
        /*this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.analytics.screenViewChanged(event.urlAfterRedirects);
            } else if (event instanceof RouteConfigLoadEnd) {

            }
        });*/
        this.initData();
        /*this.network.onDisconnect().subscribe(() => {
            this.isOffline = true;
        });
        this.network.onConnect().subscribe(() => {
            this.isOffline = false;
        });*/
        this.logger.exit('app.component.ngOnInit', 'Success');
        // add desktop class
        if (!window.IS_MOBILE) {
            var getBodyElement = document.querySelector('.recykal');
            getBodyElement.classList.add('recykal-desktop');
        }
        if (!window.IS_MOBILE || window.IS_MOBILE_WEB) {
            this.loadFreshChatWeb();
        }
    };
    AppComponent.prototype.loadFreshChatWeb = function () {
        var jsElm = document.createElement('script');
        jsElm.type = 'application/javascript';
        jsElm.src = 'https://wchat.freshchat.com/js/widget.js';
        document.body.appendChild(jsElm);
    };
    AppComponent.prototype.checkWebViewAndPromptForUpdate = function () {
        var _this = this;
        // 45
        if ((this.platform.is('android') || this.platform.is('ios')) && !window.IS_MOBILE_WEB) {
            this.firebaseConfig.fetchAndActivate().then(function (data) {
                if (_this.platform.is('android')) {
                    var md = new MobileDetect(window.navigator.userAgent);
                    var webviewVersion = md.version('Chrome') || 0;
                    console.log(webviewVersion);
                    if (webviewVersion <= 45) {
                        _this.firebaseConfig.getString('android_system_webview').then(function (linkForUpdate) {
                            if (linkForUpdate) {
                                _this.alertController.create({
                                    header: 'Please update Android System Webview for better experience.',
                                    buttons: [
                                        {
                                            text: 'Continue',
                                            role: 'cancel',
                                            cssClass: 'secondary',
                                            handler: function (no) {
                                                console.log('Confirm Cancel: no');
                                            }
                                        }, {
                                            text: 'Update',
                                            handler: function () {
                                                window.open(linkForUpdate, '_system');
                                            }
                                        }
                                    ]
                                }).then(function (alertInstance) {
                                    alertInstance.present();
                                });
                            }
                        }).catch(function (err) {
                            _this.helper.showToast(err, 'danger').then();
                        });
                    }
                    console.log(md.version('Chrome'));
                }
            });
        }
    };
    AppComponent.prototype.checkCodePush = function () {
        /*check app running in debug mode or not*/
        var downloadProgress = function (progress) { console.log("Downloaded " + progress.receivedBytes + " of " + progress.totalBytes); };
        this.codePush.sync({
            installMode: 0,
            deploymentKey: (this.platform.is('ios') ? environment.codePush.keys.ios : environment.codePush.keys.android)
        }, downloadProgress).subscribe(function (syncStatus) { return console.log(syncStatus); });
    };
    AppComponent.prototype.initData = function () {
        this.sharedService.getCities().first().subscribe(function () { }, function (err) {
        });
        this.sharedService.getPortalConfig().first().subscribe(function () { }, function (err) {
        });
        /*remove app loader from dom*/
        setTimeout(function () {
            var element = document.getElementById('app-body-loader');
            element.parentElement.removeChild(element);
        }, 2000);
    };
    AppComponent.prototype.start = function () {
    };
    AppComponent.prototype.setDeepLinkHandler = function () {
        var _this = this;
        this.deeplinks.route({
            '/reset-password': ForgotPasswordPage,
            '/reset-password.html': ForgotPasswordPage
        }).subscribe(function (match) {
            if (_this.authService.isAuthenticated()) {
                _this.helper.showToast('Please logout to reset password.', 'danger').then();
            }
            else {
                // match.$route - the route we matched, which is the matched entry from the arguments to route()
                // match.$args - the args passed in the link
                // match.$link - the full link data
                _this.router.navigate([window.ROUTES.AUTH_FORGOT_PASSWORD], { queryParams: match.$args }).then();
                console.log('Successfully matched route', match);
            }
        }, function (nomatch) {
            // nomatch.$link - the full link data
            console.log('Got a deeplink that didn\'t match', nomatch);
        });
    };
    AppComponent.prototype.checkRemoteConfigAndShowAppUpdateModal = function () {
        var _this = this;
        this.firebaseConfig.fetchAndActivate().then(function (data) {
            _this.firebaseConfig.getString('current_app_version').then(function (remoteConfigAppVersion) {
                if (remoteConfigAppVersion) {
                    var appVersion_1 = JSON.parse(remoteConfigAppVersion);
                    if (_this.platform.is('ios')) {
                        return _this.appVersion.getVersionNumber().then(function (currentVersion) {
                            if (_this.helper.versionCompare(appVersion_1.ios, currentVersion) === 1) {
                                _this.showAppUpdateModal(appVersion_1).then();
                                console.log('need to update', appVersion_1.ios, currentVersion);
                            }
                        });
                    }
                    else if (_this.platform.is('android')) {
                        return _this.appVersion.getVersionNumber().then(function (currentVersion) {
                            if (_this.helper.versionCompare(appVersion_1.android, currentVersion) === 1) {
                                _this.showAppUpdateModal(appVersion_1).then();
                                console.log('need to update', appVersion_1.android, currentVersion, _this.helper.versionCompare(appVersion_1.android, currentVersion));
                            }
                        });
                    }
                }
            }).catch(function (err) {
                _this.helper.showToast(err, 'danger').then();
            });
        });
    };
    AppComponent.prototype.setAppVersionToBugSnag = function () {
        this.appVersion.getVersionNumber().then(function (currentVersion) {
            BUGSNAG_CLIENT.app = {
                version: currentVersion
            };
        }, function (err) { });
    };
    AppComponent.prototype.showAppUpdateModal = function (newAppVersion) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updateInfo, modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updateInfo = {};
                        updateInfo.current_app_version = newAppVersion;
                        return [4 /*yield*/, this.firebaseConfig.getString('app_install_link').then(function (data) {
                                if (data) {
                                    updateInfo.app_install_link = JSON.parse(data);
                                }
                            }).catch(function (err) {
                                _this.helper.showToast(err, 'danger');
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.firebaseConfig.getString('current_version_release_notes').then(function (data) {
                                if (data) {
                                    updateInfo.current_version_release_notes = JSON.parse(data);
                                }
                            }).catch(function (err) {
                                _this.helper.showToast(err, 'danger');
                            })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.modalController.create({
                                component: AppUpdateModalComponent,
                                componentProps: {
                                    updateInfo: updateInfo
                                },
                                cssClass: ['modal-auto-center', 'small'],
                                backdropDismiss: false
                            })];
                    case 3:
                        modal = _a.sent();
                        modal.present().then();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.authStateSubscription.unsubscribe();
    };
    return AppComponent;
}());
export { AppComponent };
