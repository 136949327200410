/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-learn-more.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "./product-learn-more.component";
var styles_ProductLearnMoreComponent = [i0.styles];
var RenderType_ProductLearnMoreComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductLearnMoreComponent, data: {} });
export { RenderType_ProductLearnMoreComponent as RenderType_ProductLearnMoreComponent };
export function View_ProductLearnMoreComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 20, "ion-content", [["padding", ""], ["page-bg-colored", ""]], null, null, null, i4.View_IonContent_0, i4.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i5.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(3, 16384, null, 0, i5.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 17, "ion-grid", [["no-padding", ""]], null, null, null, i4.View_IonGrid_0, i4.RenderType_IonGrid)), i1.ɵdid(5, 49152, null, 0, i5.IonGrid, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(6, 16384, null, 0, i5.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 14, "ion-row", [], null, null, null, i4.View_IonRow_0, i4.RenderType_IonRow)), i1.ɵdid(8, 49152, null, 0, i5.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 8, "ion-col", [["no-padding", ""], ["size", "12"], ["text-center", ""]], null, null, null, i4.View_IonCol_0, i4.RenderType_IonCol)), i1.ɵdid(10, 49152, null, 0, i5.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), i1.ɵdid(11, 16384, null, 0, i5.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 5, "ion-text", [["class", "product-name"], ["modal-heading", ""]], null, null, null, i4.View_IonText_0, i4.RenderType_IonText)), i1.ɵdid(13, 49152, null, 0, i5.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(14, 0, [" ", " "])), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "span", [["color-medium", ""], ["float-end", ""], ["font-bold", ""], ["text-small", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i5.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["HIDE"])), (_l()(), i1.ɵeld(18, 0, null, 0, 3, "ion-col", [["class", "learn-more"], ["size", "12"]], null, null, null, i4.View_IonCol_0, i4.RenderType_IonCol)), i1.ɵdid(19, 49152, null, 0, i5.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵeld(20, 0, null, 0, 1, "div", [["class", "ql-editor"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(21, 1)], function (_ck, _v) { var currVal_0 = "12"; _ck(_v, 10, 0, currVal_0); var currVal_2 = "12"; _ck(_v, 19, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.item == null) ? null : _co.item.name); _ck(_v, 14, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 20, 0, _ck(_v, 21, 0, i1.ɵnov(_v, 0), ((_co.item == null) ? null : _co.item.description))); _ck(_v, 20, 0, currVal_3); }); }
export function View_ProductLearnMoreComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "product-learn-more", [], null, null, null, View_ProductLearnMoreComponent_0, RenderType_ProductLearnMoreComponent)), i1.ɵdid(1, 114688, null, 0, i6.ProductLearnMoreComponent, [i5.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductLearnMoreComponentNgFactory = i1.ɵccf("product-learn-more", i6.ProductLearnMoreComponent, View_ProductLearnMoreComponent_Host_0, { item: "item" }, {}, []);
export { ProductLearnMoreComponentNgFactory as ProductLearnMoreComponentNgFactory };
