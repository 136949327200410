import * as tslib_1 from "tslib";
import { GLOBALS } from '../../constants';
import { Events, LoadingController, ToastController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { File } from '@ionic-native/file/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { RCYL_ORDERS_GLOBALS } from 'rcyl-orders';
import { JwtHelperService } from "@auth0/angular-jwt";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic-native/status-bar/ngx/index";
import * as i3 from "@ionic-native/file/ngx/index";
import * as i4 from "@ionic-native/in-app-browser/ngx/index";
import * as i5 from "@ionic-native/file-opener/ngx/index";
import * as i6 from "@ionic-native/open-native-settings/ngx/index";
import * as i7 from "@auth0/angular-jwt/src/jwthelper.service";
var HelperService = /** @class */ (function () {
    function HelperService(toastCtrl, statusBar, file, iab, fileOpener, loadingController, openNativeSettings, jwtService, events) {
        var _this = this;
        this.toastCtrl = toastCtrl;
        this.statusBar = statusBar;
        this.file = file;
        this.iab = iab;
        this.fileOpener = fileOpener;
        this.loadingController = loadingController;
        this.openNativeSettings = openNativeSettings;
        this.jwtService = jwtService;
        this.events = events;
        this.events.subscribe(RCYL_ORDERS_GLOBALS.EVENTS_TO_PUBLISH.SHOW_TOAST, function (data) {
            if (data && data.message) {
                _this.showToast(data.message, data.color).then();
            }
        });
        this.events.subscribe(RCYL_ORDERS_GLOBALS.EVENTS_TO_PUBLISH.VIEW_BG, function (data) {
            if (data && typeof data === 'string') {
                _this.statusBar.backgroundColorByHexString(data);
            }
        });
    }
    HelperService.prototype.decodeRawToken = function (token) {
        var helperJwt = new JwtHelperService();
        return helperJwt.decodeToken(token);
    };
    HelperService.prototype.getHttpError = function (err) {
        var message;
        if (err) {
            if (err.headers && err.headers.get(GLOBALS.HTTP_APP_KEY + '-error')) {
                message = err.headers.get(GLOBALS.HTTP_APP_KEY + '-error');
            }
            else if (err.status === 500) {
                message = 'Something went wrong. please try again!!!';
            }
            else if (err.status === 503) {
                message = 'Server is not reachable. please try after some time.';
            }
            else if (err.status === 0) {
                message = 'Request timeout. Please check if you are connected to Internet.';
            }
            else if (err.status === 404) {
                message = 'Requested resource not found.';
            }
            else {
                message = err.status + ' ' + err.statusText;
            }
        }
        console.log('Error', message);
        return message;
    };
    HelperService.prototype.getHttpErrorKey = function (err, showToast) {
        var errorCode = '';
        if (err) {
            if (err.headers && err.headers.get(GLOBALS.HTTP_APP_KEY + '-error-key')) {
                return err.headers.get(GLOBALS.HTTP_APP_KEY + '-error-key');
            }
        }
        return errorCode;
    };
    HelperService.prototype.showHttpError = function (err, showToast, duration) {
        var message = this.getHttpError(err);
        if (showToast) {
            this.showToast(message, (err && err.status >= 500) ? 'danger' : 'warning', duration).then();
        }
    };
    HelperService.prototype.showToast = function (message, color, duration, closeBtnText) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: message,
                            duration: duration ? duration : 5000,
                            color: color ? color : 'dark',
                            position: 'top',
                            showCloseButton: true,
                            cssClass: 'custom-toast',
                            closeButtonText: closeBtnText ? closeBtnText : 'DISMISS',
                            animated: false,
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        if (color) {
                            this.setStatusBarColorByIonicColor(color);
                        }
                        toast.onDidDismiss().then(function () {
                            if (color) {
                                _this.setStatusBarColorByIonicColor('');
                            }
                        });
                        return [2 /*return*/, toast];
                }
            });
        });
    };
    /*
    * @input options
    *
    * @example options input
    * {
            header: 'Toast header',
            message: 'Click to Close',
            position: 'top',
            buttons: [
                {
                    side: 'start',
                    icon: 'star',
                    text: 'Favorite',
                    handler: () => {
                        console.log('Favorite clicked');
                    }
                }, {
                    text: 'Done',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }
            ]
        }
    *
    * */
    HelperService.prototype.presentToastWithOptions = function (options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create(options)];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/, toast];
                }
            });
        });
    };
    HelperService.prototype.passwordConfirming = function (c) {
        if (c.get('password').value !== c.get('confirmPassword').value) {
            return { invalid: true };
        }
    };
    HelperService.prototype.getGroupBy = function (data, key) {
        var groupedData = {};
        data.forEach(function (a) {
            groupedData[a[key]] = groupedData[a[key]] || [];
            groupedData[a[key]].push(a);
        });
        return groupedData;
    };
    HelperService.prototype.setStatusBarColorByIonicColor = function (color) {
        switch (color) {
            case 'dark':
                this.statusBar.backgroundColorByHexString('#27353C');
                break;
            case 'danger':
                this.statusBar.backgroundColorByHexString('#D85757');
                break;
            case 'warning':
                this.statusBar.backgroundColorByHexString('#FCD974');
                break;
            case 'page-bg':
                this.statusBar.backgroundColorByHexString('#F2F2F2');
                break;
            default:
                this.statusBar.backgroundColorByHexString('#ffffff');
        }
    };
    HelperService.prototype.getFilterDate = function (data) {
        if (data) {
            var filterData = data.filter(function (a) {
                if (a.id === 'PLA101') {
                    return a;
                }
            });
            return filterData[0].id;
        }
    };
    HelperService.prototype.getFieldFromHttpResponse = function (resp, fieldName) {
        /*if (resp && resp.headers && fieldName) {
            return  resp.headers.get(fieldName);
        }*/
        return (resp && resp.headers && fieldName) ? resp.headers.get(fieldName) : null;
    };
    HelperService.prototype.downloadFile = function (fileName, fileData) {
        var _this = this;
        return this.file.writeFile(this.file.cacheDirectory, fileName, fileData, { replace: true }).then(function (data) {
            return data;
        }, function (e) {
            _this.showToast('Failed to download file. Please try again.', 'red').then();
        });
    };
    HelperService.prototype.downloadAndOpenFile = function (fileName, fileData) {
        var _this = this;
        return this.downloadFile(fileName, fileData).then(function (data) {
            return _this.openFile(data.nativeURL, 'application/pdf').then(function () {
            }, function () {
                _this.showToast('Failed to open file', 'danger').then();
            });
        });
    };
    HelperService.prototype.openInAppBrowser = function (urlToOpen, target) {
        this.iab.create(urlToOpen, target ? target : '_blank', 'location=yes,enableViewportScale=yes,hidden=no');
    };
    HelperService.prototype.openFile = function (filePath, mimeType) {
        return this.fileOpener.open(filePath, mimeType);
    };
    HelperService.prototype.createLoader = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadingController.create({
                            message: message ? message : 'Loading...',
                            mode: 'ios'
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    HelperService.prototype.openSettings = function (settingName) {
        var _this = this;
        return this.openNativeSettings.open(settingName).then(function () {
        }, function () {
            _this.showToast('Failed to open settings', 'danger').then();
        });
    };
    HelperService.prototype.updateObject = function (object, newValue, path) {
        var stack = path.split('>');
        while (stack.length > 1) {
            object = object[stack.shift()];
        }
        object[stack.shift()] = newValue;
    };
    HelperService.prototype.versionCompare = function (v1, v2, options) {
        var lexicographical = options && options.lexicographical;
        var zeroExtend = options && options.zeroExtend;
        var v1parts = v1.split('.');
        var v2parts = v2.split('.');
        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }
        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return NaN;
        }
        if (zeroExtend) {
            while (v1parts.length < v2parts.length) {
                v1parts.push('0');
            }
            while (v2parts.length < v1parts.length) {
                v2parts.push('0');
            }
        }
        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }
        for (var i = 0; i < v1parts.length; ++i) {
            if (v2parts.length === i) {
                return 1;
            }
            if (v1parts[i] === v2parts[i]) {
                continue;
            }
            else if (v1parts[i] > v2parts[i]) {
                return 1;
            }
            else {
                return -1;
            }
        }
        if (v1parts.length !== v2parts.length) {
            return -1;
        }
        return 0;
    };
    HelperService.prototype.conditionalValidator = function (condition, validator) {
        return function (control) {
            if (!condition()) {
                return null;
            }
            return validator(control);
        };
    };
    HelperService.prototype.trimAllLevelZero = function (obj) {
        Object.keys(obj).map(function (k) { return obj[k] = typeof obj[k] === 'string' ? obj[k].trim() : obj[k]; });
        return obj;
    };
    HelperService.prototype.parseAndShowGoogleLoginError = function (err) {
        var errorMessage = '';
        switch (err) {
            case 2:
                errorMessage = 'The installed version of Google Play services is out of date. ' +
                    'Please install/update latest version';
                break;
            case 3:
                errorMessage = 'The installed version of Google Play services has been disabled on this device.' +
                    ' Please enable it to continue.';
                break;
            case 4:
                errorMessage = 'The client attempted to connect to the service but the user is not signed in.';
                break;
            case 5:
                errorMessage = 'The client attempted to connect to the service with an invalid account name specified.';
                break;
            case 6:
                errorMessage = 'Completing the operation requires some form of resolution.';
                break;
            case 7:
                errorMessage = 'A network error occurred. Please check if you are connected to Internet or try after some time.';
                break;
            case 8:
                errorMessage = 'An internal error occurred. Please try after sometime.';
                break;
            case 10:
                errorMessage = 'The application is misconfigured.';
                break;
            case 13:
                errorMessage = 'The operation failed with no more detailed information.';
                break;
            case 14:
                errorMessage = 'A call was interrupted while waiting and did not run to completion.';
                break;
            case 15:
                errorMessage = 'Request timeout. Please check if you are connected to Internet.';
                break;
            case 16:
                errorMessage = 'Client disconnected connection or canceled';
                break;
            case 17:
                errorMessage = 'Your device is not supported for google login. Please try alternative methods.';
                break;
        }
        this.showToast(errorMessage ? errorMessage : err, 'danger').then();
    };
    HelperService.prototype.getAllUrlParams = function (url) {
        var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
        var obj = {};
        if (queryString) {
            queryString = queryString.split('#')[0];
            var arr = queryString.split('&');
            for (var i = 0; i < arr.length; i++) {
                var a = arr[i].split('=');
                var paramName = a[0];
                var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
                paramName = paramName;
                if (typeof paramValue === 'string') {
                    paramValue = paramValue;
                }
                if (paramName.match(/\[(\d+)?\]$/)) {
                    var key = paramName.replace(/\[(\d+)?\]/, '');
                    if (!obj[key]) {
                        obj[key] = [];
                    }
                    if (paramName.match(/\[\d+\]$/)) {
                        var index = /\[(\d+)\]/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    }
                    else {
                        obj[key].push(paramValue);
                    }
                }
                else {
                    if (!obj[paramName]) {
                        obj[paramName] = paramValue;
                    }
                    else if (obj[paramName] && typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]];
                        obj[paramName].push(paramValue);
                    }
                    else {
                        obj[paramName].push(paramValue);
                    }
                }
            }
        }
        return obj;
    };
    HelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelperService_Factory() { return new HelperService(i0.ɵɵinject(i1.ToastController), i0.ɵɵinject(i2.StatusBar), i0.ɵɵinject(i3.File), i0.ɵɵinject(i4.InAppBrowser), i0.ɵɵinject(i5.FileOpener), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i6.OpenNativeSettings), i0.ɵɵinject(i7.JwtHelperService), i0.ɵɵinject(i1.Events)); }, token: HelperService, providedIn: "root" });
    return HelperService;
}());
export { HelperService };
