import {Directive, HostBinding, Input, OnInit} from '@angular/core';

// Define the Directive meta data
@Directive({
    selector: '[img-preloader]', // E.g <img mg-img-preloader="http://some_remote_image_url"
})

// Class must implement OnInit for @Input()
export class ImagePreloaderDirective implements OnInit {
    @HostBinding('attr.src') finalImage;
    @Input('img-preloader') targetSource: string;

    downloadingImage: any; // In class holder of remote image
    // finalImage: any; // property bound to our host attribute.

    // Set an input so the directive can set a default image.
    @Input() defaultImage = 'assets/imgs/img-loader.svg';
    @Input() errorImage = 'assets/imgs/img-placeholder.svg';

    constructor() {
        this.finalImage = this.defaultImage;
    }

    // ngOnInit is needed to access the @inputs() variables. these aren't available on constructor()
    ngOnInit() {
        // First set the final image to some default image while we prepare our preloader:
        // this.finalImage = this.defaultImage;

        this.downloadingImage = new Image();  // create image object
        this.downloadingImage.onload = () => { // Once image is completed, console.log confirmation and switch our host attribute
            setTimeout(() => {
                this.finalImage = this.targetSource;  // do the switch
            }, 200);
        };
        // Assign the src to that of some_remote_image_url. Since its an Image Object the
        // on assignment from this.targetSource download would start immediately in the background
        // and trigger the onload()

        // if image failed to load
        this.downloadingImage.onerror = () => {
            this.finalImage = this.errorImage;
        };

        this.downloadingImage.src = this.targetSource;
    }

}
