import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { AuthService } from '../services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "../../shared/shared.service";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(authService, router, sharedService) {
        this.authService = authService;
        this.router = router;
        this.sharedService = sharedService;
    }
    AuthGuardService.prototype.canActivate = function (next, state) {
        console.log('AuthGuard#canActivate called');
        if (this.authService.isAuthenticated()) {
            return true;
        }
        else {
            this.sharedService.goToLoginOrWalkThrough();
            return false;
        }
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.SharedService)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
