import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FileManagerService } from '../../services/file-manager.service';
import { ImgViewerComponent } from 'rcyl-orders';
var ImgListComponent = /** @class */ (function () {
    function ImgListComponent(modalController, fileManagerService) {
        var _this = this;
        this.modalController = modalController;
        this.fileManagerService = fileManagerService;
        this.onDeleteImg = new EventEmitter();
        this.downloadDocumentCallback = function (document) {
            _this.fileManagerService.downloadFile(document);
        };
    }
    ImgListComponent.prototype.ngOnInit = function () {
    };
    ImgListComponent.prototype.deleteImg = function (doc) {
        this.onDeleteImg.emit(doc);
    };
    ImgListComponent.prototype.onImageClick = function (doc) {
        this.showImg(doc).then();
    };
    ImgListComponent.prototype.showImg = function (document) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(document && document.url)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.modalController.create({
                                component: ImgViewerComponent,
                                componentProps: {
                                    document: document,
                                    componentOptions: {
                                        showDownload: true
                                    },
                                    downloadCallback: this.downloadDocumentCallback
                                },
                                cssClass: 'full-width-modal'
                            })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ImgListComponent;
}());
export { ImgListComponent };
