<ion-grid no-padding >
    <ion-row no-padding>
        <ion-col size="12" no-padding>
            <div class="container">
                <ion-list lines="none" class="ion-no-padding step-list">
                    <ion-item  class="step-item" [ngClass]=" {'data-selected' : cardData?.storeData, 'step-activated': (selectedMenu === 'STORE') }" (click)="gotoSelectStore()" >
                        <div class="sequence-number" >
                            <span>1</span>
                        </div>
                        <div class="check-icon" >
                            <ion-icon name="checkmark"></ion-icon>
                        </div>
                        <ion-label class="step">
                            <div class="step-number">Step 1</div>
                            <div class="title"> {{ (cardData?.storeData) ? 'Location Selected' : 'Select Location'}} </div>
                        </ion-label>
                    </ion-item>
                    <ion-item  class="step-item" [ngClass]=" {'data-selected' : (cardData?.cartItems?.length>0), 'step-activated': (selectedMenu === 'ITEMS'), 'proceed-to-next': (cardData?.storeData) }" (click)="gotoSelectItems()">
                        <div class="sequence-number" >
                            <span>2</span>
                        </div>
                        <div class="check-icon" >
                            <ion-icon name="checkmark"></ion-icon>
                        </div>
                        <ion-label class="step">
                            <div class="step-number">Step 2</div>
                            <div class="title"> {{ (cardData?.cartItems?.length>0) ? 'Product Summary' : 'Select Recyclables'}} </div>
                        </ion-label>
                    </ion-item>
                    <ion-item  class="step-item" [ngClass]=" {'data-selected' : (cardData?.selectedTimeslot?.id > 0 ), 'step-activated': (selectedMenu === 'TIMESLOT'), 'proceed-to-next': (cardData?.cartItems?.length>0) }" (click)="gotoSelectDate()">
                        <div class="sequence-number" >
                            <span>3</span>
                        </div>
                        <div class="check-icon" >
                            <ion-icon name="checkmark"></ion-icon>
                        </div>
                        <ion-label class="step">
                            <div class="step-number">Step 3</div>
                            <div class="title">Select Date</div>
                        </ion-label>
                    </ion-item>
                </ion-list>
            </div>
        </ion-col>
    </ion-row>
</ion-grid>
<ion-footer padding no-border page-bg-colored class="desktop-footer-section" *ngIf="buttonDisplay">
    <ion-grid no-padding>
        <ion-row>
            <ion-col size="12" no-padding class="back-step">
                <ion-button  no-margin size="large"  strong (click)="btnClicked(buttonData?.on_click)" >
                    <span class="back-icon" *ngIf="buttonData?.show_icon" ><ion-icon  [name]="buttonData?.show_icon" ></ion-icon></span> {{buttonData?.text}}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>
