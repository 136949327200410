import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-iframe-component',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent implements OnInit {
  @Input() url: string;
  @Input() title: string;
  @Input() showModalClose: boolean;
  @ViewChild('frame', {static: true}) frame: ElementRef;
  src: any;
  constructor(private sanitizer: DomSanitizer,  private modalController: ModalController) { }
  ngOnInit() {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.frame.nativeElement.addEventListener('load', this.setStyle.bind(this));
  }

  setStyle(event) {
    try {
      const style = document.createElement('style');
      style.textContent =
          '.fixed-nav {' +
          '  display: none !important;' +
          '}'
      ;
      event.target.contentWindow.head.appendChild(style);
    } catch (e) {
      console.log('Failed to set style for iframe content');
    }
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
