import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ImgListComponent} from './img-list.component';
import {ImgViewerComponentModule} from 'rcyl-orders';
import {ImgPreloaderDirectiveModule} from '../../directives/img-preloader/img-preloader-directive.module';


@NgModule({
    declarations: [ImgListComponent],
    imports: [
        CommonModule,
        IonicModule,
        ImgViewerComponentModule,
        ImgPreloaderDirectiveModule
    ],
    exports: [ImgListComponent],
    entryComponents: [ImgListComponent]
})
export class ImgListComponentModule {
}
