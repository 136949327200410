/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./city-search-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./city-search-modal.component";
var styles_CitySearchModalComponent = [i0.styles];
var RenderType_CitySearchModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CitySearchModalComponent, data: {} });
export { RenderType_CitySearchModalComponent as RenderType_CitySearchModalComponent };
function View_CitySearchModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-col", [["margin-end", ""], ["margin-start", ""], ["size", "12"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemSelected(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(1, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), i1.ɵdid(2, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "ion-text", [["color", "dark"], ["font-bold", ""], ["text-medium", ""]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(4, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(5, 0, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "ion-text", [["class", "blurred-para"], ["text-small", ""]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(8, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(9, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = "12"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "dark"; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.state; _ck(_v, 9, 0, currVal_3); }); }
function View_CitySearchModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-col", [["margin-top", ""], ["size", "12"], ["text-center", ""]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(1, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), i1.ɵdid(2, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "ion-text", [["color", "primary"], ["font-bold", ""], ["text-medium", ""]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(4, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Sorry!! No cities found."]))], function (_ck, _v) { var currVal_0 = "12"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "primary"; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CitySearchModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 12, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(5, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 4, "ion-button", [["no-box-shadow", ""], ["no-margin", ""], ["slot", "icon-only"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(7, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(8, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "ion-icon", [["mode", "ios"], ["name", "arrow-back"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(10, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "ion-searchbar", [["autocomplete", "true"], ["debounce", "300"], ["mode", "md"], ["placeholder", "Search by city name"], ["searchIcon", ""]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14)._handleInputEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.searchItems(i1.ɵnov(_v, 13).value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonSearchbar_0, i2.RenderType_IonSearchbar)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TextValueAccessor]), i1.ɵdid(13, 49152, [["searchBar", 4]], 0, i3.IonSearchbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { autocomplete: [0, "autocomplete"], debounce: [1, "debounce"], mode: [2, "mode"], placeholder: [3, "placeholder"], searchIcon: [4, "searchIcon"] }, null), i1.ɵdid(14, 16384, null, 0, i3.TextValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 8, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(16, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 6, "ion-row", [["margin-top", ""]], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(18, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(19, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CitySearchModalComponent_1)), i1.ɵdid(21, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CitySearchModalComponent_2)), i1.ɵdid(23, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ios"; var currVal_1 = "arrow-back"; _ck(_v, 10, 0, currVal_0, currVal_1); var currVal_2 = "true"; var currVal_3 = "300"; var currVal_4 = "md"; var currVal_5 = "Search by city name"; var currVal_6 = ""; _ck(_v, 13, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.cityList; _ck(_v, 21, 0, currVal_7); var currVal_8 = _co.noCities; _ck(_v, 23, 0, currVal_8); }, null); }
export function View_CitySearchModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "city-search-modal", [], null, null, null, View_CitySearchModalComponent_0, RenderType_CitySearchModalComponent)), i1.ɵdid(1, 245760, null, 0, i6.CitySearchModalComponent, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CitySearchModalComponentNgFactory = i1.ɵccf("city-search-modal", i6.CitySearchModalComponent, View_CitySearchModalComponent_Host_0, { citySearchCallback: "citySearchCallback" }, {}, []);
export { CitySearchModalComponentNgFactory as CitySearchModalComponentNgFactory };
