import {ImagePreloaderDirective} from './img-preloader.directive';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';


@NgModule({
    declarations: [ImagePreloaderDirective],
    imports: [
        CommonModule,
        IonicModule,
    ],
    exports: [ImagePreloaderDirective]
})
export class ImgPreloaderDirectiveModule {
}
