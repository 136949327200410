import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {SharedService} from '../../shared/shared.service';
import {AuthService} from '../services/auth.service';
import {CustomerService} from '../../customer/customer.service';
import {environment} from '../../../environments/environment';
import {Storage} from '@ionic/storage';


const TOKEN_KEY = 'access_token';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(private storage: Storage,
                private sharedService: SharedService,
                private authService: AuthService,
                private customerService: CustomerService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const requestUpdate: any = {
            setHeaders: {
                // appCode: environment.app_code,
            }
        };
        const deviceInfo = this.customerService.getCustomerDeviceInfo();
        const accountInfo = this.authService.getAccountInfo();
        if (deviceInfo) {
            if (deviceInfo.appVersion) {
                requestUpdate.setHeaders.appVersion = deviceInfo.appVersion;
            }
            if (deviceInfo.uuid) {
                requestUpdate.setHeaders.device = deviceInfo.uuid;
            }
        }
        if (accountInfo && accountInfo.email) {
            requestUpdate.setHeaders.email = accountInfo.email;
        }
        if (/core/.test(request.url) || /stakeholders/.test(request.url) || /catalog/.test(request.url)) {
            const fullUrl = environment.api_url + request.url;
            if (!!this.authService.accessToken) {
                requestUpdate.setHeaders.Authorization = `Bearer ${this.authService.accessToken}`;
                requestUpdate.url = fullUrl;
            } else {
                requestUpdate.url = fullUrl;
            }
        }
        request = request.clone(requestUpdate);
        return next.handle(request);
    }
}
