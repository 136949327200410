<ion-grid *ngIf="!window.IS_MOBILE">
    <ion-row>
        <ion-col>
            <ion-item class="desktop-item-search">
                <ion-icon name="search" mode="ios"></ion-icon>
                <ion-input [(ngModel)]="itemSearchValue" placeholder="Search by items name, ID"  (ionChange)="searchItems(itemSearchValue)" ></ion-input>
                <ion-icon *ngIf="!searchingItems && itemSearchValue" name="close" class="cursor-pointer" (click)="clearSearch()" ></ion-icon>
                <ion-spinner *ngIf="searchingItems" name="crescent" slot="end"></ion-spinner>
            </ion-item>
        </ion-col>
    </ion-row>
    <ion-row margin-top *ngIf="itemSearchValue?.length">
        <ion-col size="12"  size-lg="4" size-md="4" size-xl="4" size-sm="4" *ngFor="let category of categorizedItems| keys" class="search-result-item">
          <div>
              <product-item-large
                      *ngFor="let item of categorizedItems[category]"
                      [item]="item"
                      [showPrice]="showItemPrice"
                      [textToHighlight]="itemSearchValue"
                      (onItemSelected)="itemSelected($event)"
                      [autoSelect]="isAutoSelectable(item)"
                      [selectionMode]="itemSelectionMode"
                      [selectedItemIdInGroup]="selectedItemId"
                      [disable]="markAutoSelectedItemDisable(item)"></product-item-large>
          </div>
        </ion-col>
        <ion-col margin-top size="12" text-center *ngIf="itemSearchValue?.length && !searchingItems && ((categorizedItems | json) == '{}') ">
            <ion-text font-bold text-medium color="primary">Sorry!! No items found.</ion-text>
        </ion-col>
    </ion-row>
</ion-grid>
<ion-grid *ngIf="itemSelectionMode == productItemSelectionMode.SINGLE_SELECT" [hidden]="itemSearchValue?.length && !window.IS_MOBILE" no-padding   >
    <ion-row>
        <ion-col size="12" no-padding>
            <ion-list *ngFor="let category of catalogData; let path = index;">
                <ion-list-header
                        (click)="onCategoryClick(category, appendPath(path, ''), category.name); showHideList(catalogData, category)"
                        [ngClass]="{'selected': category.showHide}">
                    <ion-text font-bold>{{category?.name | uppercase}} </ion-text>
                    <ion-icon margin [src]="category.showHide?'./assets/imgs/Minus.svg':'./assets/imgs/Expand.svg'"></ion-icon>
                </ion-list-header>
                <ng-container *ngTemplateOutlet="recursiveList; context:{ catData:category, objPath:path, topCatName: category.name}">
                </ng-container>
                <p text-small text-center
                   [hidden]="!(category?.showHide && (category?.items)?.length === 0 && category?.subCategories?.length === 0)">
                    No products in selected category.
                </p>
            </ion-list>
            <p text-small text-center *ngIf="catalogData?.length === 0">
                No products to select.
            </p>
        </ion-col>
    </ion-row>
</ion-grid>
<ng-template #recursiveList let-category="catData" let-path="objPath" let-topCatName="topCatName">
    <div [hidden]="!category?.showHide">
        <div *ngFor="let subCat of category?.subCategories; let pathToAppend = index">
            <ion-item text-wrap="" lines="none" class="category-item cursor-pointer"
                      (click)="onCategoryClick(subCat, appendPath(path + '>subCategories', pathToAppend), topCatName); showHideList(category?.subCategories, subCat)">
                <ion-img slot="start" [img-preloader]="subCat?.icon"></ion-img>
                <ion-label class="cat-name" text-wrap>
                    <span color-primary font-bold text-medium>{{subCat?.name}}</span>
                </ion-label>
                <ion-icon *ngIf="!subCat?.leafWithSpecs" slot="end" no-margin [ngClass]="subCat?.showHide ? 'expand' : ''"
                          [name]="subCat?.showHide ? 'ios-arrow-dropup-circle': 'ios-arrow-dropdown-circle'">
                </ion-icon>
                <ion-icon *ngIf="subCat?.leafWithSpecs" slot="end" no-margin name="ios-arrow-dropright-circle">
                </ion-icon>
            </ion-item>
            <div style="margin-left: 4%">
                <ng-container
                        *ngTemplateOutlet="recursiveList; context:{ catData:subCat, objPath:appendPath(path + '>subCategories', pathToAppend), topCatName: topCatName}">
                </ng-container>
            </div>
        </div>
    </div>
    <div [hidden]="!category?.showHide" class="ion-radio-custom" [@listAnimation]="category?.items?.length">
        <product-item-large [item]="item"
                            [showPrice]="showItemPrice"
                            (onItemSelected)="itemSelected($event)"
                            [autoSelect]="isAutoSelectable(item)"
                            [selectionMode]="itemSelectionMode"
                            [selectedItemIdInGroup]="selectedItemId"
                            [disable]="markAutoSelectedItemDisable(item)"
                            *ngFor="let item of category?.items">
        </product-item-large>
    </div>
</ng-template>
