import * as tslib_1 from "tslib";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelperService } from '../services/helper.service';
import { CustomHttpParams } from '../overrides/custom-http-params.class';
import * as i0 from "@angular/core";
import * as i1 from "../services/helper.service";
var HttpErrorInterceptorService = /** @class */ (function () {
    function HttpErrorInterceptorService(helper) {
        this.helper = helper;
    }
    HttpErrorInterceptorService.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(catchError(function (errorResponse) {
            console.log('HTTP ERROR: ', errorResponse);
            _this.handleHttpError(request, errorResponse).then();
            return throwError(errorResponse);
        }));
    };
    HttpErrorInterceptorService.prototype.handleHttpError = function (request, errorDetails) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(errorDetails
                            && errorDetails.error
                            && errorDetails.error.hasOwnProperty('errorCode')
                            && errorDetails.error.hasOwnProperty('errorType')
                            && errorDetails.error.hasOwnProperty('message'))) return [3 /*break*/, 4];
                        if (!(errorDetails.status >= 500)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.helper.showToast(errorDetails.error.errorCode + ': ' + errorDetails.error.message, 'danger')];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        if (request.params instanceof CustomHttpParams) {
                            if (request.params.interceptorConfig.ignoreErrorHandling) {
                                return [2 /*return*/];
                            }
                        }
                        this.helper.showToast(errorDetails.error.message, 'warning').then();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        if (errorDetails && errorDetails.status === 0) {
                            this.helper.showToast('Request timeout. Please check if you are connected to Internet.', 'danger').then();
                        }
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    HttpErrorInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpErrorInterceptorService_Factory() { return new HttpErrorInterceptorService(i0.ɵɵinject(i1.HelperService)); }, token: HttpErrorInterceptorService, providedIn: "root" });
    return HttpErrorInterceptorService;
}());
export { HttpErrorInterceptorService };
