import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../../pickup/pickup.model';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'product-learn-more',
  templateUrl: './product-learn-more.component.html',
  styleUrls: ['./product-learn-more.component.scss'],
})
export class ProductLearnMoreComponent implements OnInit {
  @Input() item: Product;
  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  closeModal() {
    this.modalController.dismiss().then();
  }

}
