import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Platform } from '@ionic/angular';
import { CustomerService } from '../../customer/customer.service';
import { API } from '../../shared/services/api.url';
import { environment } from '../../../environments/environment';
import { SignUp } from '../pages/sign-up/sign-up.model';
import { LoginRequest } from '../auth.model';
import { AppPreferences } from '@ionic-native/app-preferences/ngx';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/storage";
import * as i3 from "@ionic-native/google-plus/ngx/index";
import * as i4 from "@ionic/angular";
import * as i5 from "../../customer/customer.service";
import * as i6 from "@ionic-native/app-preferences/ngx/index";
var TOKEN_KEY = 'access_token';
var AuthService = /** @class */ (function () {
    function AuthService(httpClient, storage, googlePlus, plt, customerService, appPreferences) {
        this.httpClient = httpClient;
        this.storage = storage;
        this.googlePlus = googlePlus;
        this.plt = plt;
        this.customerService = customerService;
        this.appPreferences = appPreferences;
        this.authenticationState = new BehaviorSubject(false);
        this.accountInfo = new BehaviorSubject(null);
        this.privacyPolicyData = new BehaviorSubject(null);
    }
    AuthService.prototype.checkToken = function () {
        var _this = this;
        this.storage.get(TOKEN_KEY).then(function (res) {
            if (res) {
                _this.authenticationState.next(true);
            }
        });
    };
    AuthService.prototype.sendOtpLogin = function (reqBody) {
        reqBody = this.trimStringsInObj(reqBody);
        return this.httpClient.post("" + API.LOGIN_SEND_OTP(), reqBody)
            .map(function (data) {
            return data;
        });
    };
    AuthService.prototype.privacyPolicyCheck = function () {
        var _this = this;
        return this.httpClient.get("" + API.PRIVACY_POLICY_DETAILS()).map(function (data) {
            _this.privacyPolicyData.next(data);
            return data;
        });
    };
    AuthService.prototype.getUserPolicyDetails = function () {
        return this.httpClient.get("" + API.USER_POLICY_DETAILS()).map(function (data) {
            return data;
        });
    };
    AuthService.prototype.acceptPrivacyPolicy = function (reqBody) {
        return this.httpClient.post("" + API.ACCEPT_PRIVACY_POLICY(), reqBody).map(function (data) {
            return data;
        });
    };
    AuthService.prototype.verifyOtpLogin = function (reqBody) {
        var _this = this;
        reqBody = this.trimStringsInObj(reqBody);
        return this.httpClient.post("" + API.LOGIN_VERIFY_OTP(), reqBody)
            .map(function (data) {
            if (data.id_token) {
                _this.accessToken = data.id_token;
                _this.storage.set(TOKEN_KEY, data.id_token).then();
                _this.authenticationState.next(true);
            }
            return data;
        });
    };
    AuthService.prototype.sendOtpRegistration = function (reqBody) {
        reqBody = this.trimStringsInObj(reqBody);
        return this.httpClient.post("" + API.REGISTER_SEND_OTP(), reqBody)
            .map(function (data) {
            return data;
        });
    };
    AuthService.prototype.verifyOtpRegistration = function (reqBody) {
        var _this = this;
        reqBody = this.trimStringsInObj(reqBody);
        return this.httpClient.post("" + API.REGISTER_VERIFY_OTP(), reqBody)
            .map(function (data) {
            if (data.id_token) {
                _this.accessToken = data.id_token;
                _this.storage.set(TOKEN_KEY, data.id_token).then();
                _this.authenticationState.next(true);
            }
            return data;
        });
    };
    AuthService.prototype.createPassword = function (reqBody) {
        var _this = this;
        return this.httpClient.post("" + API.CREATE_PASSWORD(), reqBody).map(function (data) {
            if (data.id_token) {
                _this.accessToken = data.id_token;
                _this.storage.set(TOKEN_KEY, data.id_token).then();
                _this.authenticationState.next(true);
            }
            return data;
        });
    };
    AuthService.prototype.loginUserServiceToken = function (loginForm) {
        var _this = this;
        var formData = new LoginRequest(loginForm.email, loginForm.password, true);
        return this.httpClient.post("" + API.LOGIN(), formData)
            .map(function (data) {
            var respdata = data;
            console.log(respdata);
            if (respdata.id_token) {
                _this.accessToken = respdata.id_token;
                _this.storage.set(TOKEN_KEY, respdata.id_token).then();
                _this.authenticationState.next(true);
            }
            return respdata;
        });
    };
    AuthService.prototype.requestLoginUsingGoogleAuthCode = function (serverAuthCode, eventAssociated) {
        var _this = this;
        return this.httpClient.post("" + API.GOOGLE_LOGIN(), { authCode: serverAuthCode, eventAssociated: eventAssociated })
            .map(function (data) {
            var respdata = data;
            console.log(respdata);
            if (respdata.id_token) {
                _this.accessToken = respdata.id_token;
                _this.storage.set(TOKEN_KEY, respdata.id_token).then();
                _this.authenticationState.next(true);
            }
            return respdata;
        });
    };
    AuthService.prototype.googleLogin = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            if (_this.plt.is('cordova')) {
                // To get a serverAuthCode, you must pass in your webClientId and set offline to true.
                // If offline is true, but no webClientId is provided, the serverAuthCode will NOT be requested.
                _this.googlePlus.login({
                    webClientId: environment.google.web_client_id,
                    offline: true
                }).then(function (resp) {
                    resolve(resp);
                }, function (err) {
                    reject(err);
                });
            }
            else {
                reject('Invalid platform');
            }
        });
        return promise;
    };
    AuthService.prototype.signUpUser = function (signUpForm, eventAssociated) {
        var formData = new SignUp(signUpForm.username, signUpForm.phone, signUpForm.password, eventAssociated);
        return this.httpClient.post("" + API.REGISTRATION(), formData, { responseType: 'text' })
            .map(function (data) {
            /*const respdata = data;
            console.log(respdata);*/
            return data;
        });
    };
    AuthService.prototype.resendOtp = function (email) {
        var formData = { email: email };
        return this.httpClient.post("" + API.RESENDOTP(), formData)
            .map(function (data) {
            var respdata = data;
            // console.log(respdata);
            return respdata;
        });
    };
    AuthService.prototype.verifyOtp = function (email, verificationCode) {
        var formData = { email: email, verificationCode: verificationCode };
        return this.httpClient.post("" + API.VERIFICATION(), formData)
            .map(function (data) {
            return data;
        });
    };
    AuthService.prototype.resetPasswordRequest = function (resetmail) {
        var headers = new HttpHeaders({
            requestUrl: environment.forgotPasswordEndPoint
        });
        var options = { headers: headers };
        return this.httpClient.post("" + API.RESET_PASSWORD_REQUEST(), resetmail, options)
            .map(function (data) {
            // console.log(data);
            return data;
        });
    };
    AuthService.prototype.resetPassword = function (key, newPassword) {
        return this.httpClient.post("" + API.RESET_PASSWORD(), { key: key, newPassword: newPassword })
            .map(function (data) {
            // console.log(data);
            return data;
        });
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        return Promise.all([
            this.storage.remove(TOKEN_KEY),
            this.storage.get('WALKTHROUGH_DISPLAYED'),
            this.tryGoogleLogout(),
            this.storage.clear(),
            this.appPreferences.clearAll()
        ].map(function (promise) {
            return promise.then(function (value) {
                return { value: value, err: undefined, status: 'resolved' };
            }, function (err) {
                return { value: undefined, err: err, status: 'rejected' };
            });
        })).then(function (results) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(results && results[1] && results[1].status === 'resolved')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.storage.set('WALKTHROUGH_DISPLAYED', results[1].value)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.accessToken = null;
                        location.reload();
                        return [2 /*return*/];
                }
            });
        }); }, function () {
            _this.accessToken = null;
            location.reload();
        });
    };
    AuthService.prototype.tryGoogleLogout = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                _this.googlePlus.logout().then(function (data) {
                    resolve(data);
                }, function (err) {
                    reject(err);
                });
            }
            catch (e) {
                reject(e);
                console.log(e);
            }
        });
    };
    AuthService.prototype.isAuthenticated = function () {
        return this.authenticationState.value;
    };
    AuthService.prototype.setAccessTokenAndState = function (accessToken) {
        if (accessToken) {
            this.accessToken = accessToken;
            this.authenticationState.next(true);
            this.storage.set(TOKEN_KEY, accessToken).then();
        }
    };
    AuthService.prototype.fetchAccountDetails = function () {
        var _this = this;
        return this.httpClient.get("" + API.ACCOUNT()).map(function (data) {
            // todo skip-dashboard-redirect data.registrationDone = false;
            // data.registrationDone = false;
            /*if (data.registrationDone) {*/
            _this.customerService.setCustomerBsType(data);
            /*}*/
            _this.accountInfo.next(data);
            return data;
        });
    };
    AuthService.prototype.getAccountInfo = function () {
        return this.accountInfo.value;
    };
    AuthService.prototype.trimStringsInObj = function (obj) {
        if (obj) {
            Object.keys(obj).map(function (k) { return obj[k] = typeof obj[k] === 'string' ? obj[k].trim() : obj[k]; });
        }
        return obj;
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.GooglePlus), i0.ɵɵinject(i4.Platform), i0.ɵɵinject(i5.CustomerService), i0.ɵɵinject(i6.AppPreferences)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
