import {Injectable} from '@angular/core';
import {FirebaseMessaging} from '@ionic-native/firebase-messaging/ngx';
import {CustomerService} from '../customer/customer.service';
import {ChatService} from './services/chat.service';
import {Router} from '@angular/router';
import {HelperService} from './services/helper.service';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {
    constructor(private firebase: FirebaseMessaging,
                private chatService: ChatService,
                private customerService: CustomerService,
                private helper: HelperService,
                private router: Router) {
    }

    init() {

        this.getTokenAndSendDeviceInfo();

        this.firebase.onMessage()
            .subscribe(data => {
                this.onNotificationReceived(data, true);

            });

        this.firebase.onBackgroundMessage().subscribe((data) => {
            this.onNotificationReceived(data, true);
        });

        this.firebase.onTokenRefresh()
            .subscribe(() => {
                console.log(`Got a new token`);
                this.getTokenAndSendDeviceInfo();
            });
    }

    subscribeToTopic() {
    }

    unsubscribeFromTopic() {
    }

    getTokenAndSendDeviceInfo() {
        this.firebase.getToken()
            .then(token => {
                // save the token server-side and use it to push notifications to this device
                console.log(`The token is ${token}`);
                this.chatService.updatePushDeviceToken(token);
                this.customerService.sendCustomerDeviceInfo(token);
            })
            .catch(error => {
                console.log('Error getting token', error);
                this.customerService.sendCustomerDeviceInfo('');
            });
    }

    private onNotificationReceived(data: any, b: boolean) {
        console.log('Notification',  data);
        if (this.chatService.isFreshChatNotification(data)) {
            return;
        }
        // data.gcm coming null when app is in background
        if (data.type === 'Transactional' && data.click_action !== null && !data.gcm) {
            const queryString = data.click_action;
            let paramObj = {};
            if (queryString) {
                paramObj = this.helper.getAllUrlParams(queryString);
                console.log('QueryParamsObj', paramObj);
            }
            new URLSearchParams().get('filename');
            setTimeout(() => {
                this.router.navigate([encodeURI(data.click_action)], {queryParams: paramObj}).then();
                // this.router.navigate(['/transactions/4654']).then();
            }, 500);
        }
    }
}
