import bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
var ɵ0 = function (report) {
    if (report.originalError instanceof HttpErrorResponse) {
        report.ignore();
    }
    else {
        return true;
    }
};
var bugsnagConfig = {
    apiKey: '10f135d09f236b1945d53250614a8a26',
    appType: 'MOBILE_APP',
    notifyReleaseStages: ['production'],
    releaseStage: environment.production ? 'production' : 'development',
    beforeSend: ɵ0
};
// configure Bugsnag asap
export var BUGSNAG_CLIENT = bugsnag(bugsnagConfig);
// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler(BUGSNAG_CLIENT);
}
var BugsnagModule = /** @class */ (function () {
    function BugsnagModule() {
    }
    return BugsnagModule;
}());
export { BugsnagModule };
export { ɵ0 };
