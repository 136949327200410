import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppChildRouteGuard} from './app-child-route-guared.service';
import {AuthGuardService} from './auth/guards/auth-guard.service';


const mobileRoutes: Routes = [
    {
        path: 'mobile',
        children: [
            {
                path: '',
                loadChildren: './main/main.module#MainPageModule'
            },
            {
                path: 'auth',
                children: [
                    {
                        path: 'login',
                        loadChildren: './auth/pages/login/login.page.module#LoginPageModule'
                    },
                    {
                        path: 'registration',
                        loadChildren: './auth/pages/registration/registration.page.module#RegistrationPageModule'
                    },
                    {
                        path: 'forgot-password',
                        loadChildren: './auth/pages/forgot-password/forgot-password.module#ForgotPasswordPageModule'
                    },
                    {
                        path: '',
                        redirectTo: '/auth/login',
                        pathMatch: 'full'
                    }
                ]
            },
            {
                path: 'onboarding',
                children: [
                    {
                        path: '',
                        loadChildren: './onboarding/onboarding.module#OnboardingPageModule'
                    },
                    {
                        path: 'business-type',
                        loadChildren: './onboarding/business-type/business-type.module#BusinessTypePageModule',
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'contact-details',
                        loadChildren: './onboarding/contact-details/contact-details.module#ContactDetailsPageModule',
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'bank-details',
                        loadChildren: './onboarding/bank-details/bank-details.module#BankDetailsPageModule',
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'pick-up-address',
                        loadChildren: './onboarding/pick-up-address/pick-up-address.module#PickUpAddressPageModule',
                        canActivate: [AuthGuardService]
                    },
                ]
            },
            {
                path: 'pickup',
                children: [
                    {
                        path: 'select-store',
                        loadChildren: './pickup/select-store/select-store.module#SelectStorePageModule'
                    },
                    {
                        path: 'edit-store',
                        loadChildren: './pickup/select-store/select-store.module#SelectStorePageModule'
                    },
                    {
                        path: 'selected-products',
                        loadChildren: './pickup/selected-products/selected-products.module#SelectedProductsPageModule'
                    },
                    {
                        path: 'select-products',
                        loadChildren: './pickup/select-products/select-products.module#SelectProductsPageModule'
                    },
                    {
                        path: 'select-products/select-from-cat',
                        loadChildren: './pickup/select-products/select-from-cat/select-from-cat.module#SelectFromCatPageModule'
                    },
                    {
                        path: 'select-timeslot',
                        loadChildren: './pickup/select-timeslot/select-timeslot.module#SelectTimeslotPageModule'
                    },
                    {
                        path: 'recently-used-products',
                        loadChildren: './pickup/recently-used-products/recently-used-products.module#RecentlyUsedProductsPageModule'
                    }
                ]
            },
            {
                path: 'sustainability-impact-details',
                loadChildren: './sustainability-impact-details/sustainability-impact-details.module#SustainabilityImpactDetailsPageModule'
            },
            {
                path: 'transactions',
                children: [
                    {
                        path: '',
                        loadChildren: './transactions/transactions.module#TransactionsPageModule'
                    },
                    {
                        path: ':orderId/:isMarket',
                        loadChildren: './transactions/order-details/order-details.module#OrderDetailsPageModule'
                    },
                    {
                        path: ':orderId',
                        loadChildren: './transactions/order-details/order-details.module#OrderDetailsPageModule'
                    }
                ]
            },
            {
                path: 'success-onboard-aggregator',
                loadChildren: './dashboard/components/success-onboard-aggregator/success-onboard-aggregator.module#' +
                    'SuccessOnboardAggregatorPageModule'
            },
            {
                path: 'walkthrough',
                loadChildren: './walkthrough/walkthrough.module#WalkthroughPageModule'
            },
            {
                path: 'market',
                children: [
                    {
                        path: '',
                        loadChildren: './market/pages/market.page.module#MarketPageModule',
                    },
                    {
                        path: 'learn-more',
                        loadChildren: './market/pages/learn-more/learn-more.page.module#LearnMorePageModule'
                    },
                    {
                        path: 'add-listing/:itemId',
                        loadChildren: './market/pages/add-edit-listing/add-edit-listing.module#AddEditListingPageModule'
                    },
                    {
                        path: 'listings',
                        loadChildren: './market/pages/market-listing/market-listing.module#MarketListingPageModule'
                    },
                    {
                        path: 'select-product',
                        loadChildren: './market/pages/select-product/select-product.module#SelectProductPageModule'
                    }
                ]
            },
            {
                path: 'settings',
                children: [
                    {
                        path: 'support',
                        loadChildren: './settings/support/support.module#SupportPageModule'
                    },
                    {
                        path: 'login-information',
                        loadChildren: './settings/login-information/login-information.module#LoginInformationPageModule'
                    },
                    {
                        path: 'business-profile',
                        loadChildren: './settings/business-profile/business-profile.module#BusinessProfilePageModule'
                    },
                    {
                        path: 'account-information',
                        loadChildren: './settings/account-information/account-information.module#AccountInformationPageModule'
                    },
                    {
                        path: 'login-information/change-password',
                        loadChildren: './settings/login-information/change-password/change-password.module#ChangePasswordPageModule'
                    },
                    {
                        path: 'manage-locations',
                        loadChildren: './settings/manage-locations/manage-locations.module#ManageLocationsPageModule'
                    },
                    {
                        path: 'manage-locations/add-edit-location',
                        loadChildren: './settings/manage-locations/add-edit-location/add-edit-location.module#AddEditLocationPageModule'
                    },
                    {
                        path: 'manage-locations/add-edit-location/:storeId',
                        loadChildren: './settings/manage-locations/add-edit-location/add-edit-location.module#AddEditLocationPageModule'
                    }
                ]
            }
        ]
    }
];

const desktopRoute: Routes = [
    {
        path: 'main',
        loadChildren: './desktop/dashboard/pages/dashboard-container/dashboard-container.page.module#DashboardContainerPageModule',
    },
    {
        path: 'auth',
        loadChildren: './desktop/auth/pages/auth-container/auth-container.page.module#AuthContainerPageModule'
    },
    {
        path: 'onboarding',
        children: []
    },
    {
        path: 'pickup',
        children: [
            {
                path: 'select-store',
                loadChildren: './pickup/select-store/select-store.module#SelectStorePageModule'
            },
            {
                path: 'edit-store',
                loadChildren: './pickup/select-store/select-store.module#SelectStorePageModule'
            },
            {
                path: 'selected-products',
                loadChildren: './pickup/selected-products/selected-products.module#SelectedProductsPageModule'
            },
            {
                path: 'select-products',
                loadChildren: './pickup/select-products/select-products.module#SelectProductsPageModule'
            },
            {
                path: 'select-products/select-from-cat',
                loadChildren: './pickup/select-products/select-from-cat/select-from-cat.module#SelectFromCatPageModule'
            },
            {
                path: 'select-timeslot',
                loadChildren: './pickup/select-timeslot/select-timeslot.module#SelectTimeslotPageModule'
            },
            {
                path: 'recently-used-products',
                loadChildren: './pickup/recently-used-products/recently-used-products.module#RecentlyUsedProductsPageModule'
            },
        ]
    },
    {
        path: 'sustainability-impact-details',
        loadChildren: './sustainability-impact-details/sustainability-impact-details.module#SustainabilityImpactDetailsPageModule'
    },
    {
        path: 'success-onboard-aggregator',
        loadChildren: './dashboard/components/success-onboard-aggregator/success-onboard-aggregator.module#' +
            'SuccessOnboardAggregatorPageModule'
    },
];


const routes: Routes = [
    {
        path: '',
        children: [...mobileRoutes, ...desktopRoute],
        canActivateChild: [AppChildRouteGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {useHash: true})
    ],
    exports: [RouterModule],
    providers: [AppChildRouteGuard]
})
export class AppRoutingModule {
}
