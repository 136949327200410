import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from 'ionic-logging-service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthService } from '../../services/auth.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { HelperService } from '../../../shared/services/helper.service';
import { SharedService } from '../../../shared/shared.service';
import { MyEvents } from '../../../shared/my-events.const';
import { PageTracker } from '../../../shared/PageTracker';
var ForgotPasswordPage = /** @class */ (function () {
    function ForgotPasswordPage(authService, $fb, alertCtrl, router, loggingService, modal, activatedRoute, analyticsService, sharedService, helper) {
        this.authService = authService;
        this.$fb = $fb;
        this.alertCtrl = alertCtrl;
        this.router = router;
        this.modal = modal;
        this.activatedRoute = activatedRoute;
        this.analyticsService = analyticsService;
        this.sharedService = sharedService;
        this.helper = helper;
        this.passwordType = 'password';
        this.confirmPasswordType = 'password';
        this.window = window;
        this.logger = loggingService.getLogger('reset.password.page');
        this.viewName = ForgotPasswordPageViews.REQUEST_EMAIL;
    }
    ForgotPasswordPage.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.queryParams.subscribe(function (params) {
            _this.keyToResetPass = params.key;
            if (_this.keyToResetPass) {
                _this.viewName = ForgotPasswordPageViews.CHANGE_PASS;
                _this.resetResetPasswordForm();
            }
            else {
                _this.viewName = ForgotPasswordPageViews.REQUEST_EMAIL;
                _this.resetUserForm();
            }
        });
    };
    ForgotPasswordPage.prototype.resetUserForm = function () {
        this.userResetForm = this.$fb.group({
            username: [, Validators.compose([
                    Validators.required,
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
                ])]
        });
    };
    ForgotPasswordPage.prototype.resetResetPasswordForm = function () {
        this.resetPasswordForm = this.$fb.group({
            password: [, Validators.compose([
                    Validators.required,
                    Validators.pattern('^$|^[A-Za-z0-9]+'),
                    Validators.minLength(4)
                ])],
            confirmPassword: [, Validators.compose([
                    Validators.required,
                    Validators.pattern('^$|^[A-Za-z0-9]+'),
                    Validators.minLength(4)
                ])]
        }, { validator: this.helper.passwordConfirming });
    };
    ForgotPasswordPage.prototype.resetPasswordRequest = function () {
        var _this = this;
        var email = this.userResetForm.get('username').value;
        this.analyticsService.logEvent(MyEvents.RESET_PASS_REQUESTED);
        this.authService.resetPasswordRequest(email).subscribe(function (data) {
            _this.analyticsService.logEvent(MyEvents.RESET_PASS_REQUEST_SUCCESS);
            _this.modal.getModal('forgotPasswordEmailSent').open();
        }, function (error) {
            _this.analyticsService.logEvent(MyEvents.RESET_PASS_REQUEST_SUCCESS, { error: error });
        }, function () {
        });
    };
    ForgotPasswordPage.prototype.resetPassword = function () {
        var _this = this;
        this.authService.resetPassword(this.keyToResetPass, this.resetPasswordForm.get('password').value).subscribe(function (data) {
            _this.analyticsService.logEvent(MyEvents.RESET_PASS_SUCCESS);
            _this.router.navigate([window.ROUTES.AUTH_FORGOT_PASSWORD_RESET_SUCCESS]).then();
        }, function (error) {
            _this.analyticsService.logEvent(MyEvents.RESET_PASS_FAILED);
        }, function () {
        });
    };
    ForgotPasswordPage.prototype.redirectToLogin = function () {
        var _this = this;
        setTimeout(function () {
            _this.sharedService.goToLoginOrWalkThrough();
        }, 300);
    };
    ForgotPasswordPage = tslib_1.__decorate([
        PageTracker(MyEvents.SCREENS.FORGOT_PASSWORD_PAGE),
        tslib_1.__metadata("design:paramtypes", [AuthService,
            FormBuilder,
            AlertController,
            Router,
            LoggingService,
            NgxSmartModalService,
            ActivatedRoute,
            AnalyticsService,
            SharedService,
            HelperService])
    ], ForgotPasswordPage);
    return ForgotPasswordPage;
}());
export { ForgotPasswordPage };
export var ForgotPasswordPageViews;
(function (ForgotPasswordPageViews) {
    ForgotPasswordPageViews["REQUEST_EMAIL"] = "REQUEST_EMAIL";
    ForgotPasswordPageViews["CHANGE_PASS"] = "CHANGE_PASS";
})(ForgotPasswordPageViews || (ForgotPasswordPageViews = {}));
