import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentDetails} from '../../models/common.model';
import {ModalController} from '@ionic/angular';
import {FileManagerService} from '../../services/file-manager.service';
import {ImgViewerComponent} from 'rcyl-orders';

@Component({
  selector: 'img-list',
  templateUrl: './img-list.component.html',
  styleUrls: ['./img-list.component.scss'],
})
export class ImgListComponent implements OnInit {
  @Input() imgList: DocumentDetails[];
  @Input() showDelete: boolean;
  @Output() onDeleteImg = new EventEmitter();
  constructor(private modalController: ModalController,
              private fileManagerService: FileManagerService) { }

  ngOnInit() {
  }

  deleteImg(doc) {
    this.onDeleteImg.emit(doc);
  }

  onImageClick(doc: DocumentDetails) {
    this.showImg(doc).then();
  }

  async showImg(document) {
    if (document && document.url) {
      const modal = await this.modalController.create({
        component: ImgViewerComponent,
        componentProps: {
          document,
          componentOptions: {
            showDownload: true
          },
          downloadCallback: this.downloadDocumentCallback
        },
        cssClass: 'full-width-modal'
      });
      return await modal.present();
    }
  }

  downloadDocumentCallback = (document) => {
    this.fileManagerService.downloadFile(document);
  }

}
