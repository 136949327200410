<div>
  <ion-grid>
    <ion-row class="enter-qty" text-center input-white>
      <ion-col size="12"  no-padding>
        <ion-text text-small="" letter-space-2 font-bold margin-bottom>ENTER QUANTITY</ion-text>
      </ion-col>
      <ion-col size="9" text-center no-padding>
        <ion-item lines="none" border-radius-10 float-right [ngClass]="{'item-error': ((selectedQty <= 0 && selectedQty != null) || isFloat(selectedQty))}">
          <ion-input #qtyInput no-padding=""
                     no-margin font-bold  [(ngModel)]="selectedQty"
                     maxlength="10"
                     clear-on-edit
                     type="number" (ngModelChange)="onQtyChange()"></ion-input>
          <ion-label no-margin slot="end" text-medium color="medium">{{item?.unitType | lowercase}}</ion-label>
          <div no-margin slot="end" class="plus-minus-btn cursor-pointer">
            <ion-icon name="arrow-dropup" color="medium" (click)="selectedQty = selectedQty + 1 "></ion-icon>
            <ion-icon name="arrow-dropdown" color="medium" (click)="selectedQty > 0 ? selectedQty = selectedQty - 1 : selectedQty = 0"></ion-icon>
          </div>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row  *ngIf="item?.tradeType !== tradeTypes.S">
      <ion-col size="12" text-center>
        <ion-badge class="you-earn">
          <span class="span1" text-regular font-bold>You may earn</span>
          <span class="span2" font-bold>₹{{(item?.price * selectedQty) | number: '1.2-2'}}</span>
          <!--<ion-icon color="primary" name="arrow-dropup"></ion-icon>-->
          <!--<span class="span3" font-bold color-primary="">₹3/kg</span>-->
        </ion-badge>
      </ion-col>
      <ion-col size="12" text-center no-padding="" class="info-text" >
        <ion-text  color="medium">
          This is an approximate amount you may earn. Final amount will depend on the weights done by our pickup executive.
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="you-pay" *ngIf="item?.tradeType === tradeTypes.S" margin-top="">
      <ion-col size="12" text-center>
        <ion-badge>
          <span class="span1" text-regular font-bold>Est. service fee</span>
          <span class="span2" font-bold>₹{{(item?.price * selectedQty) | number: '1.2-2'}}</span>
        </ion-badge>
      </ion-col>
      <ion-col size="12">
        <div>
          <p padding-bottom-10="">
            <ion-text text-regular="" color="danger" font-bold="">Note</ion-text>
          </p>
          <p>
            <ion-text text-small="" color="medium">
              This item is non-recyclable and harmful for the environment.
              We <b>charge a minimal service fee from you</b> to make sure that it is disposed properly and does not end up in landfills.
            </ion-text>
          </p>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>


