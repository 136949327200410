import {ErrorHandler, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import {environment} from '../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';


const bugsnagConfig = {
  apiKey: '10f135d09f236b1945d53250614a8a26',
  appType: 'MOBILE_APP',
  notifyReleaseStages: ['production'],
  releaseStage: environment.production ? 'production' : 'development',
  beforeSend: (report) => {
    if (report.originalError instanceof HttpErrorResponse) {
      report.ignore();
    } else {
      return true;
    }
  }
};

// configure Bugsnag asap
export const BUGSNAG_CLIENT = bugsnag(bugsnagConfig);


// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler(BUGSNAG_CLIENT);
}


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],

  /* Pass the BugsnagErrorHandler class along to the providers for your module */
  providers: [ { provide: ErrorHandler, useFactory: errorHandlerFactory } ],
  /* other properties passed to the decorator omitted for brevity */

})
export class BugsnagModule { }
