<ion-grid>
  <ion-row>
    <ion-col size="12" text-center="" padding="">
      <ion-text font-bold="" text-large="">New version available!</ion-text><br/>
      <ion-label text-small>Please update your app to continue.</ion-label>
    </ion-col>
    <ion-col size="12" class="release-notes" padding text-center="">
      <ion-text text-medium font-bold>What's new ?</ion-text>
      <div text-left="" *ngIf="updateInfo?.current_version_release_notes?.notes?.length > 0">
        <ul padding-end>
          <li *ngFor="let note of updateInfo?.current_version_release_notes?.notes">
            {{note}}
          </li>
        </ul>
      </div>
    </ion-col>
    <ion-col size="12" text-center="" padding="">
      <ion-button button-no-shadow="" no-margin color="primary" (click)="updateClicked()">Update</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
