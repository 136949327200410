import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppChildRouteGuard implements CanActivateChild {

    constructor(private router: Router) {
    }
    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        /*if (state.url === '') {
            return true;
        }
        if ((window as any).IS_MOBILE) {
            if (state.url.startsWith('/mobile')) {
                return true;
            } else {
                console.log(state.url);
                return new Promise((resolve, reject) => {
                    this.router.navigateByUrl('/mobile' + state.url).then(() => {
                        return resolve(true);
                    }, () => {
                        return resolve(false);
                    });
                });
            }
        }*/
        return true;
    }
}
