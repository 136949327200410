export function PageTracker( pageName: string, params?: any ): ClassDecorator {
    return ( constructor: any ) => {
        constructor.prototype.track = ( ...args ) => {

        };
        // called every time page visit
        const ionViewDidEnterHook = 'ionViewDidEnter';
        const ionViewDidEnter = constructor.prototype[ionViewDidEnterHook];
        constructor.prototype[ionViewDidEnterHook] = ( ...args ) => {
            // using basic javascript events as we don't want any dependency injection to happen here
            // which may cause circular dependency problem.
            const customEvent = new CustomEvent('PageTracker:PageChanged', {detail: {
                    pageName,
                    params
                }});
            document.dispatchEvent(customEvent);
            console.log('PageTracker ionViewDidEnter', pageName);
            // if (ionViewDidEnter) {
            //     ionViewDidEnter.apply(this, args);
            // }
        };

        // called when page destroyed
        /*const ngOnDestroy = constructor.prototype.ngOnDestroy;
        constructor.prototype.ngOnDestroy = ( ...args ) => {
            // analyticsService.screenViewChanged(pageName);
            if (ngOnDestroy) {
                ngOnDestroy.apply(this, args);
            }
        };*/

    };
}
