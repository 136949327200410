import { API } from '../shared/services/api.url';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import * as _ from 'lodash';
import { ProductService } from '../product/product.service';
import { HelperService } from '../shared/services/helper.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "../product/product.service";
import * as i4 from "../shared/services/helper.service";
var MarketService = /** @class */ (function () {
    function MarketService(httpClient, modalController, productService, helperService) {
        this.httpClient = httpClient;
        this.modalController = modalController;
        this.productService = productService;
        this.helperService = helperService;
        this.catalogData = new BehaviorSubject([]);
    }
    MarketService.prototype.fetchAllCategories = function () {
        return this.httpClient.get("" + API.ALL_CATEGORIES()).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.fetchAllOffersForCat = function (catId) {
        return this.httpClient.get(API.ALL_OFFERS_BY_CAT() + "?categoryId=" + catId).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.fetchAllBidsForCat = function (catId) {
        return this.httpClient.get(API.ALL_BIDS_BY_CAT() + "?categoryId=" + catId).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.fetchAllBidsForItem = function (itmId) {
        return this.httpClient.get(API.ALL_BIDS() + "?itemId.equals=" + itmId).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.fetchAllOffersForItem = function (itmId) {
        return this.httpClient.get(API.ALL_OFFERS() + "?itemId.equals=" + itmId).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.saveBid = function (bidData, isUpdate) {
        return isUpdate ? this.httpClient.put("" + API.ALL_BIDS(), bidData) : this.httpClient.post("" + API.ALL_BIDS(), bidData);
    };
    MarketService.prototype.saveOffer = function (offerData, isUpdate) {
        return isUpdate ? this.httpClient.put("" + API.ALL_OFFERS(), offerData) : this.httpClient.post("" + API.ALL_OFFERS(), offerData);
    };
    MarketService.prototype.fetchBidDetails = function (bidId) {
        return this.httpClient.get(API.ALL_BIDS() + "/" + bidId);
    };
    MarketService.prototype.fetchOfferDetails = function (offerId) {
        return this.httpClient.get(API.ALL_OFFERS() + "/" + offerId);
    };
    MarketService.prototype.fetchOffersList = function (options) {
        var params = new HttpParams({ fromObject: options });
        return this.httpClient.get(API.ALL_OFFERS() + "?sort=modifiedOn,desc", {
            params: params,
            observe: 'response'
        }).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.fetchBidsList = function (options) {
        var params = new HttpParams({ fromObject: options });
        return this.httpClient.get(API.ALL_BIDS() + "?sort=modifiedOn,desc", {
            params: params,
            observe: 'response'
        }).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.fetchMarketBidStats = function (itemId) {
        return this.httpClient.get(API.MARKET_BID_STATS() + "?itemId=" + itemId).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.fetchMarketOfferStats = function (itemId) {
        return this.httpClient.get(API.MARKET_OFFER_STATS() + "?itemId=" + itemId).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.fetchMarketOfferTransactions = function (id) {
        return this.httpClient.get(API.MARKET_OFFER_TRANSACTIONS() + "?channelName=Marketplace&offerId=" + id).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.fetchMarketBidTransactions = function (id) {
        return this.httpClient.get(API.MARKET_BID_TRANSACTIONS() + "?channelName=Marketplace&bidId=" + id).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.fetchAllListingStatuses = function () {
        /* catalog/listing-statuses*/
        return this.httpClient.get("" + API.LISTING_STATUSES()).map(function (data) {
            return data;
        });
    };
    MarketService.prototype.getCatalogRootCategories = function () {
        var _this = this;
        if (this.catalogData.value && this.catalogData.value.length === 0) {
            this.productService.fetchProductCategoriesForMarket().subscribe(function (data) {
                data = data ? _.orderBy(data, 'seq', 'asc') : data;
                _this.catalogData.next(data);
            }, function (err) {
            });
        }
    };
    MarketService.prototype.getProductCategoryData = function (catData, pathToCat) {
        var _this = this;
        if (catData &&
            catData.items === undefined &&
            catData.subCategories === undefined) {
            this.productService.fetchProductCategoryDataForMarket(catData.id).subscribe(function (data) {
                if (data) {
                    data.items = data.items ? _.orderBy(data.items, 'seq', 'asc') : data;
                    data.subCategories = data.subCategories ? _.orderBy(data.subCategories, 'seq', 'asc') : data;
                    // this.catalogData.next(data);
                    _this.helperService.updateObject(_this.catalogData.value, data.items, pathToCat + '>items');
                    _this.helperService.updateObject(_this.catalogData.value, data.subCategories, pathToCat + '>subCategories');
                    _this.catalogData.next(_this.catalogData.value);
                }
            }, function (err) {
            });
        }
    };
    MarketService.prototype.getProductCategoryDescendentItems = function (catData, pathToCat) {
        var _this = this;
        if (catData.items === undefined || (catData.items && catData.items.length === 0)) {
            this.productService.fetchProductCategoryDescendantItemsForMarket(catData.id).subscribe(function (data) {
                if (data) {
                    data.items = data.items ? _.orderBy(data.items, 'seq', 'asc') : data;
                    _this.helperService.updateObject(_this.catalogData.value, data, pathToCat + '>items');
                    // pushing empty cat array is required to show not items message in listing.
                    _this.helperService.updateObject(_this.catalogData.value, [], pathToCat + '>subCategories');
                    _this.catalogData.next(_this.catalogData.value);
                }
            }, function (err) {
            });
        }
    };
    MarketService.prototype.getProductsByAttributeIds = function (attrValues) {
        return this.productService.fetchProductsByAttributeIdsForMarket(attrValues);
    };
    MarketService.prototype.searchProducts = function (query) {
        return this.productService.searchAndFetchProductsForMarket(query);
    };
    MarketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MarketService_Factory() { return new MarketService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ModalController), i0.ɵɵinject(i3.ProductService), i0.ɵɵinject(i4.HelperService)); }, token: MarketService, providedIn: "root" });
    return MarketService;
}());
export { MarketService };
