/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./html-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "./html-modal.component";
var styles_HtmlModalComponent = [i0.styles];
var RenderType_HtmlModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HtmlModalComponent, data: {} });
export { RenderType_HtmlModalComponent as RenderType_HtmlModalComponent };
export function View_HtmlModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "ion-header", [["no-box-shadow", ""]], null, null, null, i4.View_IonHeader_0, i4.RenderType_IonHeader)), i1.ɵdid(2, 49152, null, 0, i5.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 15, "ion-toolbar", [["no-box-shadow", ""]], null, null, null, i4.View_IonToolbar_0, i4.RenderType_IonToolbar)), i1.ɵdid(4, 49152, null, 0, i5.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 6, "ion-buttons", [["slot", "start"]], null, null, null, i4.View_IonButtons_0, i4.RenderType_IonButtons)), i1.ɵdid(6, 49152, null, 0, i5.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "ion-button", [["no-box-shadow", ""], ["no-margin", ""], ["slot", "icon-only"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal(null, "closed") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(8, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(9, 16384, null, 0, i5.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "ion-icon", [["mode", "ios"], ["name", "arrow-back"]], null, null, null, i4.View_IonIcon_0, i4.RenderType_IonIcon)), i1.ɵdid(11, 49152, null, 0, i5.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 6, "ion-title", [["text-center", ""]], null, null, null, i4.View_IonTitle_0, i4.RenderType_IonTitle)), i1.ɵdid(13, 49152, null, 0, i5.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(14, 16384, null, 0, i5.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 3, "div", [["class", "custom-title with-backbtn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "ion-text", [["class", "heading-medium"]], null, null, null, i4.View_IonText_0, i4.RenderType_IonText)), i1.ɵdid(17, 49152, null, 0, i5.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(18, 0, ["", ""])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "ion-content", [["padding", ""]], [[8, "innerHTML", 1]], null, null, i4.View_IonContent_0, i4.RenderType_IonContent)), i1.ɵdid(20, 49152, null, 0, i5.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(21, 16384, null, 0, i5.CssUtilsDeprecations, [i1.ElementRef], null, null), i1.ɵppd(22, 1)], function (_ck, _v) { var currVal_0 = "ios"; var currVal_1 = "arrow-back"; _ck(_v, 11, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 18, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 19, 0, _ck(_v, 22, 0, i1.ɵnov(_v, 0), _co.htmlContent)); _ck(_v, 19, 0, currVal_3); }); }
export function View_HtmlModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-html-modal", [], null, null, null, View_HtmlModalComponent_0, RenderType_HtmlModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.HtmlModalComponent, [i5.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HtmlModalComponentNgFactory = i1.ɵccf("app-html-modal", i6.HtmlModalComponent, View_HtmlModalComponent_Host_0, { title: "title", htmlContent: "htmlContent" }, {}, []);
export { HtmlModalComponentNgFactory as HtmlModalComponentNgFactory };
