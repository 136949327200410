export function PageTracker(pageName, params) {
    return function (constructor) {
        constructor.prototype.track = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
        };
        // called every time page visit
        var ionViewDidEnterHook = 'ionViewDidEnter';
        var ionViewDidEnter = constructor.prototype[ionViewDidEnterHook];
        constructor.prototype[ionViewDidEnterHook] = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            // using basic javascript events as we don't want any dependency injection to happen here
            // which may cause circular dependency problem.
            var customEvent = new CustomEvent('PageTracker:PageChanged', { detail: {
                    pageName: pageName,
                    params: params
                } });
            document.dispatchEvent(customEvent);
            console.log('PageTracker ionViewDidEnter', pageName);
            // if (ionViewDidEnter) {
            //     ionViewDidEnter.apply(this, args);
            // }
        };
        // called when page destroyed
        /*const ngOnDestroy = constructor.prototype.ngOnDestroy;
        constructor.prototype.ngOnDestroy = ( ...args ) => {
            // analyticsService.screenViewChanged(pageName);
            if (ngOnDestroy) {
                ngOnDestroy.apply(this, args);
            }
        };*/
    };
}
