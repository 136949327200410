<ion-toolbar>
  <ion-searchbar mode="ios" [debounce]="250" [(ngModel)]="query" (ionInput)="searchPlace()" placeholder="Search Address here"></ion-searchbar>
  <ion-progress-bar type="indeterminate" *ngIf="searching"></ion-progress-bar>

</ion-toolbar>
<div class="inner-content" page-bg-colored>
  <ion-list bg-transperant=""  >
    <ion-item (click)="currentLocationClicked()">
      <ion-icon slot="start" name="locate" color="primary" ></ion-icon>
      <ion-label >
        <h2 text-regular="" font-bold>Current Location</h2>
        <p font-regular="" text-small="">Using GPS</p>
      </ion-label>
    </ion-item>
    <ion-item *ngFor="let place of places" (click)="onResultClick(place)">
      <ion-icon slot="start" name="pin"  ></ion-icon>
      <ion-label >
        <h2 text-regular="" font-bold>{{place.structured_formatting?.main_text}}</h2>
        <p font-regular="" text-small="">{{place.structured_formatting.secondary_text}}</p>
      </ion-label>
    </ion-item>
  </ion-list>
</div>
