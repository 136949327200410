import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { CustomerService } from '../customer/customer.service';
import { ChatService } from './services/chat.service';
import { Router } from '@angular/router';
import { HelperService } from './services/helper.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/firebase-messaging/ngx/index";
import * as i2 from "./services/chat.service";
import * as i3 from "../customer/customer.service";
import * as i4 from "./services/helper.service";
import * as i5 from "@angular/router";
var PushNotificationService = /** @class */ (function () {
    function PushNotificationService(firebase, chatService, customerService, helper, router) {
        this.firebase = firebase;
        this.chatService = chatService;
        this.customerService = customerService;
        this.helper = helper;
        this.router = router;
    }
    PushNotificationService.prototype.init = function () {
        var _this = this;
        this.getTokenAndSendDeviceInfo();
        this.firebase.onMessage()
            .subscribe(function (data) {
            _this.onNotificationReceived(data, true);
        });
        this.firebase.onBackgroundMessage().subscribe(function (data) {
            _this.onNotificationReceived(data, true);
        });
        this.firebase.onTokenRefresh()
            .subscribe(function () {
            console.log("Got a new token");
            _this.getTokenAndSendDeviceInfo();
        });
    };
    PushNotificationService.prototype.subscribeToTopic = function () {
    };
    PushNotificationService.prototype.unsubscribeFromTopic = function () {
    };
    PushNotificationService.prototype.getTokenAndSendDeviceInfo = function () {
        var _this = this;
        this.firebase.getToken()
            .then(function (token) {
            // save the token server-side and use it to push notifications to this device
            console.log("The token is " + token);
            _this.chatService.updatePushDeviceToken(token);
            _this.customerService.sendCustomerDeviceInfo(token);
        })
            .catch(function (error) {
            console.log('Error getting token', error);
            _this.customerService.sendCustomerDeviceInfo('');
        });
    };
    PushNotificationService.prototype.onNotificationReceived = function (data, b) {
        var _this = this;
        console.log('Notification', data);
        if (this.chatService.isFreshChatNotification(data)) {
            return;
        }
        // data.gcm coming null when app is in background
        if (data.type === 'Transactional' && data.click_action !== null && !data.gcm) {
            var queryString = data.click_action;
            var paramObj_1 = {};
            if (queryString) {
                paramObj_1 = this.helper.getAllUrlParams(queryString);
                console.log('QueryParamsObj', paramObj_1);
            }
            new URLSearchParams().get('filename');
            setTimeout(function () {
                _this.router.navigate([encodeURI(data.click_action)], { queryParams: paramObj_1 }).then();
                // this.router.navigate(['/transactions/4654']).then();
            }, 500);
        }
    };
    PushNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushNotificationService_Factory() { return new PushNotificationService(i0.ɵɵinject(i1.FirebaseMessaging), i0.ɵɵinject(i2.ChatService), i0.ɵɵinject(i3.CustomerService), i0.ɵɵinject(i4.HelperService), i0.ɵɵinject(i5.Router)); }, token: PushNotificationService, providedIn: "root" });
    return PushNotificationService;
}());
export { PushNotificationService };
