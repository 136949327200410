<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button no-margin no-box-shadow slot="icon-only" (click)="closeModal()">
        <ion-icon name="arrow-back" mode="ios"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-searchbar #searchBar debounce="300" autocomplete="true"
                   (ionChange)="searchItems(searchBar.value)" mode="md"
                   placeholder="Search by city name" searchIcon="">
    </ion-searchbar>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row margin-top>
    <ion-col size="12" (click)="itemSelected(city)" *ngFor="let city of cityList" margin-start="" margin-end="">
      <ion-text  color="dark" font-bold text-medium>
        {{city.name}}
      </ion-text>
      <br/>
      <ion-text text-small="" class="blurred-para">
        {{city.state}}
      </ion-text>
    </ion-col>
    <ion-col margin-top size="12" text-center *ngIf="noCities">
      <ion-text font-bold text-medium color="primary">Sorry!! No cities found.</ion-text>
    </ion-col>
  </ion-row>
</ion-content>
