import { ErrorHandler, InjectionToken, Injector } from '@angular/core';
import { LoggingService } from 'ionic-logging-service';
import { AppInitializerService } from './init/services/app-initializer.service';
import * as Rollbar from 'rollbar';
import { environment } from '../environments/environment';
import { JWT_OPTIONS } from '@auth0/angular-jwt';
// disable console log
if (environment.production) {
    console.log = function () { };
}
export function initApp(appLoadService) {
    return function () { return appLoadService.initApp(); };
}
/*rollbar plugin*/
var rollbarConfig = {
    accessToken: '8ebfc598601146c78648f09765cbd8a1',
    captureUncaught: true,
    captureUnhandledRejections: true,
};
var RollbarErrorHandler = /** @class */ (function () {
    function RollbarErrorHandler(rollbar) {
        this.rollbar = rollbar;
    }
    RollbarErrorHandler.prototype.handleError = function (err) {
        this.rollbar.error(err.originalError || err);
    };
    return RollbarErrorHandler;
}());
export { RollbarErrorHandler };
export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}
export var RollbarService = new InjectionToken('rollbar');
/*-- rollbar plugin end--*/
export function configureLogging(loggingService) {
    return function () { return loggingService.configure(environment.logging); };
}
var ɵ0 = JWT_OPTIONS;
var AppModule = /** @class */ (function () {
    function AppModule(injector) {
        AppModule.injector = injector;
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
