import {Component, OnInit} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Logger, LoggingService} from 'ionic-logging-service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {AuthService} from '../../services/auth.service';
import {AnalyticsService} from '../../../shared/services/analytics.service';
import {HelperService} from '../../../shared/services/helper.service';
import {SharedService} from '../../../shared/shared.service';
import {MyEvents} from '../../../shared/my-events.const';
import {PageTracker} from '../../../shared/PageTracker';

@PageTracker(MyEvents.SCREENS.FORGOT_PASSWORD_PAGE)
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {

  public userResetForm: FormGroup;
  public resetPasswordForm: FormGroup;
  private logger: Logger;
  private keyToResetPass: string;
  viewName: ForgotPasswordPageViews;
  passwordType = 'password';
  confirmPasswordType = 'password';
  window = window;
  constructor(private authService: AuthService,
              public $fb: FormBuilder,
              private alertCtrl: AlertController,
              private router: Router,
              loggingService: LoggingService,
              private modal: NgxSmartModalService,
              private activatedRoute: ActivatedRoute,
              private analyticsService: AnalyticsService,
              private sharedService: SharedService,
              private helper: HelperService) {
    this.logger = loggingService.getLogger('reset.password.page');
    this.viewName = ForgotPasswordPageViews.REQUEST_EMAIL;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.keyToResetPass = params.key;
      if (this.keyToResetPass) {
        this.viewName = ForgotPasswordPageViews.CHANGE_PASS;
        this.resetResetPasswordForm();
      } else {
        this.viewName = ForgotPasswordPageViews.REQUEST_EMAIL;
        this.resetUserForm();
      }
    });
  }
  resetUserForm() {
    this.userResetForm = this.$fb.group({
      username: [, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ])]
    });
  }
  resetResetPasswordForm() {
    this.resetPasswordForm = this.$fb.group({
      password: [, Validators.compose([
        Validators.required,
        Validators.pattern('^$|^[A-Za-z0-9]+'),
        Validators.minLength(4)
      ])],
      confirmPassword: [, Validators.compose([
        Validators.required,
        Validators.pattern('^$|^[A-Za-z0-9]+'),
        Validators.minLength(4)
      ])]
    }, {validator: this.helper.passwordConfirming});
  }


  resetPasswordRequest() {
    const email = this.userResetForm.get('username').value;
    this.analyticsService.logEvent(MyEvents.RESET_PASS_REQUESTED);
    this.authService.resetPasswordRequest(email).subscribe(data => {
      this.analyticsService.logEvent(MyEvents.RESET_PASS_REQUEST_SUCCESS);

      this.modal.getModal('forgotPasswordEmailSent').open();
    }, (error) => {
      this.analyticsService.logEvent(MyEvents.RESET_PASS_REQUEST_SUCCESS, {error});
    }, () => {

    });
  }

  resetPassword() {
    this.authService.resetPassword(this.keyToResetPass, this.resetPasswordForm.get('password').value).subscribe(data => {
      this.analyticsService.logEvent(MyEvents.RESET_PASS_SUCCESS);
      this.router.navigate([window.ROUTES.AUTH_FORGOT_PASSWORD_RESET_SUCCESS]).then();
    }, (error) => {
      this.analyticsService.logEvent(MyEvents.RESET_PASS_FAILED);
    }, () => {
    });
  }

  redirectToLogin() {
    setTimeout(() => {
      this.sharedService.goToLoginOrWalkThrough();
    }, 300);
  }
}
export enum ForgotPasswordPageViews {
  REQUEST_EMAIL = 'REQUEST_EMAIL',
  CHANGE_PASS = 'CHANGE_PASS'
}
