import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Product} from '../../../pickup/pickup.model';
import {Observable, Subject} from 'rxjs';
import {PRODUCT_ITEM_SELECTION_MODE} from '../../product.model';
import {HelperService} from '../../../shared/services/helper.service';
import * as _ from 'lodash';
import {CustomAnimations} from '../../../shared/animations';
import {IItem} from '../../../models/item.model';
import {FacebookEvents} from '../../../shared/my-events.const';
import {PickupService} from '../../../pickup/pickup.service';

@Component({
  selector: 'product-list-lazy',
  templateUrl: './product-list-lazy.component.html',
  styleUrls: ['./product-list-lazy.component.scss'],
  animations: [CustomAnimations.listAnimation()]
})
export class ProductListLazyComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() catalogDataObservable: Observable<any[]>;
  @Input() resetSelection?: Subject<boolean>;
  @Input() itemSelectionMode: PRODUCT_ITEM_SELECTION_MODE;
  @Input() autoSelectedItems: Product[];
  @Input() disableAutoSelected: boolean;
  @Input() allowLoadingSubCatForAll: boolean;
  @Input() allowLoadingSubCatForSpecificCats: string[];
  @Input() showItemPrice: boolean;

  @Output() onItemSelected ? = new EventEmitter<Product>();
  @Output() onGetRootCategories: EventEmitter<any> = new EventEmitter<any>();
  @Output() onGetCategoryData: EventEmitter<any> = new EventEmitter<any>();
  @Output() onGetCategoryDescendantItems: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLeafWithAttrItemsClicked: EventEmitter<any> = new EventEmitter<any>();

  /*fetchRootCategoriesSubscription: Subscription;
  fetchCategoryDataSubscription: Subscription;
  fetchCategoryDescendantItemsSubscription: Subscription;*/

  productItemSelectionMode = PRODUCT_ITEM_SELECTION_MODE;
  selectedItemId: string;
  catalogData = [];
  showHideMap = {};
  window = window;
  categorizedItems: any = {};

  itemSearchValue: string;
  showNoItemsMsg: boolean;
  searchingItems: boolean;

  constructor(private helper: HelperService,
              private pickupService: PickupService) {
  }

  ngOnInit() {
    this.catalogDataObservable.subscribe((data) => {
      this.catalogData = data;
    });
    this.onGetRootCategories.emit();
    this.resetSelection.subscribe((data) => { if (data) {this.selectedItemId = ''; } });
  }

  onCategoryClick(catData, pathToCat, topCatName) {
    if (this.allowLoadingSubCatForAll) {
      if (catData.leafWithSpecs) {
        this.onLeafWithAttrItemsClicked.emit(catData);
      } else {
        this.onGetCategoryData.emit([catData, pathToCat]);
      }
    } else {
      if (this.allowLoadingSubCatForSpecificCats && this.allowLoadingSubCatForSpecificCats.includes(topCatName)) {
        if (catData.leafWithSpecs) {
          this.onLeafWithAttrItemsClicked.emit(catData);
        } else {
          this.onGetCategoryData.emit([catData, pathToCat]);
        }
      } else {
        this.onGetCategoryDescendantItems.emit([catData, pathToCat]);
      }
    }

    return true;
  }

  ngOnChanges() {
    /*this.catalogData = this.helper.getGroupBy(this.items, 'categoryName');*/
    console.log(this.catalogData);
  }

  ngAfterViewInit(): void {
    /*this.showHideMap[0] = true;*/
  }

  itemSelected(event) {
    /*this.onItemSelected.emit(this.items.filter((item) => {
      return (event && (item.id === event.id));
    })[0]);*/
    this.onItemSelected.emit(event);
    this.selectedItemId = event ? event.id : null;
  }

  isAutoSelectable(productItem) {
    return (_.some(this.autoSelectedItems, (item) => {
      return item.id === productItem.id;
    }));
  }

  markAutoSelectedItemDisable(item) {
    return this.disableAutoSelected && this.isAutoSelectable(item);
  }

  showHideList(list, cat) {
    list.forEach((item, key) => {
      if (item.id !== cat.id) {
        list[key].showHide = false;
      }
    });
    cat.showHide = !cat.showHide;
  }

  appendPath(i, path) {
    i = path.toString() ? (i + '>' + path) : i;
    return i;
  }


  searchItems(searchText) {
    if (searchText) {
      this.searchingItems = true;
      this.pickupService.searchProductsForSelectedStore(searchText).subscribe((data) => {
        this.categorizedItems = data;
        this.searchingItems = false;
      }, () => {
        this.searchingItems = false;
        this.categorizedItems = {};
      });
    } else {
      this.searchingItems = false;
      this.categorizedItems = {};
    }
  }

  clearSearch() {
    this.itemSearchValue = '';
  }

  ngOnDestroy(): void {
    /*if (this.fetchRootCategoriesSubscription) {
      this.fetchRootCategoriesSubscription.unsubscribe();
    }
    if (this.fetchCategoryDataSubscription) {
      this.fetchCategoryDataSubscription.unsubscribe();
    }
    if (this.fetchCategoryDescendantItemsSubscription ) {
      this.fetchCategoryDescendantItemsSubscription.unsubscribe();
    }*/
  }

}
