export interface HttpRequestErrorResponse {
    errorCode: string;
    errorType: string;
    message: string;
}

export class HttpRequestErrorResponse {
    constructor(public errorCode: string,
                public errorType: string,
                public message: string) {
    }
}

export interface RemoteConfig {
    current_version_release_notes?: ReleaseNotesRemoteConfig;
    app_install_link?: CurrentAppVersionRemoteConfig;
    current_app_version?: CurrentAppVersionRemoteConfig;
}

export interface CurrentAppVersionRemoteConfig {
    ios?: string;
    android?: string;
}

export interface ReleaseNotesRemoteConfig {
    notes?: string[];
}

export interface PopoverMenu {
    displayText: string;
    dismissRole: string;
    dismissData?: any;
}

export interface CancellationReason {
    id: number;
    title: string;
}

export interface ContactInfo {
    phone: string;
    email: string;
}

export interface PickupOrder {
    minOrder: number;
}

export interface FileUpload {
    max_file_size: number;
    allowed_document_types: string[];
}

export interface PortalConfig {
    cancellationReasons: CancellationReason[];
    contactInfo: ContactInfo;
    defaultLogisticsCharges: number;
    pickupOrder: PickupOrder;
    file_upload: FileUpload;
    marketplace?: MarketplaceConfig;
}

export interface MarketplaceConfig {
    orderCancellationReasonsForBuyer?: CancellationReason[];
    orderCancellationReasonsForSeller?: CancellationReason[];
}

export enum OtpPageViews {
    VERIFY = 'VERIFY',
    UNVERIFIED = 'UNVERIFIED',
    REGISTERED = 'REGISTERED'
}

export interface DeviceInfo {
    appVersion?: string;
    createdAt?: Date;
    deviceToken?: string;
    id?: number;
    internal?: boolean;
    lastSessionAt?: Date;
    manufacturer?: string;
    model?: string;
    platform?: string;
    referrer?: string;
    referrerTimestamp?: Date;
    runCount?: number;
    serial?: string;
    sessionCount?: number;
    uninstalled?: boolean;
    unistalledDate?: Date;
    userId?: number;
    userLogin?: string;
    uuid?: string;
    version?: string;
}

export interface DocumentDetails {
    id?: number;
    type: string;
    fileName: string;
    url?: string;
    signedUrl?: any;
    modifiedAt: string;
    active: boolean;
    entityId?: any;
    entity: string;
    fileSize: number;
    contentType: string;
}

export interface DocumentUploadMeta {
    docType: string;
    entity: string;
    entityId?: number;
    fileName: string;
    fileSize: number;
    type: string;
    contentType: string;
}

export enum TradeType {
    B = 'B', // buy
    S = 'S' // sell negative
}
