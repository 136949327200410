import * as tslib_1 from "tslib";
import { HttpParams } from '@angular/common/http';
var CustomHttpParams = /** @class */ (function (_super) {
    tslib_1.__extends(CustomHttpParams, _super);
    function CustomHttpParams(interceptorConfig, params) {
        var _this = _super.call(this, { fromObject: params }) || this;
        _this.interceptorConfig = interceptorConfig;
        return _this;
    }
    return CustomHttpParams;
}(HttpParams));
export { CustomHttpParams };
