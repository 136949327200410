<span class="close-btn" *ngIf="!window.IS_MOBILE" (click)="gotoTransactions()" ><ion-icon name="close-circle" ></ion-icon></span>
<div class="inner-content" page-bg-colored padding="">
    <ion-grid no-padding class="pickup-flow">
        <ion-row  no-padding class="pickup-flow-row">
            <ion-col size="auto" *ngIf="!window.IS_MOBILE" no-padding class="pickup-flow-sidebar">
                <app-pickup-timeline ></app-pickup-timeline>
            </ion-col>
            <ion-col  no-padding >
                <div class="content-header-section" *ngIf="!window.IS_MOBILE" >
                    <div>
                        <div class="main-title">Enter Quantity</div>
                        <div class="sub-title">Enter the quantity for the selected item</div>
                    </div>
                </div>
                <div class="desktop-qty-main-section" >
                    <ion-grid no-padding>
                        <ion-row>
                            <ion-col  class="product-data-top" size="12"  size-lg="6" size-md="6" size-xl="6" size-sm="6" offset-lg="3" offset-md="3" offset-xl="3" offset-sm="3" text-center no-padding>
                                <ion-text class="product-name" modal-heading>
                                    {{product?.name}}
                                </ion-text>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <ion-grid no-padding>
                        <ion-row>
                            <ion-col no-padding class="product-data-bottom" size="12"  size-lg="6" size-md="6" size-xl="6" size-sm="6" offset-lg="3" offset-md="3" offset-xl="3" offset-sm="3">
                                <ion-grid no-padding="">
                                    <ion-row *ngIf="activeView == ProductAddToCartComponentViews.SET_QTY" style="line-height: 23px;">
                                        <ion-col  size="12" text-center  *ngIf="product?.tradeType !== tradeTypes.S">
                                            <ion-text color="medium"  class="rate" text-medium>
                                                Earn <span color-primary font-bold text-large>₹{{product?.price}}/{{product?.unitType | lowercase}}</span>
                                            </ion-text>
                                        </ion-col>
                                        <ion-col size="12" text-center  *ngIf="product?.tradeType === tradeTypes.S">
                                            <ion-text color="medium" class="rate" text-medium>
                                                Pay service fee of <span color-danger font-bold text-large>₹{{product?.price}}/{{product?.unitType | lowercase}}</span>
                                            </ion-text>
                                        </ion-col>
                                        <ion-col no-padding-top size="12" text-center *ngIf="product?.description">
                                            <ion-button size="small" text-small="" letter-space-2="" font-regular=""
                                                        (click)="openLearnMoreModal()">
                                                LEARN MORE
                                            </ion-button>
                                        </ion-col>
                                        <ion-col size="12" no-padding-bottom no-padding-left no-padding-right>
                                            <product-qty-edit [item]="product" [setSelectedQty]="setSelectedQty"
                                                              (onItemQtyUpdate)="updateQty($event)"></product-qty-edit>
                                        </ion-col>
                                        <ion-col size="12" margin-top *ngIf="window.IS_MOBILE">
                                            <ion-button *ngIf="activeView == ProductAddToCartComponentViews.SET_QTY"
                                                        [disabled]="selectedQty <= 0 || isFloat(selectedQty)"
                                                        (click)="closeModal()"
                                                        size="large"
                                                        strong="">{{addToCartBtnText ? addToCartBtnText : 'Add Product'}}</ion-button>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>

            </ion-col>
        </ion-row>
    </ion-grid>
</div>
<ion-footer padding no-border  class="desktop-footer-section with-padding" *ngIf="!window.IS_MOBILE">
    <ion-grid no-padding>
        <ion-row>
            <ion-col size="6" no-padding class="back-step">
                <ion-button  (click)="closeModal()"  no-margin size="large"  strong >
                    <span class="back-icon"><ion-icon name="arrow-round-back"></ion-icon></span> Back
                </ion-button>
            </ion-col>
            <ion-col size="6" no-padding class="next-step" >
                <ion-button *ngIf="activeView == ProductAddToCartComponentViews.SET_QTY"
                            [disabled]="selectedQty <= 0 || isFloat(selectedQty)"
                            (click)="closeModal()"  no-margin size="large" strong color="primary" >
                   Proceed to {{addToCartBtnText ? addToCartBtnText : 'Add Product'}} <span class="next-icon"><ion-icon name="arrow-round-forward"></ion-icon></span>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>
