import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CordovaPluginsModule} from './cordova-plugins/cordova-plugins.module';
import {Ng2OdometerModule} from 'ng2-odometer';
import {BugsnagModule} from './bugsnag/bugsnag.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        CordovaPluginsModule,
        Ng2OdometerModule.forRoot(),
        BugsnagModule
    ],
    exports: [Ng2OdometerModule, BugsnagModule]
})
export class ThirdPartyModule { }
