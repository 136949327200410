/// <reference types="@types/googlemaps" />
import {Component, Input, OnInit} from '@angular/core';
import {Events, ModalController} from '@ionic/angular';
import {AnalyticsService} from '../../services/analytics.service';
import {MyEvents} from '../../my-events.const';

declare let google: any;
@Component({
    selector: 'app-location-search',
    templateUrl: './location-search.component.html',
    styleUrls: ['./location-search.component.scss'],
})
export class LocationSearchComponent implements OnInit {
    @Input()  errorHandler: (err: string, color: string) => void;
    autocompleteService;
    query;
    places;
    searching = false;
    constructor(
        private modalCtrl: ModalController,
        private events: Events,
        private analyticsService: AnalyticsService
    ) { }

    ngOnInit() {
        this.analyticsService.screenViewChanged(MyEvents.SCREENS.SEARCH_GOOGLE_LOCATION_MODAL);
    }

    searchPlace() {
        try {
            this.autocompleteService = new google.maps.places.AutocompleteService();
        } catch (e) {
            this.errorHandler('Your Internet connection appears to be slow. Please try after sometime.', 'dark');
            return;
        }
        if (this.query && this.query.length > 0 && this.analyticsService) {
            this.searching = true;
            const config = {
                input: this.query,
                componentRestrictions: { country: 'in' },
            };

            this.autocompleteService.getPlacePredictions(config, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                    this.places = [];
                    predictions.forEach((prediction) => {
                        this.places.push(prediction);
                    });
                }
                this.searching = false;
            });
        } else {
            this.places = [];
        }

    }

    onResultClick(place) {
        this.analyticsService.logEvent(MyEvents.ADDRESS_SELECTED_FROM_SEARCH, {screen: MyEvents.SCREENS.SEARCH_GOOGLE_LOCATION_MODAL});
        this.modalCtrl.dismiss(place).then();
    }

    currentLocationClicked() {
        this.analyticsService.logEvent(MyEvents.BTN_GET_CURRENT_LOCATION, {screen: MyEvents.SCREENS.SEARCH_GOOGLE_LOCATION_MODAL});
        this.events.publish('ON_CURRENT_LOCATION_CLICKED', {});
        this.modalCtrl.dismiss(null, 'MY_LOCATION').then();
    }

}
