import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {City} from '../../../pickup/pickup.model';
import {LoginSendOtpReqResp} from '../../../auth/auth.model';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'city-search-modal',
    templateUrl: './city-search-modal.component.html',
    styleUrls: ['./city-search-modal.component.scss'],
})
export class CitySearchModalComponent implements OnInit, OnDestroy {
    @Input() citySearchCallback: (reqBody: string) => Observable<City[]>;
    citySearchSubscription: Subscription = new Subscription();
    cityList: City[] = [];

    constructor(private modalController: ModalController) {
    }

    selectedItemId: string;
    noCities: boolean;

    ngOnInit() {
    }

    closeModal() {
        this.modalController.dismiss().then();
    }

    searchItems(textToSearch) {
        if (textToSearch) {
            this.citySearchSubscription = this.citySearchCallback(textToSearch).subscribe((data) => {
                this.cityList = data;
                this.noCities = false;
            }, () => {
                this.noCities = true;
                this.cityList = [];
            });
        } else {
            this.noCities = true;
            this.cityList = [];
        }
    }

    itemSelected(city: City) {
        this.modalController.dismiss(city, 'CITY_SELECTED').then();
    }

    isEmpty(obj) {
        return !Object.keys(obj).length;
    }

    ngOnDestroy(): void {
        this.citySearchSubscription.unsubscribe();
    }

}
