import {HttpParameterCodec, HttpParams} from '@angular/common/http';


declare interface HttpParamsOptions {
    /**
     * String representation of the HTTP parameters in URL-query-string format.
     * Mutually exclusive with `fromObject`.
     */
    fromString?: string;
    /** Object map of the HTTP parameters. Mutually exclusive with `fromString`. */
    fromObject?: {
        [param: string]: string | string[];
    };
    /** Encoding codec used to parse and serialize the parameters. */
    encoder?: HttpParameterCodec;
}

export class CustomHttpParams extends HttpParams {
    constructor(
        public interceptorConfig: { ignoreErrorHandling: boolean },
        params?: { [param: string]: string | string[] }
    ) {
        super({ fromObject: params } as HttpParamsOptions);
    }
}
