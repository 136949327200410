<ion-content page-bg-colored padding="">
    <ion-grid no-padding>
        <ion-row>
            <ion-col size="12" text-center no-padding>
                <ion-text class="product-name" modal-heading>
                    {{item?.name}}
                    <span color-medium text-small float-end (click)="closeModal()" font-bold>HIDE</span>
                </ion-text>
            </ion-col>
            <ion-col size="12" class="learn-more" >
                <div class="ql-editor" [innerHTML]="item?.description | safeHtml"></div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
