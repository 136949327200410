import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActionSheetController, Events } from '@ionic/angular';
import { API } from './api.url';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import * as _ from 'lodash';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { HelperService } from './helper.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "@ionic-native/camera/ngx/index";
import * as i4 from "@ionic-native/file/ngx/index";
import * as i5 from "@ionic-native/image-picker/ngx/index";
import * as i6 from "./helper.service";
import * as i7 from "@ionic-native/in-app-browser/ngx/index";
// media, document and files related all stuff should be here.
var FileManagerService = /** @class */ (function () {
    function FileManagerService(http, events, actionSheetController, camera, file, imagePicker, helperService, inAppBrowser) {
        this.http = http;
        this.events = events;
        this.actionSheetController = actionSheetController;
        this.camera = camera;
        this.file = file;
        this.imagePicker = imagePicker;
        this.helperService = helperService;
        this.inAppBrowser = inAppBrowser;
        this.imagePickerOptions = {
            maximumImagesCount: 4,
        };
    }
    FileManagerService.prototype.getSignedUrl = function (request) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post("" + API.SIGNED_URL(), request)
                .toPromise().then(function (res) {
                resolve(res);
            }, function (error) {
                reject(error);
            });
        });
    };
    FileManagerService.prototype.uploadAndAttachFile = function (documentMeta, file) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getSignedUrl(documentMeta).then(function (result) {
                _this.uploadFileToS3(file, result).then(function (response) {
                    result.entityId = documentMeta.entityId;
                    _this.attachUploadedFileToEntity(result, 'CORE').then(function (data) {
                        resolve(data);
                    }, function (err) {
                        reject(err);
                    });
                }, function (err) {
                    reject(err);
                });
            });
        });
    };
    FileManagerService.prototype.uploadFileToS3 = function (file, result) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.helperService.createLoader('Uploading..').then(function (loaderObj) {
                loaderObj.present().then();
                var headersValues = new HttpHeaders();
                headersValues.append('Content-Type', file.type);
                _this.http.put(result.signedUrl, file, { headers: headersValues })
                    .toPromise().then(function (res) {
                    loaderObj.dismiss().then();
                    resolve(res);
                }, function (error) {
                    loaderObj.dismiss().then();
                    reject(error);
                });
            });
        });
    };
    FileManagerService.prototype.readAndUploadFile = function (fileUri, documentMeta) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getFileMeta(fileUri).then(function (fileMeta) {
                documentMeta.contentType = 'image/jpeg';
                documentMeta.fileName = fileMeta.name;
                documentMeta.fileSize = fileMeta.size;
                return _this.readFile(fileMeta.dirName, fileMeta.name).then(function (data) {
                    return _this.uploadAndAttachFile(documentMeta, new Blob([data], {
                        type: documentMeta.contentType
                    })).then(function (uploadedImgDetails) {
                        resolve(uploadedImgDetails);
                    });
                }, function (err) {
                    reject(err);
                });
            }, function (err) {
                reject(err);
            });
        });
    };
    FileManagerService.prototype.captureImgAndReturnFile = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var options = {
                quality: 60,
                destinationType: _this.camera.DestinationType.FILE_URI,
                encodingType: _this.camera.EncodingType.JPEG,
                mediaType: _this.camera.MediaType.PICTURE,
                sourceType: _this.camera.PictureSourceType.CAMERA
            };
            return _this.camera.getPicture(options).then(function (imageData) {
                resolve(imageData);
            }, function (err) {
                reject(err);
            });
        });
    };
    FileManagerService.prototype.captureImgAndUpload = function (documentMeta) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            return _this.captureImgAndReturnFile().then(function (imgUri) {
                _this.readAndUploadFile(imgUri, documentMeta).then(function (uploadedImgsInfo) {
                    resolve([uploadedImgsInfo]);
                }, function (err) {
                    reject(err);
                });
            }, function (err) {
                reject(err);
            });
        });
    };
    FileManagerService.prototype.selectImgFromGalleryAndUpload = function (documentMeta, noOfImgsAllowedForSelect) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (noOfImgsAllowedForSelect != null) {
                _this.imagePickerOptions.maximumImagesCount = noOfImgsAllowedForSelect;
            }
            _this.imagePicker.getPictures(_this.imagePickerOptions).then(function (imgUris) {
                var fileUploads = [];
                console.log(imgUris);
                imgUris.forEach(function (imgUri, seq) {
                    fileUploads[seq] = _this.readAndUploadFile(imgUri, documentMeta);
                });
                Promise.all(fileUploads).then(function (data) {
                    resolve(data);
                }, function (err) {
                    reject(err);
                });
            }, function (err) {
                reject(err);
            });
        });
    };
    FileManagerService.prototype.showUploadOptionsForImg = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.actionSheetController.create({
                header: 'Upload Options',
                buttons: [
                    {
                        text: 'Open Camera',
                        role: 'camera',
                        icon: 'camera'
                    },
                    {
                        text: 'Select Image',
                        icon: 'images',
                        role: 'files'
                    }
                ]
            }).then(function (data) {
                var actionSheet = data;
                actionSheet.present().then(function () {
                    resolve(actionSheet);
                }, function (err) {
                    reject(err);
                });
            }, function (err) {
                reject(err);
            });
        });
    };
    FileManagerService.prototype.selectAndUploadImgS3 = function (documentMeta, noOfImgsAllowedForSelect) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.showUploadOptionsForImg().then(function (actionObj) {
                actionObj.onDidDismiss().then(function (selectedAction) {
                    if (selectedAction && selectedAction.role) {
                        if (selectedAction.role === 'camera') {
                            _this.captureImgAndUpload(documentMeta).then(function (data) {
                                resolve(data);
                            }, function (err) {
                                reject(err);
                            });
                        }
                        else if (selectedAction.role === 'files') {
                            return _this.selectImgFromGalleryAndUpload(documentMeta, noOfImgsAllowedForSelect).then(function (data) {
                                resolve(data);
                            }, function (err) {
                                reject(err);
                            });
                        }
                    }
                }, function (err) {
                    reject(err);
                });
            }, function (err) {
                reject(err);
            });
        });
    };
    FileManagerService.prototype.getFileMeta = function (fileUri) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.file.resolveLocalFilesystemUrl(fileUri).then(function (fileEntry) {
                fileEntry.getMetadata(function (metadata) {
                    fileEntry.getParent(function (dirEntry) {
                        resolve(_.merge({ name: fileEntry.name, dirName: dirEntry.nativeURL }, metadata));
                    }, function (err) {
                        return reject(err);
                    });
                }, function (err) {
                    return reject(err);
                });
            }, function (err) {
                return reject(err);
            });
        });
    };
    FileManagerService.prototype.readFile = function (fileUri, fileName) {
        return this.file.readAsArrayBuffer(fileUri, fileName);
    };
    FileManagerService.prototype.deleteDocument = function (docId) {
        return this.http.put(API.DELETE_DOC() + "?id=" + docId, '');
    };
    FileManagerService.prototype.attachUploadedFileToEntity = function (request, uploadAgainst) {
        var _this = this;
        // adding case because in platform different urls for attach.
        if (request && request.signedUrl && !request.url) {
            var splittedString = request.signedUrl.split('?X-Amz-');
            if (splittedString && splittedString[0]) {
                request.url = splittedString[0];
            }
        }
        return new Promise(function (resolve, reject) {
            switch (uploadAgainst) {
                case 'CORE':
                    _this.http.post("" + API.CORE_DOCUMENTS_ATTACH(), request)
                        .toPromise().then(function (res) {
                        resolve(res);
                    }, function (error) {
                        reject(error);
                    });
                    break;
                case 'STACKHOLDER':
                    reject('Not supported');
                    break;
                case 'CATALOG':
                    reject('Not supported');
                    break;
                default:
                    reject('Not supported');
            }
        });
    };
    FileManagerService.prototype.downloadFile = function (orderDocument) {
        if (orderDocument && orderDocument.url) {
            if (window.cordova) {
                var openbrowser = this.inAppBrowser.create(orderDocument.url, '_system');
            }
            else {
                var openInNew = document.createElement('a');
                openInNew.target = '_blank';
                openInNew.href = orderDocument.url;
                openInNew.click();
            }
        }
    };
    FileManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileManagerService_Factory() { return new FileManagerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i2.ActionSheetController), i0.ɵɵinject(i3.Camera), i0.ɵɵinject(i4.File), i0.ɵɵinject(i5.ImagePicker), i0.ɵɵinject(i6.HelperService), i0.ɵɵinject(i7.InAppBrowser)); }, token: FileManagerService, providedIn: "root" });
    return FileManagerService;
}());
export { FileManagerService };
