import * as _ from 'lodash';
import { Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { isFunction } from 'rxjs/internal-compatibility';
import { AuthService } from '../../auth/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../../auth/services/auth.service";
import * as i2 from "@ionic/angular";
var ChatService = /** @class */ (function () {
    function ChatService(authService, platform) {
        var _this = this;
        this.authService = authService;
        this.platform = platform;
        /** initialize plugin when device ready */
        this.platform.ready().then(function () {
            console.log('Platform ready: Init Freshchat');
            _this.init();
        });
        /** clear data on logout */
        this.authService.authenticationState.subscribe(function (isLoggedIn) {
            if (!isLoggedIn) {
                _this.clearData();
            }
        });
    }
    ChatService.prototype.init = function () {
        var _this = this;
        if (window.IS_MOBILE && !window.IS_MOBILE_WEB) {
            if (!window.Freshchat) {
                return;
            }
            window.Freshchat.init({
                appId: environment.chat.app_id,
                appKey: environment.chat.app_token
            }, function (success) {
                console.log('FRESHCHAT INIT', success);
                /** send data to FreshChat when changed */
                _this.authService.accountInfo.subscribe(function (account) {
                    _this.account = _.cloneDeep(account);
                    if (_this.account) {
                        console.log('Log freshchat account');
                        _this.updateUser();
                    }
                });
            });
        }
        else { // web sdk
            this.authService.accountInfo.subscribe(function (account) {
                _this.account = _.cloneDeep(account);
                if (_this.account) {
                    _this.loadWebSdk(false);
                    console.log('Log desktop freshchat account');
                }
            });
        }
    };
    ChatService.prototype.loadWebSdk = function (toBeOpen) {
        if (window.fcWidget) {
            window.fcWidget.init({
                token: 'b2034893-6df5-445a-9bb2-416603c06386',
                host: 'https://wchat.freshchat.com',
                siteId: 'Recykal B2B Portal',
                tags: ['recykalportal'],
                open: toBeOpen || false,
                config: {
                    headerProperty: {
                        hideChatButton: true
                    }
                }
            });
        }
        if (window.fcWidget) {
            window.fcSettings = {
                token: 'b2034893-6df5-445a-9bb2-416603c06386',
                host: 'https://wchat.freshchat.com',
                externalId: this.account.profile.id,
                firstName: this.account.firstName,
                lastName: this.account.lastName,
                email: this.account.email,
                phone: this.account.profile ? (this.account.profile.mobile || '') : '',
                properties: {
                    Company: this.account.lastName,
                },
                phoneCountryCode: '+91'
            };
        }
        if (window.fcWidget) {
            window.fcWidget.setExternalId(this.account.profile.id);
            window.fcWidget.user.setFirstName(this.account.firstName);
            window.fcWidget.user.setEmail(this.account.email);
            window.fcWidget.user.setProperties({
                Company: this.account.lastName,
            });
        }
    };
    ChatService.prototype.openChatWindow = function () {
        if (window.IS_MOBILE && !window.IS_MOBILE_WEB) {
            if (!window.Freshchat) {
                return;
            }
            window.Freshchat.showConversations({
                tags: ['recykal_marketplace_app'],
                filteredViewTitle: 'Support'
            });
        }
        else {
            if (!window.fcWidget) {
                return;
            }
            else {
                if (window.fcWidget.isLoaded() === true) {
                    window.fcWidget.show();
                    window.fcWidget.open();
                }
                else {
                    this.loadWebSdk(true);
                }
            }
        }
    };
    ChatService.prototype.updateUser = function () {
        if (window.IS_MOBILE) {
            if (!window.Freshchat) {
                return;
            }
            window.Freshchat.updateUser({
                firstName: this.account.firstName,
                lastName: this.account.lastName,
                email: this.account.email,
                countryCode: '+91',
                phoneNumber: this.account.profile ? (this.account.profile.mobile || '') : ''
            });
            window.Freshchat.identifyUser({
                externalId: this.account.profile.id.toString()
            });
        }
        this.updateUserProperty();
    };
    ChatService.prototype.clearData = function () {
        if (!window.Freshchat) {
            return;
        }
        return window.Freshchat.clearUserData();
    };
    ChatService.prototype.updatePushDeviceToken = function (token) {
        if (!window.Freshchat) {
            return;
        }
        window.Freshchat.updatePushNotificationToken(token);
    };
    ChatService.prototype.updateUserProperty = function () {
        if (window.IS_MOBILE) {
            if (!window.Freshchat) {
                return;
            }
            window.Freshchat.updateUserProperties({
                customerId: this.account.profile ? this.account.profile.formattedId : '',
                RegDone: this.account.registrationDone,
                businessType: this.account.profile.businessType ? this.account.profile.businessType.title : ''
            });
        }
    };
    ChatService.prototype.sendMessage = function (msg, openChat, callback) {
        var _this = this;
        if (!window.Freshchat) {
            return;
        }
        window.Freshchat.sendMessage({ tag: 'recykal_marketplace_app', message: msg }, (function (success) {
            if (openChat) {
                _this.openChatWindow();
            }
            if (isFunction(callback)) {
                callback(success);
            }
        }));
    };
    ChatService.prototype.isFreshChatNotification = function (payload) {
        if (!window.Freshchat) {
            return;
        }
        return window.Freshchat.isFreshchatPushNotification(payload, (function (success, isFreshchatNotif) {
            if (success && isFreshchatNotif) {
                window.Freshchat.handlePushNotification(payload.additionalData);
                return isFreshchatNotif;
            }
            return false;
        }));
    };
    ChatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatService_Factory() { return new ChatService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Platform)); }, token: ChatService, providedIn: "root" });
    return ChatService;
}());
export { ChatService };
