<ion-app>
  <!--<ngx-loading-bar color="#328E69" [includeSpinner]="false" [fixed]="true"></ngx-loading-bar>-->
  <!--<div id="app-component-loader" *ngIf="loadingRouteConfig" class="lds-facebook"><div></div><div></div><div></div></div>-->
  <div *ngIf="isOffline" class="offline">
    <div class="center-div">
      <ion-grid>
        <ion-row text-center="">
          <ion-col size="12">
            <ion-img img-center src="/assets/imgs/no-internet.png"></ion-img>
          </ion-col>
          <ion-col size="12">
            <ion-text text-regular font-bold>You are not connected to Internet.</ion-text>
          </ion-col>
          <ion-col size="12">
            <ion-text text-regular="">Please check your Internet connection.</ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
  <ion-router-outlet></ion-router-outlet>
</ion-app>
