import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HelperService} from '../services/helper.service';
import {CustomHttpParams} from '../overrides/custom-http-params.class';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {

    constructor(private helper: HelperService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
                catchError((errorResponse: Response | any) => {
                    console.log('HTTP ERROR: ', errorResponse);
                    this.handleHttpError(request, errorResponse).then();
                    return throwError(errorResponse);
                })
        );
    }

    async handleHttpError(request, errorDetails: HttpErrorResponse) {
        if (errorDetails
            && errorDetails.error
            && errorDetails.error.hasOwnProperty('errorCode')
            && errorDetails.error.hasOwnProperty('errorType')
            && errorDetails.error.hasOwnProperty('message')) {
            if (errorDetails.status >= 500) {
                await this.helper.showToast( errorDetails.error.errorCode + ': ' + errorDetails.error.message, 'danger');
            } else {
                if (request.params instanceof CustomHttpParams) {
                    if (request.params.interceptorConfig.ignoreErrorHandling) {
                        return;
                    }
                }
                this.helper.showToast( errorDetails.error.message, 'warning').then();
            }
        } else {
            if (errorDetails && errorDetails.status === 0) {
                this.helper.showToast('Request timeout. Please check if you are connected to Internet.', 'danger').then();
            }
        }
    }
}
