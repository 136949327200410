import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../../pickup/pickup.model';
import {ProductLearnMoreComponent} from '../product-learn-more/product-learn-more.component';
import {TradeType} from '../../../shared/models/common.model';
import {Router} from '@angular/router';

@Component({
    selector: 'app-product-selected',
    templateUrl: './product-add-to-cart.component.html',
    styleUrls: ['./product-add-to-cart.component.scss'],
})
export class ProductAddToCartComponent implements OnInit {
    @Input() showModalClose: boolean;
    @Input() product: Product;
    @Input() setSelectedQty?: number;
    @Input() addToCartBtnText?: string;
    selectedQty: number;
    activeView: ProductAddToCartComponentViews;
    ProductAddToCartComponentViews = ProductAddToCartComponentViews;
    tradeTypes = TradeType;
    window = window;
    constructor(private modalController: ModalController,
                private router: Router,
    ) {
    }

    ngOnInit() {
        this.selectedQty = this.setSelectedQty ? this.setSelectedQty : 0;
        this.activeView = ProductAddToCartComponentViews.SET_QTY;
    }

    async closeModal() {
        await this.modalController.dismiss({selectedQty: this.selectedQty, productId: this.product.id});
    }

    updateQty(qty: number) {
        this.selectedQty = qty;
    }

    async openLearnMoreModal() {
        const learnMoreModal = await this.modalController.create({
            component: ProductLearnMoreComponent,
            cssClass: ['bottom-sheet'],
            showBackdrop: true,
            backdropDismiss: true,
            componentProps: {
                item: this.product
            }
        });
        learnMoreModal.present().then();
    }

    isFloat(n) {
        return Number(n) === n && n % 1 !== 0;
    }

    gotoTransactions() {
        this.router.navigate([window.ROUTES.DASHBOARD_CONTAINER_TRANSACTIONS]).then( res => {
            this.modalController.dismiss(null, undefined);
        });
    }
}
enum ProductAddToCartComponentViews {
    SET_QTY,
    LEARN_MORE
}
