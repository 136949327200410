import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MyEvents} from "../../shared/my-events.const";
import {environment} from "../../../environments/environment";
import {AnalyticsService} from "../../shared/services/analytics.service";
import {SharedService} from "../../shared/shared.service";
import {DomSanitizer} from "@angular/platform-browser";
import {ModalController} from "@ionic/angular";
import {AuthService} from "../../auth/services/auth.service";
import {HelperService} from "../../shared/services/helper.service";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {

  @Input() url: string;
  @Input() title: string;
  @Input() acceptPolicy: boolean;
  @Input() policyDetails: any;
  @ViewChild('frame', {static: true}) frame: ElementRef;
  src: any;
  checkboxChecked: boolean;
  constructor(private sanitizer: DomSanitizer,
              private modalController: ModalController,
              private helper: HelperService,
              private authService: AuthService) { }

  ngOnInit() {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.frame.nativeElement.addEventListener('load', this.setStyle.bind(this));
  }

  setStyle(event) {
    try {
      const style = document.createElement('style');
      style.textContent =
          '.fixed-nav {' +
          '  display: none !important;' +
          '}'
      ;
      event.target.contentWindow.head.appendChild(style);
    } catch (e) {
      console.log('Failed to set style for iframe content');
    }
  }

  acceptPrivacyPolicy() {
    const dta ={
      'policyId' : this.policyDetails.id
    }
    this.authService.acceptPrivacyPolicy(dta).subscribe(res => {
      this.helper.showToast('Privacy Policy has been accepted successfully', 'primary');
      this.closeModal();
    });
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
