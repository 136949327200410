export enum PRODUCT_ITEM_SELECTION_MODE {
    SINGLE_SELECT,
    MULTI_SELECT,
    NO_SELECTION
}


export interface CatAttrVal {
    id: string;
    val: string;
    unitId: number;
    icon: string;
    seqNo: number;
    description: string;
    active?: any;
}

export interface CatAttr {
    id: string;
    label: string;
    fieldType: string;
    placeholder?: any;
    info?: any;
    optional: boolean;
    srNo: number;
    active: boolean;
    categoryId: string;
    categoryName: string;
    regexPattern?: any;
    groupName?: any;
    unitId?: any;
    attributeValues: CatAttrVal[];
    icon?: any;
    fieldName?: any;
}
