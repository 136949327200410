import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {KeysPipe} from './pipes/keys.pipe';
import {IframeComponent} from './components/iframe-component/iframe.component';
import {LocationSelectionModalComponent} from './components/location-selection-modal/location-selection-modal.component';
import {RcylGoogleMapComponent} from './components/rcyl-google-map/rcyl-google-map.component';
import {InputErrorsComponent} from './components/input-errors/input-errors.component';
import {FilterPipe} from './pipes/filter.pipe';

import {LocationSearchComponent} from './components/location-search/location-search.component';
import {GroupbyPipe} from './pipes/groupby.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {ParseAddressPipe} from './pipes/parse-address.pipe';
import {PopoverMenusComponent} from './components/popover-menus/popover-menus.component';
import {LocationPermissionComponent} from './components/location-permission/location-permission.component';
import {OrderByPipe} from './pipes/order-by.pipe';
import {TrueFalseValueDirective} from './directives/true-false-value.directive';
import {HighlightSearchPipe} from './pipes/highlight-search.pipe';
import {HtmlModalComponent} from './components/html-modal/html-modal.component';
import {PositivePipe} from './pipes/positive.pipe';
import {TextMaskPipeModule} from './pipes/text-mask/text-mask.pipe.module';
import {CitySearchModalComponentModule} from './components/city-search-modal/city-search-modal.component.module';
import {ImgListComponentModule} from './components/img-list/img-list.component.module';
import {ImgPreloaderDirectiveModule} from './directives/img-preloader/img-preloader-directive.module';
import {OnboardingPendingErrorModule} from '../desktop/auth/pages/auth-container/components/onboarding-pending-error/onboarding-pending-error.module';
import {OnboardingPendingErrorComponent} from "../desktop/auth/pages/auth-container/components/onboarding-pending-error/onboarding-pending-error.component";
import {PrivacyPolicyComponent} from "../settings/privacy-policy/privacy-policy.component";


@NgModule({
    declarations: [FilterPipe, KeysPipe, GroupbyPipe, SafeHtmlPipe,
        IframeComponent, RcylGoogleMapComponent, InputErrorsComponent,
        LocationSearchComponent, LocationSelectionModalComponent, ParseAddressPipe,
        PopoverMenusComponent, LocationPermissionComponent, OrderByPipe, TrueFalseValueDirective,
        HighlightSearchPipe,
        HtmlModalComponent,
        PositivePipe,
        PrivacyPolicyComponent
    ],
    entryComponents: [
        IframeComponent,
        LocationSearchComponent,
        PopoverMenusComponent,
        LocationPermissionComponent,
        HtmlModalComponent,
        OnboardingPendingErrorComponent,
        PrivacyPolicyComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskPipeModule,
        CitySearchModalComponentModule,
        ImgListComponentModule,
        ImgPreloaderDirectiveModule,
        OnboardingPendingErrorModule
    ],
    exports: [KeysPipe, GroupbyPipe, SafeHtmlPipe, OrderByPipe,
        InputErrorsComponent, LocationSelectionModalComponent,
        RcylGoogleMapComponent,
        LocationSearchComponent, FilterPipe, ParseAddressPipe, PopoverMenusComponent,
        LocationPermissionComponent, TrueFalseValueDirective,
        HighlightSearchPipe, HtmlModalComponent,
        PositivePipe,
        CitySearchModalComponentModule,
        ImgListComponentModule,
        ImgPreloaderDirectiveModule,
        PrivacyPolicyComponent,
        OnboardingPendingErrorModule]
})
export class SharedModule {
}
