import {Address, IAddress} from '../models/address.model';

export interface CartData {
    storeData: Store;
    cartItems: Product[];
    cartSummery: CartSummery;
    selectedTimeslot?: Timeslot;
}

export class CartData {
    constructor(public storeData: Store, public cartItems: Product[], public cartSummery: CartSummery, public selectedTimeslot?: Timeslot) {
    }
}

export interface CartSummery {
    totalPrice: number;
    totalPriceToPay: number;
    totalQty: number;
    totalWeight: number;
    containsItemWithNegativePricing: boolean;
}

export class CartSummery {
    constructor(public totalPrice: number, public totalPriceToPay: number,
                public totalQty: number, public totalWeight: number,
                public containsItemWithNegativePricing: boolean) {
    }
}

export interface Timeslot {
    id: number;
    startTime: Date;
    endTime: Date;
    maxPickups: number;
    currentPickups: number;
    unverifiedPickups: number;
    hidden: boolean;
    regionName: string;
    isToday: boolean;
    isTomorrow: boolean;
    drop?: any;
    available: boolean;
}

export class Timeslot {
    constructor(public id: number, public startTime: Date, public endTime: Date,
                public maxPickups: number, currentPickups: number, public unverifiedPickups: number,
                public hidden: boolean, public regionName: string, public isToday: boolean,
                public isTomorrow: boolean, public available: boolean, public drop?: any) {
    }
}

export interface PlaceOrderRequest {
    orderItems: OrderItems[];
    addressInfo: IAddress;
    timeSlot: Timeslot;
    storeId: number;
}

export class PlaceOrderRequest {
    constructor(public orderItems: OrderItems[],
                public  addressInfo: IAddress,
                public timeSlot: Timeslot,
                public storeId: number) {
    }
}

export interface OrderItems {
    itemId: string;
    qty: number;
    total: number;
}

export interface ContactDetails {
    id: number;
    street: string;
    city: City;
    zipcode: string;
    landmark: string;
    flatBuilding?: string;
}

export interface City {
    id: number;
    name: string;
    state: string;
    active?: null;
}

export interface Product {
    id: string;
    name: string;
    icon: string;
    price: number;
    categoryId: string;
    categoryName: string;
    active: boolean;
    seq: number;
    info?: any;
    weight: number;
    hsn: string;
    cgstPercentage: number;
    sgstPercentage: number;
    enabledByCustomer: boolean;
    enabledByPartner?: any;
    unitType: string;
    energySaved?: any;
    oilLts?: any;
    landfillSpace?: any;
    airPollutants?: any;
    waterLts?: any;
    noOfTrees?: any;
    description: string;
    exchangeCatalog: boolean;
    nonExchangeCatalog: boolean;
    colorCode?: any;
    itemSpecificationPayloads: ItemSpecificationPayload[];
    locationPrices?: any;
    categoryNodes: CategoryNode[];
    quantity?: number;
    total?: number;
    qualityReq?: any;
    tradeType?: string;
    checked?: boolean;
}

export interface ItemSpecificationPayload {
    attributeId: string;
    value: string;
    valueId: string;
    attributeLabel?: any;
    attributeFieldName?: any;
}

export interface CategoryNode {
    id: string;
    name: string;
    image?: string;
}

export interface CustomerPaymentAccount {
    id?: number;
    accountHolderName: string;
    accountNumber: string;
    branch: string;
    ifsc: string;
    chequeInFavour?: any;
    chequePayableAt?: any;
    upi?: any;
    bankName: string;
}

export interface PartnerInfo {
    id: number;
    formattedId: string;
    name: string;
    email: string;
    address?: Address;
    contactNumber?: string;
}

export interface Store {
    id?: number;
    name: string;
    paymentMode?: string;
    contactNumber: string;
    alternateContact?: any;
    email: string;
    active: boolean;
    customerId?: number;
    enabled?: boolean;
    customerPaymentAccount?: CustomerPaymentAccount;
    managerName: string;
    addressInfo: IAddress;
    defaultStore?: any;
    logisticsCharges?: number;
    franchiseId?: number;
    partnerInfo?: PartnerInfo;
    timeslots?: Timeslot[];
    pickupPersonId?: any;
    startTime?: any;
    endTime?: any;
    distance?: any;
    droplocation?: any;
    warehouse?: any;
    collectionCenter?: any;
}

export interface NextSchedulerOrder {
    id: number;
    totalAmount: number;
    totalQty: number;
    logisticsCharges: number;
    orderType: string;
    createdDate: Date;
    timeSlot: Timeslot;
    status: string;
    orderItems: OrderItem[];
    seller?: any;
    buyer?: any;
    sellerId?: any;
    contactDetails: ContactDetails;
    pickupPerson?: any;
    statusText: string;
    statusTitle: string;
    displayStatusTitle: string;
    rejectionReason?: any;
    lastNote?: any;
    alert: string;
    weightOfOrder: number;
    allowEdit: boolean;
    statusTime: Date;
    pickupTime?: any;
    storeId?: any;
    customerStore?: any;
    netAmount: number;
    totalTax: number;
    documents: any[];
    payment?: any;
    orderDate: Date;
    vehicleNumber?: any;
    sellerUserId?: any;
    buyerUserId: number;
    active: boolean;
    formattedId: string;
    channelType: string;
    bidId?: any;
    offerId?: any;
    orderAction?: any;
    paymentMode?: any;
    warehouse?: any;
    gstOrder: boolean;
    saleOrder?: any;
}

export interface OrderItem {
    id: number;
    qty: number;
    verifyQty: number;
    itemPrice: number;
    itemId: number;
    pickupOrderId: number;
    item: Product;
    itemTotal: number;
    itemQty: number;
    receivedQty: number;
    itemSgstPercentage: number;
    itemCgstPercentage: number;
    itemSpecifications: any[];
}
