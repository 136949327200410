import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Platform} from '@ionic/angular';
import {AuthService} from '../../auth/services/auth.service';
import {DESKTOP_ROUTES, MOBILE_ROUTES} from '../../route-constants';

@Injectable({
    providedIn: 'root'
})
export class AppInitializerService {
    isMobile: boolean;
    isMobileWeb: boolean;
    constructor(private storage: Storage, private authService: AuthService, private plt: Platform) {
    }

    initApp(): Promise<any> {
        // for now getting only tokens later this method can be altered to perform various
        // other activities required to be executed during app initialization
        return this.plt.ready().then(() => {
            console.log(this.plt.platforms());

            this.isMobile = (this.plt.is('android') || this.plt.is('ios'));
            this.isMobileWeb = (this.plt.is('mobileweb') || true);

            Object.defineProperty(window, 'IS_MOBILE', {value: this.isMobile, writable: false});
            Object.defineProperty(window, 'IS_MOBILE_WEB', {value: this.isMobileWeb, writable: false});

            if (this.isMobile) {
                Object.defineProperty(window, 'ROUTES', {value: MOBILE_ROUTES, writable: false});
            } else {
                Object.defineProperty(window, 'ROUTES', {value: DESKTOP_ROUTES, writable: false});
            }

            return this.storage.get('access_token').then(token => {
                this.authService.setAccessTokenAndState(token);
                return token;
            });
        });
    }



    authInit() {

    }
}
