import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-html-modal',
  templateUrl: './html-modal.component.html',
  styleUrls: ['./html-modal.component.scss'],
})
export class HtmlModalComponent implements OnInit {
  @Input() title: string;
  @Input() htmlContent: string;
  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  closeModal(data, role) {
    this.modalController.dismiss(data, role).then();
  }

}
