import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { ModalController } from "@ionic/angular";
import { AuthService } from "../../auth/services/auth.service";
import { HelperService } from "../../shared/services/helper.service";
var PrivacyPolicyComponent = /** @class */ (function () {
    function PrivacyPolicyComponent(sanitizer, modalController, helper, authService) {
        this.sanitizer = sanitizer;
        this.modalController = modalController;
        this.helper = helper;
        this.authService = authService;
    }
    PrivacyPolicyComponent.prototype.ngOnInit = function () {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        this.frame.nativeElement.addEventListener('load', this.setStyle.bind(this));
    };
    PrivacyPolicyComponent.prototype.setStyle = function (event) {
        try {
            var style = document.createElement('style');
            style.textContent =
                '.fixed-nav {' +
                    '  display: none !important;' +
                    '}';
            event.target.contentWindow.head.appendChild(style);
        }
        catch (e) {
            console.log('Failed to set style for iframe content');
        }
    };
    PrivacyPolicyComponent.prototype.acceptPrivacyPolicy = function () {
        var _this = this;
        var dta = {
            'policyId': this.policyDetails.id
        };
        this.authService.acceptPrivacyPolicy(dta).subscribe(function (res) {
            _this.helper.showToast('Privacy Policy has been accepted successfully', 'primary');
            _this.closeModal();
        });
    };
    PrivacyPolicyComponent.prototype.closeModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.dismiss()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return PrivacyPolicyComponent;
}());
export { PrivacyPolicyComponent };
