import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
var IframeComponent = /** @class */ (function () {
    function IframeComponent(sanitizer, modalController) {
        this.sanitizer = sanitizer;
        this.modalController = modalController;
    }
    IframeComponent.prototype.ngOnInit = function () {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        this.frame.nativeElement.addEventListener('load', this.setStyle.bind(this));
    };
    IframeComponent.prototype.setStyle = function (event) {
        try {
            var style = document.createElement('style');
            style.textContent =
                '.fixed-nav {' +
                    '  display: none !important;' +
                    '}';
            event.target.contentWindow.head.appendChild(style);
        }
        catch (e) {
            console.log('Failed to set style for iframe content');
        }
    };
    IframeComponent.prototype.closeModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.dismiss()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return IframeComponent;
}());
export { IframeComponent };
