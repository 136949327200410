<ion-item text-wrap="" lines="none" [disabled]="disable" class="desktop-product-item  cursor-pointer" (click)="onItemSelect($event)">
    <ion-img slot="start" [img-preloader]="item?.icon"></ion-img>
    <!--<img slot="start" img-preloader="{{item?.icon}}" alt="">-->
    <ion-label class="product-name" text-wrap>
        <p d-flex="" align-items-center="">
            <span color-dark text-medium
                  [innerHTML]="item?.name | highlightSearch: textToHighlight | safeHtml"></span>&nbsp;

        </p>
        <p class="price" text-regular font-bold="" *ngIf="showPrice && item?.price && item?.unitType">
            <span *ngIf="item?.tradeType === tradeType.S" color-danger d-flex align-items-center>
                 <ion-icon mode="ios" name="arrow-round-down" font-bold class="rotate-45" color="danger"></ion-icon>
                Pay
                ₹   {{item?.price}}/{{item?.unitType | lowercase}}
            </span>
            <span *ngIf="item?.tradeType === tradeType.B" color-primary d-flex align-items-center>
                <ion-icon mode="ios" name="arrow-round-up" font-bold  class="rotate-45" color-primary></ion-icon>
                Earn
                ₹{{item?.price}}/{{item?.unitType | lowercase}}
            </span>
        </p>
    </ion-label>
    <ion-icon slot="end" color="primary" src="/assets/imgs/checked.svg"
              *ngIf="autoSelect || (item.id==selectedItemIdInGroup)">
    </ion-icon>

    <!--<ion-icon slot="end"
              *ngIf="(this.selectionMode != itemSelectionMode.NO_SELECTION) && !(autoSelect || (item.id==selectedItemIdInGroup))"
              src="/assets/imgs/unchecked.svg">
    </ion-icon>-->

</ion-item>
