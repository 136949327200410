/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./img-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../directives/img-preloader/img-preloader.directive";
import * as i6 from "./img-list.component";
import * as i7 from "../../services/file-manager.service";
var styles_ImgListComponent = [i0.styles];
var RenderType_ImgListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImgListComponent, data: {} });
export { RenderType_ImgListComponent as RenderType_ImgListComponent };
function View_ImgListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-button", [["fill", "clear"], ["no-box-shadow", ""], ["size", "small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteImg(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fill: [0, "fill"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["name", "close-circle"], ["size", "small"]], [[4, "color", null]], null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"], size: [1, "size"] }, null)], function (_ck, _v) { var currVal_0 = "clear"; var currVal_1 = "small"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = "close-circle"; var currVal_4 = "small"; _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_2 = "#E5EAED"; _ck(_v, 2, 0, currVal_2); }); }
function View_ImgListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "img-node"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImgListComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [], [[1, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onImageClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 81920, null, 0, i5.ImagePreloaderDirective, [], { targetSource: [0, "targetSource"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDelete; _ck(_v, 2, 0, currVal_0); var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.url); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4).finalImage; _ck(_v, 3, 0, currVal_1); }); }
export function View_ImgListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "uploaded-imgs"], ["no-padding", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImgListComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgList; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ImgListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img-list", [], null, null, null, View_ImgListComponent_0, RenderType_ImgListComponent)), i1.ɵdid(1, 114688, null, 0, i6.ImgListComponent, [i3.ModalController, i7.FileManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImgListComponentNgFactory = i1.ɵccf("img-list", i6.ImgListComponent, View_ImgListComponent_Host_0, { imgList: "imgList", showDelete: "showDelete" }, { onDeleteImg: "onDeleteImg" }, []);
export { ImgListComponentNgFactory as ImgListComponentNgFactory };
