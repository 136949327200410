import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../shared.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { Events, NavController, Platform } from '@ionic/angular';
import { FacebookEvents, MyEvents } from '../my-events.const';
import { RCYL_ORDERS_GLOBALS } from 'rcyl-orders';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/firebase-analytics/ngx/index";
import * as i2 from "../shared.service";
import * as i3 from "@angular/router";
import * as i4 from "@ionic-native/facebook/ngx/index";
import * as i5 from "@ionic/angular";
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(firebaseAnalytics, sharedService, route, facebook, events, nav, router, platform) {
        var _this = this;
        this.firebaseAnalytics = firebaseAnalytics;
        this.sharedService = sharedService;
        this.route = route;
        this.facebook = facebook;
        this.events = events;
        this.nav = nav;
        this.router = router;
        this.platform = platform;
        this.sharedService.fetchCustomerProfile()
            .first()
            .subscribe(function (data) {
            _this.setUserData(data);
        });
    }
    /**
     * enable or disable analytics
     */
    AnalyticsService.prototype.init = function () {
        var _this = this;
        if (window.IS_MOBILE && !window.IS_MOBILE_WEB) {
            if (this.platform.is('cordova') && !this.platform.is('mobileweb')) {
                this.firebaseAnalytics.setEnabled(environment.analytics.firebase);
                this.logFacebookEvent(FacebookEvents.StandardEvents.EVENT_NAME_ACTIVATED_APP);
                document.addEventListener('PageTracker:PageChanged', function (event) {
                    if (_this.platform.is('cordova') && !_this.platform.is('mobileweb')) {
                        // @ts-ignore
                        if (event && event.detail) {
                            // @ts-ignore
                            _this.screenViewChanged(event.detail.pageName);
                            _this.logFacebookEvent(FacebookEvents.StandardEvents.EVENT_NAME_VIEWED_CONTENT, {
                                CONTENT_TYPE: 'PAGE',
                                // @ts-ignore
                                CONTENT_ID: event.detail.pageName
                            });
                            // @ts-ignore
                            console.log('Screen view change is logged', event.detail);
                        }
                    }
                });
                this.events.subscribe(RCYL_ORDERS_GLOBALS.EVENTS_TO_PUBLISH.ANALYTICS, function (data) {
                    _this.logEventsFromRcylOrderListModule(data);
                });
            }
        }
    };
    AnalyticsService.prototype.setUserData = function (user) {
        if (window.IS_MOBILE && !window.IS_MOBILE_WEB) {
            if (this.platform.is('cordova')) {
                this.firebaseAnalytics.setUserId(user.formattedId).then();
                this.firebaseAnalytics.setUserProperty('name', user.name).then();
                this.firebaseAnalytics.setUserProperty('mobile', user.mobile ? '' + user.mobile : '').then();
                this.firebaseAnalytics.setUserProperty('email', user.email ? user.email : '').then();
                this.firebaseAnalytics.setUserProperty('businessType', (user.businessType && user.businessType.title) ? user.businessType.title : '');
            }
        }
    };
    AnalyticsService.prototype.screenViewChanged = function (screen) {
        if (window.IS_MOBILE && !window.IS_MOBILE_WEB) {
            if (this.platform.is('cordova') && !this.platform.is('mobileweb')) {
                this.firebaseAnalytics.setCurrentScreen(screen);
            }
        }
    };
    AnalyticsService.prototype.logEvent = function (name, params, where) {
        if (window.IS_MOBILE && !window.IS_MOBILE_WEB) {
            if (this.platform.is('cordova') && !this.platform.is('mobileweb')) {
                switch (where) {
                    case 'FIREBASE':
                        this.logFirebaseEvent(name, params);
                        break;
                    case 'FACEBOOK':
                        this.logFacebookEvent(name, params);
                        break;
                    default:
                        this.logFirebaseEvent(name, params);
                        this.logFacebookEvent(name, params);
                }
            }
        }
    };
    AnalyticsService.prototype.logPurchase = function (value, currency) {
        this.facebook.logPurchase(value, currency).then(function () {
        }, function () {
        });
    };
    AnalyticsService.prototype.logFacebookEvent = function (name, params) {
        if (environment.analytics.facebook) {
            this.facebook.logEvent(name, params).then(function () {
            }, function (err) {
                console.log(err);
            });
        }
    };
    AnalyticsService.prototype.logFirebaseEvent = function (name, params) {
        if (window.IS_MOBILE) {
            this.firebaseAnalytics.logEvent(name, params).then(function () {
            }, function () {
            });
        }
    };
    AnalyticsService.prototype.logEventsFromRcylOrderListModule = function (data) {
        switch (data.eventName) {
            case 'MARKET_MARK_AS_DISPATCHED_BTN_CLICKED':
                this.logEvent(MyEvents.MARKETPLACE_MARK_DISPATCH_CLICKED, data.params);
                break;
            case 'MARKETPLACE_ORDER_DISPATCHED':
                this.logEvent(MyEvents.MARKETPLACE_ORDER_DISPATCHED_SUCCESS, data.params);
                break;
            case 'MARKETPLACE_SELLER_WEIGH_BRIDGE_OPENED':
                this.logEvent(MyEvents.MARKETPLACE_SELLER_WEIGHBRIDGE_OPENED_BY_BUYER, data.params);
                break;
            case 'MARKETPLACE_BUYER_WEIGH_BRIDGE_OPENED':
                this.logEvent(MyEvents.MARKETPLACE_BUYER_WEIGHBRIDGE_OPENED_BY_SELLER, data.params);
                break;
            case 'MARKETPLACE_RECEIVED_TRANSACTION_OPENED':
                break;
            case 'MARKETPLACE_DELIVERED_TRANSACTION_OPENED':
                this.logEvent(MyEvents.MARKETPLACE_DELIVERED_TRANSACTION_OPENED_BY_SELLER, data.params);
                break;
            default:
                this.logEvent(data.eventName, data.params);
        }
    };
    AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.ɵɵinject(i1.FirebaseAnalytics), i0.ɵɵinject(i2.SharedService), i0.ɵɵinject(i3.ActivatedRoute), i0.ɵɵinject(i4.Facebook), i0.ɵɵinject(i5.Events), i0.ɵɵinject(i5.NavController), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i5.Platform)); }, token: AnalyticsService, providedIn: "root" });
    return AnalyticsService;
}());
export { AnalyticsService };
