import {NextSchedulerOrder} from '../pickup/pickup.model';

export enum CustomerBsType {
    BULK_GENERATOR,
    RECYCLER,
    AGGREGATOR
}


export interface CustomerStats {
    totalPickups: number;
    totalKgs: number;
    nextScheduleDate: Date;
    airPollutants: number;
    noOfTrees: number;
    energySaved: number;
    landfillSpace: number;
    oilLts: number;
    waterLts: number;
    totalEarning: number;
    nextSchedulerOrder: NextSchedulerOrder;
    totalUnits: number;
    recykalTotalKgs: number;
}

export interface UserAccount {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    email: string;
    imageUrl?: null;
    activated: boolean;
    langKey: string;
    createdBy: string;
    createdDate: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    authorities?: (string)[] | null;
    profile: UserAccountProfile;
    onboardingStatus?: (OnboardingStatusEntity)[] | null;
    verficationDone: boolean;
    registrationDone: boolean;
    hasPassword?: boolean;
}
export interface UserAccountProfile {
    id: number;
    mobile: string;
    gstin?: null;
    pan?: null;
    zipcode: string;
    cityId: number;
    latLng: LatLng;
    address?: null;
    userId: number;
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    langKey: string;
    activated: boolean;
    createdDate: string;
    createdBy: string;
    insuranceStatus: boolean;
    kycStatus: boolean;
    businessType: BusinessType;
    contactPerson: ContactPerson;
    operatingCategories?: (null)[] | null;
    franchiseId: number;
    franchiseName: string;
    franchiseUrl?: null;
    formattedId: string;
    dummyCustomer: boolean;
    totalCompletedOrders?: null;
    documents?: null;
    additionalInfo?: null;
    vehicleNumber?: null;
    canBuy: boolean;
    canSale: boolean;
    canSaleOnExchange: boolean;
    canBuyOnExchange: boolean;
    inNetwork?: null;
    registerCustomer: boolean;
    verificationDone: boolean;
    uzedUserId?: null;
    appUser: boolean;
    subscribedToSHS: boolean;
    exchangeBeta?: boolean;
    mobileVerified?: boolean;
    emailVerified?: boolean;

}
export interface LatLng {
    lat: number;
    lng: number;
}
export interface BusinessType {
    id: number;
    title: string;
    active: boolean;
    code: string;
    info?: null;
    seqNo: number;
    canBuy: boolean;
    canSale: boolean;
    canSaleOnExchange: boolean;
    canBuyOnExchange: boolean;
    image?: null;
    profileAttributes?: (null)[] | null;
    group?: string;
}
export interface ContactPerson {
    id: number;
    firstName: string;
    lastName?: null;
    mobile: string;
    designation: string;
    email?: null;
    type?: null;
    active?: null;
}
export interface OnboardingStatusEntity {
    stepName: string;
    status: boolean;
}
