import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActionSheetController, Events} from '@ionic/angular';
import {API} from './api.url';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {DocumentDetails, DocumentUploadMeta} from '../models/common.model';
import {File} from '@ionic-native/file/ngx';
import * as _ from 'lodash';
import {ImagePicker} from '@ionic-native/image-picker/ngx';
import {HelperService} from './helper.service';
import {IDocument} from '../../models/document.model';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

declare let window: any;

// media, document and files related all stuff should be here.
@Injectable({
    providedIn: 'root'
})

export class FileManagerService {

    private imagePickerOptions = {
        maximumImagesCount: 4,
    };

    constructor(private http: HttpClient,
                private events: Events,
                private actionSheetController: ActionSheetController,
                private camera: Camera,
                private file: File,
                private imagePicker: ImagePicker,
                private helperService: HelperService,
                private inAppBrowser: InAppBrowser) {
    }

    getSignedUrl(request: DocumentUploadMeta): Promise<DocumentDetails> {
        return new Promise((resolve, reject) => {
            this.http.post(`${API.SIGNED_URL()}`, request)
                .toPromise().then((res: DocumentDetails) => {
                    resolve(res);
                }, (error) => {
                    reject(error);
                }
            );
        });
    }

    uploadAndAttachFile(documentMeta: DocumentUploadMeta, file) {
        return new Promise((resolve, reject) => {
            this.getSignedUrl(documentMeta).then(result => {
                this.uploadFileToS3(file, result).then(response => {
                    result.entityId = documentMeta.entityId;
                    this.attachUploadedFileToEntity(result, 'CORE').then((data) => {
                        resolve(data);
                    }, (err) => {
                        reject(err);
                    });
                }, (err) => {
                    reject(err);
                });
            });
        });
    }

    uploadFileToS3(file, result) {
        return new Promise((resolve, reject) => {
            this.helperService.createLoader('Uploading..').then((loaderObj) => {
                loaderObj.present().then();
                const headersValues = new HttpHeaders();
                headersValues.append('Content-Type', file.type);
                this.http.put(result.signedUrl, file, {headers: headersValues})
                    .toPromise().then((res) => {
                        loaderObj.dismiss().then();
                        resolve(res);
                    }, (error) => {
                        loaderObj.dismiss().then();
                        reject(error);
                    }
                );
            });
        });
    }

    readAndUploadFile(fileUri, documentMeta) {
        return new Promise((resolve, reject) => {
            this.getFileMeta(fileUri).then((fileMeta: any) => {
                documentMeta.contentType = 'image/jpeg';
                documentMeta.fileName = fileMeta.name;
                documentMeta.fileSize = fileMeta.size;
                return this.readFile(fileMeta.dirName, fileMeta.name).then((data) => {
                    return this.uploadAndAttachFile(documentMeta, new Blob([data], {
                        type: documentMeta.contentType
                    })).then((uploadedImgDetails) => {
                        resolve(uploadedImgDetails);
                    });
                }, (err) => {
                    reject(err);
                });
            }, (err) => {
                reject(err);
            });
        });
    }

    captureImgAndReturnFile() {
        return new Promise((resolve, reject) => {
            const options: CameraOptions = {
                quality: 60,
                destinationType: this.camera.DestinationType.FILE_URI,
                encodingType: this.camera.EncodingType.JPEG,
                mediaType: this.camera.MediaType.PICTURE,
                sourceType: this.camera.PictureSourceType.CAMERA
            };
            return this.camera.getPicture(options).then((imageData) => {
                resolve(imageData);
            }, (err) => {
                reject(err);
            });
        });
    }

    captureImgAndUpload(documentMeta: DocumentUploadMeta) {
        return new Promise((resolve, reject) => {
            return this.captureImgAndReturnFile().then((imgUri) => {
                this.readAndUploadFile(imgUri, documentMeta).then((uploadedImgsInfo) => {
                    resolve([uploadedImgsInfo]);
                }, (err) => {
                    reject(err);
                });
            }, (err) => {
                reject(err);
            });
        });
    }

    selectImgFromGalleryAndUpload(documentMeta: DocumentUploadMeta, noOfImgsAllowedForSelect: number) {
        return new Promise((resolve, reject) => {
            if (noOfImgsAllowedForSelect != null) {
                this.imagePickerOptions.maximumImagesCount = noOfImgsAllowedForSelect;
            }
            this.imagePicker.getPictures(this.imagePickerOptions).then((imgUris) => {
                const fileUploads = [];
                console.log(imgUris);
                imgUris.forEach((imgUri, seq) => {
                    fileUploads[seq] = this.readAndUploadFile(imgUri, documentMeta);
                });
                Promise.all(fileUploads).then((data) => {
                    resolve(data);
                }, (err) => {
                    reject(err);
                });
            }, (err) => {
                reject(err);
            });
        });
    }

    showUploadOptionsForImg() {
        return new Promise((resolve, reject) => {
            this.actionSheetController.create({
                header: 'Upload Options',
                buttons: [
                    {
                        text: 'Open Camera',
                        role: 'camera',
                        icon: 'camera'
                    },
                    {
                        text: 'Select Image',
                        icon: 'images',
                        role: 'files'
                    }]
            }).then((data) => {
                const actionSheet = data;
                actionSheet.present().then(() => {
                    resolve(actionSheet);
                }, (err) => {
                    reject(err);
                });
            }, (err) => {
                reject(err);
            });
        });
    }

    selectAndUploadImgS3(documentMeta: DocumentUploadMeta, noOfImgsAllowedForSelect?: number) {
        return new Promise((resolve, reject) => {
            this.showUploadOptionsForImg().then((actionObj: any) => {
                actionObj.onDidDismiss().then((selectedAction) => {
                    if (selectedAction && selectedAction.role) {
                        if (selectedAction.role === 'camera') {
                            this.captureImgAndUpload(documentMeta).then((data) => {
                                resolve(data);
                            }, (err) => {
                                reject(err);
                            });
                        } else if (selectedAction.role === 'files') {
                            return this.selectImgFromGalleryAndUpload(documentMeta, noOfImgsAllowedForSelect).then((data) => {
                                resolve(data);
                            }, (err) => {
                                reject(err);
                            });
                        }
                    }
                }, (err) => {
                    reject(err);
                });
            }, (err) => {
                reject(err);
            });
        });

    }

    getFileMeta(fileUri) {
        return new Promise((resolve, reject) => {
            this.file.resolveLocalFilesystemUrl(fileUri).then((fileEntry) => {
                fileEntry.getMetadata((metadata) => {
                    fileEntry.getParent((dirEntry) => {
                        resolve(_.merge({name: fileEntry.name, dirName: dirEntry.nativeURL}, metadata));
                    }, (err) => {
                        return reject(err);
                    });
                }, (err) => {
                    return reject(err);
                });
            }, (err) => {
                return reject(err);
            });
        });
    }

    readFile(fileUri, fileName) {
        return this.file.readAsArrayBuffer(fileUri, fileName);
    }

    deleteDocument(docId) {
        return this.http.put(`${API.DELETE_DOC()}?id=${docId}`, '');
    }

    attachUploadedFileToEntity(request: DocumentDetails, uploadAgainst: 'CORE' | 'STACKHOLDER' | 'CATALOG') {
        // adding case because in platform different urls for attach.
        if (request && request.signedUrl && !request.url) {
            const splittedString = request.signedUrl.split('?X-Amz-');
            if (splittedString && splittedString[0]) {
                request.url = splittedString[0];
            }
        }
        return new Promise((resolve, reject) => {
            switch (uploadAgainst) {
                case 'CORE':
                    this.http.post(`${API.CORE_DOCUMENTS_ATTACH()}`, request)
                        .toPromise().then((res) => {
                            resolve(res);
                        }, (error) => {
                            reject(error);
                        }
                    );
                    break;
                case 'STACKHOLDER':
                    reject('Not supported');
                    break;
                case 'CATALOG':
                    reject('Not supported');
                    break;
                default:
                    reject('Not supported');
            }
        });
    }

    downloadFile(orderDocument: IDocument | DocumentDetails) {
        if (orderDocument && orderDocument.url) {
            if (window.cordova) {
                const openbrowser = this.inAppBrowser.create(orderDocument.url, '_system');
            } else {
                const openInNew = document.createElement('a');
                openInNew.target = '_blank';
                openInNew.href = orderDocument.url;
                openInNew.click();
            }
        }
    }


}
