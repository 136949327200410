import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PickupService } from '../../../pickup/pickup.service';
import * as _ from 'lodash';
import { MarketService } from '../../../market/market.service';
import { Router } from '@angular/router';
import { FacebookEvents } from '../../../shared/my-events.const';
var ProductItemSearchComponent = /** @class */ (function () {
    function ProductItemSearchComponent(modalController, pickupService, marketService, router) {
        this.modalController = modalController;
        this.pickupService = pickupService;
        this.marketService = marketService;
        this.router = router;
        this.categorizedItems = {};
    }
    ProductItemSearchComponent.prototype.ngOnInit = function () {
        this.channelName = !this.channelName ? 'B2B' : this.channelName;
    };
    ProductItemSearchComponent.prototype.closeModal = function () {
        this.modalController.dismiss().then();
    };
    ProductItemSearchComponent.prototype.searchItems = function (textToSearch) {
        var _this = this;
        if (textToSearch) {
            switch (this.channelName) {
                case 'B2B':
                    this.pickupService.searchProductsForSelectedStore(textToSearch).subscribe(function (data) {
                        _this.showNoItems = true;
                        _this.categorizedItems = data;
                        _this.analyticsEventLogger(FacebookEvents.StandardEvents.EVENT_NAME_SEARCHED, {
                            CONTENT_TYPE: 'PRODUCT_ITEM',
                            SEARCH_STRING: textToSearch,
                            SUCCESS: Object.keys(_this.categorizedItems).length > 0 ? 'YES' : 'NO'
                        }, 'FACEBOOK');
                    }, function () {
                        _this.categorizedItems = {};
                        _this.analyticsEventLogger(FacebookEvents.StandardEvents.EVENT_NAME_SEARCHED, {
                            CONTENT_TYPE: 'PRODUCT_ITEM',
                            SEARCH_STRING: textToSearch,
                            SUCCESS: 'NO'
                        }, 'FACEBOOK');
                    });
                    break;
                case 'Marketplace':
                    this.marketService.searchProducts(textToSearch).subscribe(function (data) {
                        _this.showNoItems = true;
                        _this.categorizedItems = data;
                        _this.analyticsEventLogger(FacebookEvents.StandardEvents.EVENT_NAME_SEARCHED, {
                            CONTENT_TYPE: 'PRODUCT_ITEM',
                            SEARCH_STRING: textToSearch,
                            SUCCESS: Object.keys(_this.categorizedItems).length > 0 ? 'YES' : 'NO'
                        }, 'FACEBOOK');
                    }, function () {
                        _this.categorizedItems = {};
                        _this.analyticsEventLogger(FacebookEvents.StandardEvents.EVENT_NAME_SEARCHED, {
                            CONTENT_TYPE: 'PRODUCT_ITEM',
                            SEARCH_STRING: textToSearch,
                            SUCCESS: 'NO'
                        }, 'FACEBOOK');
                    });
                    break;
            }
        }
        else {
            this.categorizedItems = {};
        }
    };
    ProductItemSearchComponent.prototype.isAutoSelectable = function (productItem) {
        return (_.some(this.autoSelectedItems, function (item) {
            return item.id === productItem.id;
        }));
    };
    ProductItemSearchComponent.prototype.markAutoSelectedItemDisable = function (item) {
        return this.disableAutoSelected && this.isAutoSelectable(item);
    };
    ProductItemSearchComponent.prototype.itemSelected = function (event) {
        var _this = this;
        switch (this.channelName) {
            case 'Marketplace':
                this.router.navigate([window.ROUTES.MARKET_LISTINGS + event.id], { skipLocationChange: true, replaceUrl: true }).then(function () {
                    _this.modalController.dismiss().then();
                }, function () {
                    _this.modalController.dismiss().then();
                });
                break;
            case 'B2B':
                this.showProductAddToCartModal(event, true).then();
                break;
        }
        this.selectedItemId = event.id;
    };
    ProductItemSearchComponent.prototype.showProductAddToCartModal = function (productData, showBackdrop) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.pickupService.showProductQtyEditForPickupRequest(productData, false, showBackdrop).then(function (modalElement) {
                    modalElement.onDidDismiss().then(function (result) {
                        _this.selectedItemId = null;
                        if (result.data) {
                            _this.closeModal();
                        }
                        // this.resetSelection.next(true);
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    ProductItemSearchComponent.prototype.isEmpty = function (obj) {
        return !Object.keys(obj).length;
    };
    return ProductItemSearchComponent;
}());
export { ProductItemSearchComponent };
