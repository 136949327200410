import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { AuthService } from '../../auth/services/auth.service';
import { DESKTOP_ROUTES, MOBILE_ROUTES } from '../../route-constants';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
import * as i2 from "../../auth/services/auth.service";
import * as i3 from "@ionic/angular";
var AppInitializerService = /** @class */ (function () {
    function AppInitializerService(storage, authService, plt) {
        this.storage = storage;
        this.authService = authService;
        this.plt = plt;
    }
    AppInitializerService.prototype.initApp = function () {
        var _this = this;
        // for now getting only tokens later this method can be altered to perform various
        // other activities required to be executed during app initialization
        return this.plt.ready().then(function () {
            console.log(_this.plt.platforms());
            _this.isMobile = (_this.plt.is('android') || _this.plt.is('ios'));
            _this.isMobileWeb = (_this.plt.is('mobileweb') || true);
            Object.defineProperty(window, 'IS_MOBILE', { value: _this.isMobile, writable: false });
            Object.defineProperty(window, 'IS_MOBILE_WEB', { value: _this.isMobileWeb, writable: false });
            if (_this.isMobile) {
                Object.defineProperty(window, 'ROUTES', { value: MOBILE_ROUTES, writable: false });
            }
            else {
                Object.defineProperty(window, 'ROUTES', { value: DESKTOP_ROUTES, writable: false });
            }
            return _this.storage.get('access_token').then(function (token) {
                _this.authService.setAccessTokenAndState(token);
                return token;
            });
        });
    };
    AppInitializerService.prototype.authInit = function () {
    };
    AppInitializerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppInitializerService_Factory() { return new AppInitializerService(i0.ɵɵinject(i1.Storage), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.Platform)); }, token: AppInitializerService, providedIn: "root" });
    return AppInitializerService;
}());
export { AppInitializerService };
