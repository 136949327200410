import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { IframeComponent } from './components/iframe-component/iframe.component';
import { HelperService } from './services/helper.service';
import { API } from './services/api.url';
import { HttpClient } from '@angular/common/http';
import { OtpPageViews } from './models/common.model';
import { Observable } from 'rxjs';
import 'rxjs-compat/add/operator/first';
import 'rxjs-compat/add/operator/map';
import { CustomerBsType } from '../customer/customer.model';
import { Storage } from '@ionic/storage';
import { CustomerService } from '../customer/customer.service';
import { LocationPermissionComponent } from './components/location-permission/location-permission.component';
import { Device } from '@ionic-native/device/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { HtmlModalComponent } from './components/html-modal/html-modal.component';
import { AuthService } from '../auth/services/auth.service';
import { BUGSNAG_CLIENT } from '../third-party/bugsnag/bugsnag.module';
// tslint:disable-next-line:max-line-length
import { OnboardingPendingErrorComponent } from '../desktop/auth/pages/auth-container/components/onboarding-pending-error/onboarding-pending-error.component';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/router";
import * as i3 from "../auth/services/auth.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./services/helper.service";
import * as i6 from "@ionic/storage";
import * as i7 from "../customer/customer.service";
import * as i8 from "@ionic-native/device/ngx/index";
import * as i9 from "@ionic-native/app-version/ngx/index";
var SharedService = /** @class */ (function () {
    function SharedService(toastController, router, authService, modalController, httpClient, helper, storage, customerService, navCtrl, device, activatedRoute, appVersion) {
        this.toastController = toastController;
        this.router = router;
        this.authService = authService;
        this.modalController = modalController;
        this.httpClient = httpClient;
        this.helper = helper;
        this.storage = storage;
        this.customerService = customerService;
        this.navCtrl = navCtrl;
        this.device = device;
        this.activatedRoute = activatedRoute;
        this.appVersion = appVersion;
        this.httpCallInProgress = new BehaviorSubject(false);
        this.customerStats = new BehaviorSubject(null);
        this.customerBsTypes = CustomerBsType;
    }
    SharedService.prototype.setHttpCallInProgress = function (val) {
        this.httpCallInProgress.next(val);
    };
    SharedService.prototype.getHttpCallInProgress = function () {
        return this.httpCallInProgress.getValue();
    };
    SharedService.prototype.openExternalUrl = function (url, title) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.modalController.create({
                                component: IframeComponent,
                                componentProps: {
                                    url: url,
                                    showModalClose: true,
                                    title: title
                                },
                                cssClass: 'modal-page'
                            })];
                    case 1:
                        _a.privacyPolicyModal = _b.sent();
                        this.privacyPolicyModal.onDidDismiss().then(function (result) {
                            // console.log(result);
                        });
                        return [4 /*yield*/, this.privacyPolicyModal.present()];
                    case 2: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    SharedService.prototype.fetchCustomerStats = function () {
        var _this = this;
        return this.httpClient.get("" + API.STATS())
            .map(function (data) {
            _this.customerStats.next(data);
            return data;
        });
    };
    SharedService.prototype.fetchCustomerProfile = function () {
        var _this = this;
        return this.httpClient.get("" + API.PROFILE())
            .map(function (data) {
            _this.customerStats.next(data);
            return data;
        });
    };
    SharedService.prototype.getCities = function () {
        var _this = this;
        return new Observable(function (observe) {
            if (_this.cityList && _this.cityList.length > 0) {
                observe.next(_this.cityList);
            }
            else {
                _this.httpClient.get("" + API.CITIES()).first().subscribe(function (data) {
                    _this.cityList = data;
                    observe.next(data);
                });
            }
        });
    };
    SharedService.prototype.getCitiesByZipCode = function (zipCode) {
        var _this = this;
        return new Observable(function (observe) {
            if (zipCode && zipCode.toString().length > 0) {
                _this.httpClient.get(API.CITIES() + "/?zipcode=" + ("" + zipCode)).subscribe(function (data) {
                    observe.next(data);
                });
            }
            else {
                observe.next([]);
            }
        });
    };
    SharedService.prototype.searchCity = function (query) {
        return this.httpClient.get(API.CITIES() + "/?query=" + ("" + query));
    };
    SharedService.prototype.getPortalConfig = function () {
        var _this = this;
        return new Observable(function (observe) {
            if (_this.portalConfig) {
                observe.next(_this.portalConfig);
            }
            else {
                _this.httpClient.get("" + API.PORTAL_CONFIG()).first().subscribe(function (data) {
                    _this.portalConfig = data;
                    observe.next(data);
                });
            }
        });
    };
    // dont place this methods in auth service
    SharedService.prototype.checkEmailVerificationPendingAndRedirect = function () {
        var _this = this;
        this.storage.get('REGISTERING_USER_EMAIL').then(function (email) {
            if (email) {
                _this.router.navigate(['auth/sign-up/otp-verification'], { queryParams: { viewToRender: OtpPageViews.UNVERIFIED }, replaceUrl: true }).then();
            }
            else {
                _this.goToLoginOrWalkThrough();
            }
        }, function () {
            _this.goToLoginOrWalkThrough();
        });
    };
    SharedService.prototype.checkAuthAccountDetailsAndRedirect = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            if (_this.authService.isAuthenticated()) {
                _this.authService.fetchAccountDetails().subscribe(function (data) {
                    if (data) {
                        BUGSNAG_CLIENT.user = {
                            id: data.id,
                            email: data.email,
                            name: data.firstName + data.lastName,
                        };
                    }
                    if (_this.authService.getAccountInfo() && _this.authService.getAccountInfo().registrationDone) {
                        // if onboarding is done
                        _this.navCtrl.navigateRoot(window.ROUTES.DASHBOARD_CONTAINER).then();
                    }
                    else {
                        // on boarding not done
                        if (window.IS_MOBILE) {
                            _this.navCtrl.navigateRoot(window.ROUTES.ON_BOARDING).then();
                        }
                        else {
                            _this.openOnBoardingError();
                        }
                    }
                    resolve();
                }, function (e) {
                    reject(e);
                });
            }
            else {
                reject('Not authorized');
            }
        });
        return promise;
    };
    SharedService.prototype.goToLoginOrWalkThrough = function () {
        var _this = this;
        this.storage.get('WALKTHROUGH_DISPLAYED').then(function (data) {
            if (data) {
                if (!window.IS_MOBILE) {
                    if (window.location.href.indexOf('/auth/forgot-password?key=') > -1) {
                        var keyVal = window.location.href.split('=');
                        _this.router.navigate([window.ROUTES.AUTH_FORGOT_PASSWORD], { queryParams: { key: keyVal[1] } }).then();
                    }
                    else {
                        _this.router.navigate([window.ROUTES.WALK_THROUGH], { replaceUrl: true }).then();
                    }
                }
                else {
                    _this.router.navigate([window.ROUTES.AUTH_LOGIN], { replaceUrl: true }).then();
                }
            }
            else {
                if (window.location.href.indexOf('/auth/forgot-password?key=') > -1) {
                    var keyVal = window.location.href.split('=');
                    _this.router.navigate([window.ROUTES.AUTH_FORGOT_PASSWORD], { queryParams: { key: keyVal[1] } }).then();
                }
                else {
                    _this.router.navigate([window.ROUTES.WALK_THROUGH], { replaceUrl: true }).then();
                }
            }
        }, function () {
            _this.router.navigate([window.ROUTES.WALK_THROUGH], { replaceUrl: true }).then();
        });
    };
    SharedService.prototype.showRequestLocationPermissionModal = function (componentProps) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var locationPermissionModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: LocationPermissionComponent,
                            backdropDismiss: true,
                            cssClass: 'modal-auto-center',
                            componentProps: componentProps
                        })];
                    case 1:
                        locationPermissionModal = _a.sent();
                        locationPermissionModal.present().then();
                        return [2 /*return*/, locationPermissionModal];
                }
            });
        });
    };
    SharedService.prototype.showHtmlModal = function (title, htmlContent) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var htmlModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: HtmlModalComponent,
                            backdropDismiss: true,
                            componentProps: {
                                title: title,
                                htmlContent: htmlContent
                            }
                        })];
                    case 1:
                        htmlModal = _a.sent();
                        return [4 /*yield*/, htmlModal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, htmlModal];
                }
            });
        });
    };
    SharedService.prototype.openOnBoardingError = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var onBoardError;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: OnboardingPendingErrorComponent,
                            cssClass: ['custom-bottom-sheet desktop-onboard-error'],
                            showBackdrop: true,
                            backdropDismiss: true,
                        })];
                    case 1:
                        onBoardError = _a.sent();
                        onBoardError.present().then();
                        onBoardError.onDidDismiss().then(function (res) {
                            _this.authService.logout();
                            setTimeout(function () {
                                location.reload();
                            }, 500);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedService_Factory() { return new SharedService(i0.ɵɵinject(i1.ToastController), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i4.HttpClient), i0.ɵɵinject(i5.HelperService), i0.ɵɵinject(i6.Storage), i0.ɵɵinject(i7.CustomerService), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i8.Device), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i9.AppVersion)); }, token: SharedService, providedIn: "root" });
    return SharedService;
}());
export { SharedService };
