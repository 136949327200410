import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Logger, LoggingService} from 'ionic-logging-service';
import {PickupService} from '../pickup.service';
import {AlertController, Events, ModalController} from '@ionic/angular';
import {Router} from '@angular/router';
import {HelperService} from '../../shared/services/helper.service';

import {Observable, Subject, Subscription} from 'rxjs';
import {Product} from '../pickup.model';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {PRODUCT_ITEM_SELECTION_MODE} from '../../product/product.model';
import {ProductService} from '../../product/product.service';
import {MyEvents} from '../../shared/my-events.const';
import {AnalyticsService} from '../../shared/services/analytics.service';
import {ProductItemSearchComponent} from '../../product/components/product-item-search/product-item-search.component';
import {PageTracker} from '../../shared/PageTracker';

@PageTracker(MyEvents.SCREENS.SELECT_PRODUCTS)
@Component({
    selector: 'app-select-products',
    templateUrl: './select-products.page.html',
    styleUrls: ['./select-products.page.scss'],
})
export class SelectProductsPage implements OnInit, OnDestroy, AfterViewInit {
    private logger: Logger;
    storeId: any;
    catalogData = []; // non grouped
    catalogDataGrouped: any;
    productAddToCartModal: any;
    selectedProductData: Product;
    catalogDataSubscription: Subscription = new Subscription();
    cartDataSubscription: Subscription = new Subscription();
    activePageView: SelectProductsPageViews;
    SelectProductsPageViews = SelectProductsPageViews;
    resetSelection = new Subject<boolean>();
    cartItems: Product [];
    productItemSelectionMode = PRODUCT_ITEM_SELECTION_MODE;
    catalogDataObservable: Observable<any>;
    window = window;

    constructor(private pickupService: PickupService,
                private alertCtrl: AlertController,
                private router: Router,
                private loggingService: LoggingService,
                private helper: HelperService,
                private analyticsService: AnalyticsService,
                private modalController: ModalController,
                private statusBar: StatusBar,
                private analytics: AnalyticsService,
                private productService: ProductService,
                private events: Events) {
        this.logger = loggingService.getLogger('all.products.component');
        this.catalogDataObservable = this.pickupService.catalogData.asObservable();
    }

    ngAfterViewInit() {
        this.selectedProductData = null;
    }

    ionViewDidEnter() {
        this.analytics.screenViewChanged(MyEvents.SCREENS.SELECTED_PRODUCTS);
    }

    ngOnInit() {
        this.statusBar.backgroundColorByHexString('#ffffff');
        this.activePageView = SelectProductsPageViews.CATEGORIZED;
        this.cartDataSubscription = this.pickupService.cartData.subscribe((cartData) => {
            if (cartData && cartData.storeData) {
                // re-fetch root categories if store changed.
                if (this.storeId !== cartData.storeData.id) {
                    this.storeId = cartData.storeData.id;
                    this.getRootCategories();
                }
            }
            if (cartData && cartData.cartItems) {
                this.cartItems = cartData.cartItems;
            }
        });
        this.events.subscribe('OPEN_QTY_EDIT_AND_SELECT_PRODUCT', (data) => {
            this.showProductAddToCartModal(data, false).then();
        });
    }

    getRootCategories() {
        this.pickupService.getCatalogRootCategoriesForSelectedStore('B2B');
    }

    getCategoryData(catData, pathToCat) {
        this.pickupService.getProductCategoryDataForSelectedStore(catData, pathToCat, 'B2B');
    }

    getCategoryDescendantItems(catData, pathToCat) {
        this.pickupService.getProductCategoryDescendentItemsForSelectedStore(catData, pathToCat, 'B2B');
    }

    async showProductAddToCartModal(productData?: Product, showBackdrop?: boolean) {
        this.pickupService.showProductQtyEditForPickupRequest(productData, false, showBackdrop).then((modalElement) => {
            modalElement.onDidDismiss().then(
                result => {
                    this.resetSelection.next(true);
                }
            );
        });
    }

    async recentlyUsedProductSelected(product) {
        await this.showProductAddToCartModal(product, false);
    }

    async categorizedProductSelected(product) {
        this.selectedProductData = product;
        this.analyticsService.logEvent(MyEvents.ITEM_CLICKED, {context: MyEvents.CONTEXT.REQUEST_PICKUP});
        if (this.selectedProductData != null) {
            this.showProductAddToCartModal(this.selectedProductData, true);
        }
    }

    handelLeafClick(event) {
        if (event) {
            this.router.navigate([window.ROUTES.CREATE_PICKUP_SELECT_PRODUCTS_SELECT_FROM_CAT], {
                queryParams: {
                    catName: event.name,
                    catId: event.id
                }
            }).then();
        }
    }

    async openSearchItemsModal() {
        const searchItemsModal = await this.modalController.create({
            component: ProductItemSearchComponent,
            showBackdrop: true,
            backdropDismiss: true,
            componentProps: {
                autoSelectedItems: this.cartItems,
                disableAutoSelected: true,
                analyticsEventLogger: this.analyticsEventLogger
            }
        });
        searchItemsModal.present().then();
        return searchItemsModal;
    }

    analyticsEventLogger = (eventName: string, params?: any, where?: 'FIREBASE' | 'FACEBOOK') => {
        this.analyticsService.logEvent(eventName, params, where);
    };

    ngOnDestroy(): void {
        this.events.unsubscribe('OPEN_QTY_EDIT_AND_SELECT_PRODUCT');
        if (this.cartDataSubscription) {
            this.cartDataSubscription.unsubscribe();
        }
    }
}

enum SelectProductsPageViews {
    RECENTLY_USED,
    CATEGORIZED
}
