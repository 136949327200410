import * as tslib_1 from "tslib";
import { ProductAddToCartComponent } from './components/product-add-to-cart/product-add-to-cart.component';
import { ModalController } from '@ionic/angular';
import { API } from '../shared/services/api.url';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
var ProductService = /** @class */ (function () {
    function ProductService(modalController, httpClient) {
        this.modalController = modalController;
        this.httpClient = httpClient;
        this.window = window;
    }
    ProductService.prototype.showProductAddToCartModal = function (productData, setSelectedQty, addToCartBtnText, showBackdrop) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var productAddToCartModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: ProductAddToCartComponent,
                            cssClass: ['bottom-sheet desktop-qty-modal'],
                            showBackdrop: showBackdrop != null ? showBackdrop : true,
                            backdropDismiss: window.IS_MOBILE,
                            componentProps: {
                                showModalClose: true,
                                product: productData,
                                setSelectedQty: setSelectedQty,
                                addToCartBtnText: addToCartBtnText
                            },
                            animated: window.IS_MOBILE
                        })];
                    case 1:
                        productAddToCartModal = _a.sent();
                        return [4 /*yield*/, productAddToCartModal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, productAddToCartModal];
                }
            });
        });
    };
    // for store
    ProductService.prototype.fetchProductCategoriesForStore = function (storeId, channel) {
        return this.httpClient.get(API.CATALOG_ROOT_CATEGORIES() + "?channelName=" + channel + "&depth=1&onlyCustomerItems=true&" +
            ("nonExchangeCatalog=true&active=true&customerConfigured=false&locationId=" + storeId))
            .map(function (data) {
            return data;
        });
    };
    ProductService.prototype.fetchProductCategoryDataForStore = function (catId, storeId, channel) {
        return this.httpClient.get(API.CATALOG_CATEGORY_DETAILS() + "?onlyCustomerItems=true&channelName=" + channel + "&nonExchangeCatalog=true&" +
            ("customerConfigured=false&active=true&locationId=" + storeId + "&categoryId=" + catId))
            .map(function (data) {
            return data;
        });
    };
    ProductService.prototype.fetchProductCategoryDescendantItems = function (catId, storeId, channel) {
        return this.httpClient.get(API.CATALOG_GET_ALL_ITEMS() + "?onlyCustomerItems=true&channelName=" + channel + "&nonExchangeCatalog=true&" +
            ("customerConfigured=false&active=true&categoryId=" + catId + "&locationId=" + storeId))
            .map(function (data) {
            return data;
        });
    };
    ProductService.prototype.fetchProductsForAllCategoryItems = function (storeId, channel) {
        return this.httpClient.get(API.CATALOG_GET_ALL_ITEMS() + "?onlyCustomerItems=true&channelName=" + channel + "&nonExchangeCatalog=true&" +
            ("customerConfigured=false&active=true&locationId=" + storeId))
            .map(function (data) {
            return data;
        });
    };
    ProductService.prototype.fetchProductsByAttributeIds = function (attrValues, storeId, channel) {
        // attributeValueId=71&attributeValueId=74
        var attrsQuery = this.convertAttributeValToQueryParamString(attrValues);
        return this.httpClient.get(API.CATALOG_GET_ALL_ITEMS() + "?" + attrsQuery + "&nonExchangeCatalog=true&customerConfigured=false" +
            ("&channelName=" + channel + "&active=true&locationId=" + storeId))
            .map(function (data) {
            return data;
        });
    };
    ProductService.prototype.fetchRecentlyUsedProducts = function (storeId, channel) {
        return this.httpClient.get(API.CATALOG_GET_ALL_ITEMS() + "?nonExchangeCatalog=true&customerConfigured=true" +
            ("&recentlyUsedItems=true&channelName=" + channel + "&active=true&locationId=" + storeId))
            .map(function (data) {
            return data;
        });
    };
    ProductService.prototype.searchAndFetchProductsForStore = function (query, storeId, channel) {
        var _this = this;
        query = query ? query.toString() : '';
        return this.httpClient.get(API.CATALOG_GET_ALL_ITEMS() + "?&nonExchangeCatalog=true&customerConfigured=false" +
            ("&channelName=" + channel + "&active=true&locationId=" + storeId + "&query=") + query)
            .map(function (data) {
            return _this.processSearchResult(data);
        });
    };
    // for market
    ProductService.prototype.fetchProductCategoriesForMarket = function () {
        return this.httpClient.get(API.CATALOG_ROOT_CATEGORIES() + "?channelName=Marketplace&depth=0&active=true")
            .map(function (data) {
            return data;
        });
    };
    ProductService.prototype.fetchProductCategoryDataForMarket = function (catId) {
        return this.httpClient.get(API.CATALOG_CATEGORY_DETAILS() + "?channelName=Marketplace&active=true&categoryId=" + catId)
            .map(function (data) {
            return data;
        });
    };
    ProductService.prototype.fetchProductCategoryDescendantItemsForMarket = function (catId) {
        return this.httpClient.get(API.CATALOG_GET_ALL_ITEMS() + "?channelName=Marketplace&active=true&categoryId=" + catId)
            .map(function (data) {
            return data;
        });
    };
    ProductService.prototype.fetchProductsByAttributeIdsForMarket = function (attrValues) {
        if (typeof attrValues === 'object') {
            attrValues = _.pickBy(attrValues, _.identity);
        }
        // attributeValueId=71&attributeValueId=74
        var attrsQuery = this.convertAttributeValToQueryParamString(attrValues);
        return this.httpClient.get(API.CATALOG_GET_ALL_ITEMS() + "?" + attrsQuery + "&channelName=Marketplace&active=true")
            .map(function (data) {
            return data;
        });
    };
    ProductService.prototype.searchAndFetchProductsForMarket = function (query) {
        var _this = this;
        query = query ? query.toString() : '';
        return this.httpClient.get(API.CATALOG_GET_ALL_ITEMS() + "?channelName=Marketplace&active=true&query=" + query)
            .map(function (data) {
            return _this.processSearchResult(data);
        });
    };
    // utilities
    ProductService.prototype.convertAttributeValToQueryParamString = function (attrValues) {
        var queryString = '';
        Object.keys(attrValues).forEach(function (val, key) {
            if (Array.isArray(attrValues[val])) {
                attrValues[val].forEach(function (subVal, subKey) {
                    queryString = queryString + '&attributeValueId=' + subVal;
                });
            }
            else {
                queryString = queryString + '&attributeValueId=' + attrValues[val];
            }
        });
        return queryString;
    };
    ProductService.prototype.processSearchResult = function (searchResult) {
        var categorizedItemsMap = {};
        searchResult.forEach(function (item, key) {
            if (item.categoryNodes && item.categoryNodes[0]) {
                if (!categorizedItemsMap[item.categoryNodes[0].name]) {
                    categorizedItemsMap[item.categoryNodes[0].name] = [];
                }
                categorizedItemsMap[item.categoryNodes[0].name].push(item);
            }
        });
        Object.keys(categorizedItemsMap).forEach(function (cat) {
            if (categorizedItemsMap[cat].lenght === 0) {
                delete categorizedItemsMap[cat];
            }
        });
        return categorizedItemsMap;
    };
    // common
    ProductService.prototype.fetchProductCategoryAttributes = function (catId, attrValueMap, prevAttrSequenceNo) {
        /*const dataToSend = ;*/
        if (attrValueMap && Object.keys(attrValueMap).length > 0 && (prevAttrSequenceNo || prevAttrSequenceNo === 0)) {
            /* url = `${API.CATALOG_CAT_ATTR()}?categoryId.equals=${catId}&active.equals=true&sort=srNo,asc`;
             _.each( attrValueMap, ( val, key ) => {
                 url = url + `&parentAttributeId.equals=${val}&parentValueId.equals=${key}`;
             } );*/
            var url = "" + API.CATALOG_CAT_CHILD_ATTR();
            return this.httpClient.post(url, {
                catId: catId,
                valueMap: _.pickBy(attrValueMap, _.identity),
                previousSeqNo: prevAttrSequenceNo
            }).map(function (data) {
                return data;
            });
        }
        else {
            var url = API.CATALOG_CAT_ATTR() + "?categoryId.equals=" + catId + "&active.equals=true&sort=srNo,asc";
            return this.httpClient.get(url)
                .map(function (data) {
                return data;
            });
        }
    };
    ProductService.prototype.fetchProductDetails = function (prodId) {
        return this.httpClient.get(API.ITEM_DETAILS() + "/" + prodId);
    };
    ProductService.prototype.fetchProductStats = function (prodId, channel, noOfRecords) {
        return this.httpClient.get(API.ITEM_STATS() + "/" + prodId + "/?channel=" + channel + "&noOfRecords=" + noOfRecords);
    };
    return ProductService;
}());
export { ProductService };
