import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PickupTimelineComponent} from './pickup-timeline.component';
import {IonicModule} from "@ionic/angular";



@NgModule({
  declarations: [PickupTimelineComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  entryComponents: [PickupTimelineComponent],
  exports: [
    PickupTimelineComponent
  ]
})
export class PickupTimelineModule { }
