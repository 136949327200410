import {Injectable} from '@angular/core';
import {API} from '../shared/services/api.url';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {CustomerBsType, UserAccount} from './customer.model';
import {PartnerInfo, Store} from '../pickup/pickup.model';
import * as _ from 'lodash';
import {DeviceInfo} from '../shared/models/common.model';
import {Device} from '@ionic-native/device/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {AppPreferences} from '@ionic-native/app-preferences/ngx';


@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    customerProfile = new BehaviorSubject(null);
    customerRole = new BehaviorSubject<CustomerBsType>(null);
    customerDefaultStore = new BehaviorSubject<Store>(null);
    customerDefaultStorePartnerInfo = new BehaviorSubject<PartnerInfo>(null);
    customerDeviceInfo = new BehaviorSubject<DeviceInfo>({});
    customerBsTypes = CustomerBsType;
    constructor(private httpClient: HttpClient,
                private device: Device,
                private appVersion: AppVersion,
                private appPreferences: AppPreferences) {
    }

    fetchCustomerProfile() {
        return this.httpClient.get(`${API.PROFILE()}`)
            .map((data: any) => {
                this.customerProfile.next(data);
                return data;
            });
    }

    setCustomerBsType(accountData: UserAccount) {
        if (accountData && accountData.profile) {
            if (accountData.profile.canSaleOnExchange) {
                this.customerRole.next(CustomerBsType.AGGREGATOR);
            } else if (accountData.profile.canBuyOnExchange) {
                this.customerRole.next(CustomerBsType.RECYCLER);
            } else if ((accountData.profile.canSaleOnExchange === false
                && accountData.profile.canBuyOnExchange === false)) {
                this.customerRole.next(CustomerBsType.BULK_GENERATOR);
            }
        }
    }

    fetchCustomerDefaultStore() {
        return this.httpClient.get(`${API.CUSTOMER_DEFAULT_STORE()}`)
            .map((data: Store) => {
                this.customerDefaultStore.next(data);
                return data;
            });
    }

    setCustomerDefaultStoreInfo(defaultPartnerInfo: PartnerInfo ) {
        this.customerDefaultStorePartnerInfo.next(defaultPartnerInfo);
    }

    sendCustomerDeviceInfo(deviceToken) {
        _.merge(this.customerDeviceInfo.value, this.device);
        this.customerDeviceInfo.value.deviceToken = deviceToken;
        // below promise all implementation will behave differently. It will always resolve and wont stop executing promises
        // event if any of promise in between fails
        Promise.all([
            this.appVersion.getVersionNumber(),
            this.appPreferences.fetch('referrer')
        ].map((promise) => {
            return promise.then((value) => {
                return {value, err: undefined, status: 'fulfilled' };
            }, (err) => {
                return {value: undefined, err, status: 'rejected' };
            });
        })).then((results) => {
            if (results[0].status === 'fulfilled' && results[0].value) {
                this.customerDeviceInfo.value.appVersion = results[0].value;
            }
            if (results[1].status === 'fulfilled' && results[1].value) {
                this.customerDeviceInfo.value.referrer = results[1].value;
            }
            this.customerDeviceInfo.next(this.customerDeviceInfo.value);
            console.log(results);
            return this.httpClient.post(`${API.DEVICE_INFO()}`, this.customerDeviceInfo.value).subscribe();
        }).catch(() => {
            return this.httpClient.post(`${API.DEVICE_INFO()}`, this.customerDeviceInfo.value).subscribe();
        });
    }

    getCustomerDeviceInfo() {
        return this.customerDeviceInfo.getValue();
    }
}



