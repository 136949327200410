import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextMaskPipe} from './text-mask.pipe';

@NgModule({
  declarations: [TextMaskPipe],
  imports: [
    CommonModule
  ],
  exports: [TextMaskPipe]
})
export class TextMaskPipeModule { }
