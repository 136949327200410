import {Facebook} from '@ionic-native/facebook';
export const MyEvents  = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_SUCCESS_EMAIL_UNVERIFIED: 'LOGIN_SUCCESS_EMAIL_UNVERIFIED',
    LOGIN_BTN_CLICKED: 'LOGIN_BTN_CLICKED',
    LOGIN_GAUTH_SUCCESS: 'LOGIN_GOOGLE_AUTH_SUCCESS',
    LOGIN_GAUTH_FAILED: 'LOGIN_GOOGLE_AUTH_FAILED',
    LOGIN_FAILED: 'LOGIN_FAILED',
    SIGNUP_BTN_CLICKED: 'SIGNUP_BTN_CLICKED',
    SIGNUP_GAUTH_SUCCESS: 'LOGIN_GOOGLE_AUTH_SUCCESS',
    SIGNUP_GAUTH_FAILED: 'LOGIN_GOOGLE_AUTH_FAILED',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_FAILED: 'SIGNUP_FAILED',
    SIGNUP_SENT_OTP_SUCCESS: 'SIGNUP_SENT_OTP_SUCCESS',
    SIGNUP_SEND_OTP_FAILED: 'SIGNUP_SEND_OTP_FAILED',
    VERIFY_OTP_BTN_CLICKED: 'VERIFY_OTP_BTN_CLICKED',
    VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
    VERIFY_OTP_POPUP_OPENED: 'VERIFY_OTP_POPUP_OPENED',
    VERIFY_OTP_FAILED: 'VERIFY_OTP_FAILED',
    RESEND_OTP_BTN_CLICKED: 'RESEND_OTP_BTN_CLICKED',
    RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
    RESEND_OTP_FAILED: 'RESEND_OTP_FAILED',
    GET_STARTED_BTN_CLICKED: 'GET_STARTED_BTN_CLICKED',
    ONBOARD_BTYPE_SELECTED: 'ONBOARD_BTYPE_SELECTED',
    ONBOARD_BTYPE_CHANGE_CLICKED: 'ONBOARD_BTYPE_CHANGE_CLICKED',
    ONBOARD_SUBTYPE_CLICKED: 'ONBOARD_SUBTYPE_CLICKED',
    ONBOARD_SAVE_BTYPE_BTN_CLICKED: 'ONBOARD_SAVE_BTYPE_BTN_CLICKED',
    ONBOARD_SAVE_BTYPE_SUCCESS: 'ONBOARD_SAVE_BTYPE_SUCCESS',
    ONBOARD_UPDATE_BTYPE_SUCCESS: 'ONBOARD_UPDATE_BTYPE_SUCCESS',
    ONBOARD_SAVE_BTYPE_FAILED: 'ONBOARD_SAVE_BTYPE_FAILED',
    ONBOARD_UPDATE_BTYPE_FAILED: 'ONBOARD_UPDATE_BTYPE_BTN_FAILED',
    ONBOARD_SAVE_BUSINESS_INFO_BTN_CLICKED: 'ONBOARD_SAVE_BUSINESS_INFO_BTN_CLICKED',
    ONBOARD_SAVE_BUSINESS_INFO_SUCCESS: 'ONBOARD_SAVE_BUSINESS_INFO_SUCCESS',
    ONBOARD_UPDATE_BUSINESS_INFO_SUCCESS: 'ONBOARD_UPDATE_BUSINESS_INFO_SUCCESS',
    ONBOARD_SAVE_BUSINESS_INFO_FAILED: 'ONBOARD_SAVE_BUSINESS_INFO_FAILED',
    ONBOARD_UPDATE_BUSINESS_INFO_FAILED: 'ONBOARD_UPDATE_BUSINESS_INFO_FAILED',
    ONBOARD_SAVE_BANK_INFO_BTN_CLICKED: 'ONBOARD_SAVE_BANK_INFO_BTN_CLICKED',
    ONBOARD_SAVE_BANK_INFO_SUCCESS: 'ONBOARD_SAVE_BANK_INFO_SUCCESS',
    ONBOARD_UPDATE_BANK_INFO_SUCCESS: 'ONBOARD_UPDATE_BANK_INFO_SUCCESS',
    ONBOARD_SAVE_BANK_INFO_FAILED: 'ONBOARD_SAVE_BANK_INFO_FAILED',
    ONBOARD_UPDATE_BANK_INFO_FAILED: 'ONBOARD_UPDATE_BANK_INFO_FAILED',
    ONBOARD_BANK_INFO_SKIPED: 'ONBOARD_BANK_INFO_SKIPED',
    ONBOARD_SAVE_PICKUP_ADDR_BTN_CLICKED: 'ONBOARD_SAVE_PICKUP_ADDR_BTN_CLICKED',
    ONBOARD_SAVE_PICKUP_ADDR_SUCCESS: 'ONBOARD_SAVE_PICKUP_ADDR_SUCCESS',
    ONBOARD_UPDATE_PICKUP_ADDR_SUCCESS: 'ONBOARD_UPDATE_PICKUP_ADDR_SUCCESS',
    ONBOARD_SAVE_PICKUP_ADDR_FAILED: 'ONBOARD_SAVE_PICKUP_ADDR_FAILED',
    ONBOARD_UPDATE_PICKUP_ADDR_FAILED: 'ONBOARD_UPDATE_PICKUP_ADDR_FAILED',
    ONBOARDING_DONE: 'ONBOARDING_DONE',

    BTN_MARKET: 'BTN_MARKET',
    BTN_HOME: 'BTN_HOME',
    BTN_SETTINGS: 'BTN_SETTINGS',
    BTN_TRANSACTIONS: 'BTN_TRANSACTIONS',
    HOME_CREATE_PICKUP_CLICKED: 'HOME_CREATE_PICKUP_CLICKED',

    STORE_CHANGED: 'STORE_CHANGED',
    STORE_CHANGE_FAILED: 'STORE_CHANGE_FAILED',
    BTN_ADD_LOCATION_CLICKED: 'BTN_ADD_LOCATION_CLICKED',
    BTN_EDIT_LOCATION_CLICKED: 'BTN_EDIT_LOCATION_CLICKED',
    BTN_DELETE_LOCATION_CLICKED: 'BTN_DELETE_LOCATION_CLICKED',
    UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
    UPDATE_LOCATION_FAILED: 'UPDATE_LOCATION_FAILED',
    ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
    ADD_LOCATION_FAILED: 'ADD_LOCATION_FAILED',
    BTN_GET_CURRENT_LOCATION: 'BTN_GET_CURRENT_LOCATION',
    LOGGED_OUT: 'LOGGED_OUT',
    BTN_EMAIL_SUPPORT: 'BTN_EMAIL_SUPPORT',
    BTN_CALL_SUPPORT: 'BTN_CALL_SUPPORT',
    AUTH_MODE: {
        GOOGLE: 'GOOGLE',
        FORM: 'FORM',
        FORM_EMAIL: 'FORM_EMAIL',
        FORM_MOBILE: 'FORM_MOBILE'
    },
    RESET_PASS_REQUESTED: 'RESET_PASS_REQUESTED',
    RESET_PASS_SUCCESS: 'RESET_PASS_SUCCESS',
    RESET_PASS_FAILED: 'RESET_PASS_FAILED',
    RESET_PASS_REQUEST_SUCCESS: 'RESET_PASS_REQUEST_SUCCESS',
    REST_PASS_REQUEST_FAILED: 'REST_PASS_REQUEST_FAILED',
    WALKTHROUGH_DONE: 'WALKTHROUGH_DONE',
    TNC_OPENED: 'TNC_OPENED',
    PRIVACY_POLICY_OPENED: 'PRIVACY_POLICY_OPENED',
    BTN_REQUEST_PICKUP_CLICKED: 'BTN_REQUEST_PICKUP_CLICKED',
    BTN_SHARE_APP_CLICKED: 'BTN_SHARE_APP_CLICKED',
    ITEM_CLICKED: 'ITEM_CLICKED',
    ITEM_QTY_EDITED: 'ITEM_QTY_EDITED',
    ITEM_ADDED: 'ITEM_ADDED',
    ITEM_REMOVED: 'ITEM_REMOVED',
    BTN_CHANGE_STORE: 'BTN_CHANGE_STORE',
    BTN_ADD_MORE_PRODUCT: 'BTN_ADD_MORE_PRODUCT',
    PICKUP_VALIDITY_FAILED: 'PICKUP_VALIDITY_FAILED',
    BTN_PLACE_PICKUP_REQUEST: 'BTN_PLACE_PICKUP_REQUEST',
    PLACE_PICKUP_REQUEST_SUCCESS: 'PLACE_PICKUP_REQUEST_SUCCESS',
    PLACE_PICKUP_REQUEST_FAILED: 'PLACE_PICKUP_REQUEST_FAILED',
    PICKUP_CANCELLED: 'PICKUP_CANCELLED',
    PICKUP_RESCHEDULED: 'PICKUP_RESCHEDULED',
    PICKUP_STATUS_CHANGED: 'PICKUP_STATUS_CHANGED',
    MARKET_SHOWED_INTEREST_IN_EXCHANGE: 'MARKET_SHOWED_INTEREST_IN_EXCHANGE',
    ACCOUNT_INFO_UPDATE_SUCCESS: 'ACCOUNT_INFO_UPDATE_SUCCESS',
    ACCOUNT_INFO_UPDATE_FAILED: 'ACCOUNT_INFO_UPDATE_FAILED',
    BUSINESS_INFO_UPDATE_SUCCESS: 'BUSINESS_INFO_UPDATE_SUCCESS',
    BUSINESS_INFO_UPDATE_FAILED: 'BUSINESS_INFO_UPDATE_FAILED',
    LOGIN_INFO_UPDATE_SUCCESS: 'LOGIN_INFO_UPDATE_SUCCESS',
    LOGIN_INFO_UPDATE_FAILED: 'LOGIN_INFO_UPDATE_FAILED',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',
    GEOCODE_FAILED: 'GEOCODE_FAILED',
    MAP_LOCATION_CHANGED: 'MAP_LOCATION_CHANGED',
    ADDRESS_SELECTED_FROM_SEARCH: 'ADDRESS_SELECTED_FROM_SEARCH',
    BTN_SUSTAIN_IMPACT: 'SUSTAIN_IMPACT',

    SCREENS: {
        TRANSACTION: 'TransactionTab',
        DASHBOARD: 'DashboardTab',
        SETTINGS: 'SettingsTab',
        MARKET: 'MarketTab',
        MARKET_SELECT_PRODUCT: 'MarketSelectProduct',
        MARKET_SELECT_PRODUCT_FROM_CAT: 'MarketSelectProductFromCategory',
        MARKET_MY_LISTINGS: 'MarketMyListings',
        MARKET_MY_LISTING_DETAILS: 'MarketMyListingDetails',
        MARKET_LEARN_MORE: 'MarketLearnMore',
        MARKET_ADD_EDIT_LISTING: 'MarketAddEditListing',


        ORDER_DETAILS: 'OrderDetails',
        ACCOUNT_INFO: 'AccountInformation',
        BUSINESS_PROFILE: 'BusinessProfile',
        LOGIN_INFO: 'LoginInformation',
        MANAGE_LOC: 'ManageLocations',
        SUPPORT: 'Support',
        ADD_EDIT_LOC: 'AddEditLocation',
        ONBOARDING: 'Onboarding',
        CHANGE_PASSWORD: 'ChangePassword',
        LEARN_MORE: 'LearnMore',
        SELECT_PRODUCTS: 'SelectProducts',
        SELECT_PRODUCTS_FROM_CAT: 'SelectProductsFromCat',
        SELECTED_PRODUCTS: 'SelectedProducts',
        SELECT_STORE: 'SelectStore',
        TIME_SLOT: 'SelectTimeSlot',
        PROFORMA_INVOICE: 'ProformaInvoice',
        SUSTAINABILITY: 'SustainabilityImpact',
        WALK_THROUGH: 'WalkTrough',
        FORGOT_PASSWORD_PAGE: 'ForgotPasswordPage',
        LOGIN_PAGE: 'LoginPage',
        SIGN_UP: 'SignupPage',
        OTP_VERIFY: 'OtpVerify',
        BANK_DETAIL: 'OnboardingBankDetail',
        BUSINESS_TYPE: 'OnboardingBusinessType',
        CONTACT_DETAIL: 'OnboardingBusinessInformation',
        PICKUP_ADDRESS: 'OnboardingPickupAddress',
        SEARCH_GOOGLE_LOCATION_MODAL: 'SEARCH_GOOGLE_LOCATION_MODAL'
    },
    CONTEXT: {
        ONBOARIDNG: 'ONBOARIDNG',
        REQUEST_PICKUP: 'REQUEST_PICKUP',
        MARKETPLACE: 'MARKETPLACE',
        FAB_BUTTON: 'FAB_BUTTON',
        TABS: 'TABS'
    },
    MARKETPLACE_BID_CLICKED: 'MARKETPLACE_BID_CLICKED',
    MARKETPLACE_OFFER_CLICKED: 'MARKETPLACE_OFFER_CLICKED',
    MARKETPLACE_QUALITY_REQUIREMENT_OPENED: 'MARKETPLACE_QUALITY_REQUIREMENT_OPENED',
    MARKETPLACE_MATCH_FOUND: 'MARKETPLACE_MATCH_FOUND',
    MARKETPLACE_MATCH_NOT_FOUND: 'MARKETPLACE_MATCH_NOT_FOUND',
    MARKETPLACE_LISTED_OFFER_OPENED: 'MARKETPLACE_LISTED_OFFER_OPENED',
    MARKETPLACE_LISTED_BID_OPENED: 'MARKETPLACE_LISTED_BID_OPENED',
    MARKETPLACE_EDIT_OFFER_CLICKED: 'MARKETPLACE_EDIT_OFFER_CLICKED',
    MARKETPLACE_EDIT_BID_CLICKED: 'MARKETPLACE_EDIT_BID_CLICKED',
    MARKETPLACE_DOWNLOAD_INVOICE: 'MARKETPLACE_DOWNLOAD_INVOICE',
    MARKETPLACE_CREATED_TRANSACTION_UPLOAD_IMAGE: 'MARKETPLACE_CREATED_TRANSACTION_UPLOAD_IMAGE',
    MARKETPLACE_MARK_DISPATCH_CLICKED: 'MARKETPLACE_MARK_DISPATCH_CLICKED',
    MARKETPLACE_ORDER_DISPATCHED_SUCCESS: 'MARKETPLACE_ORDER_DISPATCHED_SUCCESS',
    MARKETPLACE_SELLER_WEIGHBRIDGE_OPENED_BY_BUYER: 'MARKETPLACE_SELLER_WEIGHBRIDGE_OPENED_BY_BUYER',
    MARKETPLACE_BUYER_WEIGHBRIDGE_OPENED_BY_SELLER: 'MARKETPLACE_BUYER_WEIGHBRIDGE_OPENED_BY_SELLER',
    MARKETPLACE_DELIVERED_TRANSACTION_OPENED_BY_SELLER: 'MARKETPLACE_DELIVERED_TRANSACTION_OPENED_BY_SELLER'

};
// facebook only event
export const FacebookEvents = {
    StandardEvents: {
        EVENT_NAME_ACTIVATED_APP: 'EVENT_NAME_ACTIVATED_APP',
        EVENT_NAME_DEACTIVATED_APP: 'EVENT_NAME_DEACTIVATED_APP',
        EVENT_NAME_SESSION_INTERRUPTIONS: 'EVENT_NAME_SESSION_INTERRUPTIONS',
        EVENT_NAME_TIME_BETWEEN_SESSIONS: 'EVENT_NAME_TIME_BETWEEN_SESSIONS',
        EVENT_NAME_COMPLETED_REGISTRATION: 'EVENT_NAME_COMPLETED_REGISTRATION',
        EVENT_NAME_VIEWED_CONTENT: 'EVENT_NAME_VIEWED_CONTENT',
        EVENT_NAME_SEARCHED: 'EVENT_NAME_SEARCHED',
        EVENT_NAME_RATED: 'EVENT_NAME_RATED',
        EVENT_NAME_COMPLETED_TUTORIAL: 'EVENT_NAME_COMPLETED_TUTORIAL',
        EVENT_NAME_PUSH_TOKEN_OBTAINED: 'EVENT_NAME_PUSH_TOKEN_OBTAINED',
        EVENT_NAME_ADDED_TO_CART: 'EVENT_NAME_ADDED_TO_CART',
        EVENT_NAME_ADDED_TO_WISHLIST: 'EVENT_NAME_ADDED_TO_WISHLIST',
        EVENT_NAME_INITIATED_CHECKOUT: 'EVENT_NAME_INITIATED_CHECKOUT',
        EVENT_NAME_ADDED_PAYMENT_INFO: 'EVENT_NAME_ADDED_PAYMENT_INFO',
        EVENT_NAME_PURCHASED: 'EVENT_NAME_PURCHASED',
        EVENT_NAME_ACHIEVED_LEVEL: 'EVENT_NAME_ACHIEVED_LEVEL',
        EVENT_NAME_UNLOCKED_ACHIEVEMENT: 'EVENT_NAME_UNLOCKED_ACHIEVEMENT',
        EVENT_NAME_SPENT_CREDITS: 'EVENT_NAME_SPENT_CREDITS',
        EVENT_PARAM_CURRENCY: 'EVENT_PARAM_CURRENCY',
        EVENT_PARAM_REGISTRATION_METHOD: 'EVENT_PARAM_REGISTRATION_METHOD',
        EVENT_PARAM_CONTENT_TYPE: '',
        EVENT_PARAM_CONTENT_ID: '',
        EVENT_PARAM_SEARCH_STRING: 'EVENT_PARAM_SEARCH_STRING',
        EVENT_PARAM_SUCCESS: 'EVENT_PARAM_SUCCESS',
        EVENT_PARAM_MAX_RATING_VALUE: 'EVENT_PARAM_MAX_RATING_VALUE',
        EVENT_PARAM_PAYMENT_INFO_AVAILABLE: 'EVENT_PARAM_PAYMENT_INFO_AVAILABLE',
        EVENT_PARAM_NUM_ITEMS: 'EVENT_PARAM_NUM_ITEMS',
        EVENT_PARAM_LEVEL: 'EVENT_PARAM_LEVEL',
        EVENT_PARAM_DESCRIPTION: 'EVENT_PARAM_DESCRIPTION',
        EVENT_PARAM_SOURCE_APPLICATION: 'EVENT_PARAM_SOURCE_APPLICATION',
        EVENT_PARAM_VALUE_YES: 'EVENT_PARAM_VALUE_YES',
        EVENT_PARAM_VALUE_NO: 'EVENT_PARAM_VALUE_NO',
    },
    OtherEvents: {
        EVENT_NAME_CONTACT: 'EVENT_NAME_CONTACT'
    }
};

// firebase only events
export const FirebaseEvents = {
    // recommended by firebase platform
    // read more at https://support.google.com/firebase/answer/6317498?hl=en
    StandardEvents: {
        LOGIN: 'login',
        JOIN_GRP: 'join_group',
        PRESENT_OFFER: 'present_offer',
        SEARCH: 'search',
        SELECT_CONTENT: 'select_content',
        SHARE: 'share',
        SIGN_UP:  'sign_up',
        SPEND_VIRTUAL_CURRENCY: 'spend_virtual_currency',
        TUTORIAL_BEGIN: 'tutorial_begin',
        TUTORIAL_COMPLETE: 'tutorial_complete'
    }
};
