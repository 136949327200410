import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from '../../../pickup/pickup.model';
import {PRODUCT_ITEM_SELECTION_MODE} from '../../product.model';
import {TradeType} from '../../../shared/models/common.model';

@Component({
  selector: 'product-item-large',
  templateUrl: './product-item-large.component.html',
  styleUrls: ['./product-item-large.component.scss'],
})
export class ProductItemLargeComponent implements OnInit {
  @Input() item: Product;
  @Input() selectionMode: PRODUCT_ITEM_SELECTION_MODE;
  @Input() autoSelect: boolean;
  @Input() disable: boolean;
  @Input() selectedItemIdInGroup: string; // for single select
  @Input() textToHighlight: string;
  @Input() showPrice: boolean;
  @Output() onItemSelected ? = new EventEmitter<Product>();

  public tradeType = TradeType;
  public itemSelectionMode = PRODUCT_ITEM_SELECTION_MODE;

  constructor() { }

  ngOnInit() {}

  onItemSelect(event) {
    this.onItemSelected.emit(this.item);
  }

}
