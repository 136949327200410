import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SharedService} from '../../shared/shared.service';
import {AuthService} from '../services/auth.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private authService: AuthService,
                private router: Router,
                private sharedService: SharedService) {
    }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        console.log('AuthGuard#canActivate called');
        if (this.authService.isAuthenticated()) {
            return true;
        } else {
            this.sharedService.goToLoginOrWalkThrough();
            return false;
        }
    }
}
