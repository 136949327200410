import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {GooglePlus} from '@ionic-native/google-plus/ngx';
import {Platform} from '@ionic/angular';
import {UserAccount} from '../../customer/customer.model';
import {CustomerService} from '../../customer/customer.service';
import {API} from '../../shared/services/api.url';
import {environment} from '../../../environments/environment';
import {SignUp} from '../pages/sign-up/sign-up.model';
import {
    CreatePasswordRequest,
    LoginRequest,
    LoginSendOtpReqResp,
    LoginVerifyOtp,
    RegisterSendOtp,
    RegisterVerifyOtp
} from '../auth.model';
import {AppPreferences} from '@ionic-native/app-preferences/ngx';

const TOKEN_KEY = 'access_token';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authenticationState = new BehaviorSubject(false);
    accountInfo = new BehaviorSubject<UserAccount>(null);
    accessToken: string;
    privacyPolicyData = new BehaviorSubject(null);

    constructor(private httpClient: HttpClient,
                private storage: Storage,
                private googlePlus: GooglePlus,
                public plt: Platform,
                private customerService: CustomerService,
                private appPreferences: AppPreferences) {
    }

    checkToken() {
        this.storage.get(TOKEN_KEY).then(res => {
            if (res) {
                this.authenticationState.next(true);
            }
        });
    }

    public sendOtpLogin(reqBody: LoginSendOtpReqResp) {
        reqBody = this.trimStringsInObj(reqBody);
        return this.httpClient.post(`${API.LOGIN_SEND_OTP()}`, reqBody)
            .map((data: LoginSendOtpReqResp) => {
                return data;
            });
    }

    public privacyPolicyCheck() {
        return this.httpClient.get(`${API.PRIVACY_POLICY_DETAILS()}`).map((data: any) => {
            this.privacyPolicyData.next(data);
            return data;
        });
    }

    public getUserPolicyDetails() {
        return this.httpClient.get(`${API.USER_POLICY_DETAILS()}`).map((data: any) => {
            return data;
        });
    }

    public acceptPrivacyPolicy(reqBody) {
        return this.httpClient.post(`${API.ACCEPT_PRIVACY_POLICY()}`, reqBody).map((data: any) => {
            return data;
        });
    }
    public verifyOtpLogin(reqBody: LoginVerifyOtp) {
        reqBody = this.trimStringsInObj(reqBody);
        return this.httpClient.post(`${API.LOGIN_VERIFY_OTP()}`, reqBody)
            .map((data: any) => {
                if (data.id_token) {
                    this.accessToken = data.id_token;
                    this.storage.set(TOKEN_KEY, data.id_token).then();
                    this.authenticationState.next(true);
                }
                return data;
            });
    }

    public sendOtpRegistration(reqBody: RegisterSendOtp) {
        reqBody = this.trimStringsInObj(reqBody);
        return this.httpClient.post(`${API.REGISTER_SEND_OTP()}`, reqBody)
            .map((data: LoginSendOtpReqResp) => {
                return data;
            });
    }

    public verifyOtpRegistration(reqBody: RegisterVerifyOtp) {
        reqBody = this.trimStringsInObj(reqBody);
        return this.httpClient.post(`${API.REGISTER_VERIFY_OTP()}`, reqBody)
            .map((data: any) => {
                if (data.id_token) {
                    this.accessToken = data.id_token;
                    this.storage.set(TOKEN_KEY, data.id_token).then();
                    this.authenticationState.next(true);
                }
                return data;
            });
    }

    public createPassword(reqBody: CreatePasswordRequest) {
        return this.httpClient.post(`${API.CREATE_PASSWORD()}`, reqBody).map((data: any) => {
            if (data.id_token) {
                this.accessToken = data.id_token;
                this.storage.set(TOKEN_KEY, data.id_token).then();
                this.authenticationState.next(true);
            }
            return data;
        });
    }


    public loginUserServiceToken(loginForm) {
        const formData = new LoginRequest( loginForm.email, loginForm.password, true);
        return this.httpClient.post(`${API.LOGIN()}`, formData)
            .map((data: any) => {
                const respdata = data;
                console.log(respdata);
                if (respdata.id_token) {
                    this.accessToken = respdata.id_token;
                    this.storage.set(TOKEN_KEY, respdata.id_token).then();
                    this.authenticationState.next(true);
                }
                return respdata;
            });
    }

    public requestLoginUsingGoogleAuthCode(serverAuthCode: string, eventAssociated?) {
        return this.httpClient.post(`${API.GOOGLE_LOGIN()}`, {authCode: serverAuthCode, eventAssociated})
            .map((data: any) => {
                const respdata = data;
                console.log(respdata);
                if (respdata.id_token) {
                    this.accessToken = respdata.id_token;
                    this.storage.set(TOKEN_KEY, respdata.id_token).then();
                    this.authenticationState.next(true);
                }
                return respdata;
            });
    }

    public googleLogin() {
        const promise = new Promise((resolve, reject) => {
            if (this.plt.is('cordova')) {
                // To get a serverAuthCode, you must pass in your webClientId and set offline to true.
                // If offline is true, but no webClientId is provided, the serverAuthCode will NOT be requested.
                this.googlePlus.login({
                    webClientId: environment.google.web_client_id,
                    offline: true
                }).then(
                    (resp) => {
                        resolve(resp);
                    },
                    (err) => {
                        reject(err);
                    }
                );
            } else {
                reject('Invalid platform');
            }
        });
        return promise;
    }

    public signUpUser(signUpForm, eventAssociated?) {
        const formData = new SignUp(signUpForm.username, signUpForm.phone, signUpForm.password, eventAssociated);
        return this.httpClient.post(`${API.REGISTRATION()}`, formData, { responseType: 'text'})
            .map((data: any) => {
                /*const respdata = data;
                console.log(respdata);*/
                return data;
            });
    }

    public resendOtp(email) {
        const formData = {email};
        return this.httpClient.post(`${API.RESENDOTP()}`, formData)
            .map((data: any) => {
                const respdata = data;
                // console.log(respdata);
                return respdata;
            });
    }

    public verifyOtp(email, verificationCode) {
        const formData = {email, verificationCode};
        return this.httpClient.post(`${API.VERIFICATION()}`, formData)
            .map((data: any) => {
                return data;
            });
    }

    public resetPasswordRequest(resetmail) {
        const headers = new HttpHeaders({
            requestUrl: environment.forgotPasswordEndPoint
        });
        const options = { headers };
        return this.httpClient.post(`${API.RESET_PASSWORD_REQUEST()}`, resetmail, options)
            .map((data: any) => {
                // console.log(data);
                return data;
            });
    }

    public resetPassword(key, newPassword) {
        return this.httpClient.post(`${API.RESET_PASSWORD()}`, {key, newPassword})
            .map((data: any) => {
                // console.log(data);
                return data;
            });
    }

    logout() {
        return Promise.all([
            this.storage.remove(TOKEN_KEY),
            this.storage.get('WALKTHROUGH_DISPLAYED'),
            this.tryGoogleLogout(),
            this.storage.clear(),
            this.appPreferences.clearAll()
        ].map((promise) => {
            return promise.then((value) => {
                return {value, err: undefined, status: 'resolved' };
            }, (err) => {
                return {value: undefined, err, status: 'rejected' };
            });
        })).then( async (results) => {
            if (results && results[1] && results[1].status === 'resolved') {
                await this.storage.set('WALKTHROUGH_DISPLAYED', results[1].value);
            }
            this.accessToken = null;
            location.reload();
        }, () => {
            this.accessToken = null;
            location.reload();
        });
    }

    tryGoogleLogout() {
        return new Promise((resolve, reject) => {
            try {
                this.googlePlus.logout().then((data) => {
                    resolve(data);
                }, (err) => {
                    reject(err);
                });
            } catch (e) {
                reject(e);
                console.log(e);
            }
        });
    }

    isAuthenticated() {
        return this.authenticationState.value;
    }

    public setAccessTokenAndState(accessToken) {
        if (accessToken) {
            this.accessToken = accessToken;
            this.authenticationState.next(true);
            this.storage.set(TOKEN_KEY, accessToken).then();
        }
    }

    public fetchAccountDetails() {
        return this.httpClient.get(`${API.ACCOUNT()}`).map((data: UserAccount) => {
            // todo skip-dashboard-redirect data.registrationDone = false;
            // data.registrationDone = false;
            /*if (data.registrationDone) {*/
            this.customerService.setCustomerBsType(data);
            /*}*/
            this.accountInfo.next(data);
            return data;
        });
    }

    public getAccountInfo() {
        return this.accountInfo.value;
    }

    private trimStringsInObj(obj) {
        if (obj) {
            Object.keys(obj).map(k => obj[k] = typeof obj[k] === 'string' ? obj[k].trim() : obj[k]);
        }
        return obj;
    }
}
