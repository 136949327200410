<ion-grid padding>
  <ion-row>
    <ion-col size="12">
      <ion-img src="assets/imgs/location-illustration.svg"></ion-img>
    </ion-col>
    <ion-col size="12" text-center padding-start padding-end>
      <ion-text font-bold color="primary" class="main-text">
        Enable location services
      </ion-text>
    </ion-col>
    <ion-col size="12" text-center padding-start padding-end margin-bottom>
      <ion-text text-regular class="sub-text">
        Enabling Location services will help us to track your pickup location, status & more.
        <br/>
        Also please make sure that GPS is on.
      </ion-text>
    </ion-col>
    <ion-col size="12" margin-top margin-bottom>
      <ion-button (click)="enableLocationClicked()" class="enable-btn" color="primary" size="large" no-margin strong>
        Enable
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
