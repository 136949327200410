export interface ApplicationRoutes {
    DASHBOARD_CONTAINER: string;
    DASHBOARD_CONTAINER_HOME: string;
    DASHBOARD_CONTAINER_MARKET: string;
    DASHBOARD_CONTAINER_SETTINGS: string;
    DASHBOARD_CONTAINER_TRANSACTIONS: string;

    AUTH: string;
    AUTH_LOGIN: string;
    AUTH_REGISTRATION: string;
    AUTH_FORGOT_PASSWORD: string;
    AUTH_FORGOT_PASSWORD_RESET_SUCCESS: string;

    ON_BOARDING: string;
    ON_BOARDING_BS_TYPE_SELECT: string;
    ON_BOARDING_CONTACT_DETAILS: string;
    ON_BOARDING_BANK_DETAILS: string;
    ON_BOARDING_PICKUP_ADDR: string;

    CREATE_PICKUP: string;
    CREATE_PICKUP_SELECT_STORE: string;
    CREATE_PICKUP_SELECT_PRODUCTS: string;
    CREATE_PICKUP_SELECT_PRODUCTS_FROM_RECENTLY_USED: string;
    CREATE_PICKUP_SELECT_PRODUCTS_SELECT_FROM_CAT: string;
    CREATE_PICKUP_SELECTED_PRODUCTS_FROM_RECENTLY_USED_TRUE: string;
    CREATE_PICKUP_SELECTED_PRODUCTS: string;
    CREATE_PICKUP_SELECT_TIME_SLOT: string;
    CREATE_PICKUP_EDIT_STORE: string;
    CREATE_PICKUP_ADD_NEW_LOCATION: string;

    EDIT_STORE: string;
    SUSTAINABILITY_IMPACT_DETAILS: string;


    SETTINGS_SUPPORT: string;
    SETTINGS_LOGIN_INFORMATION: string;
    SETTINGS_LOGIN_INFORMATION_CHANGE_PASSWORD: string;
    SETTINGS_BUSINESS_PROFILE: string;
    SETTINGS_ACCOUNT_INFORMATION: string;
    SETTINGS_MANAGE_LOCATION: string;
    SETTINGS_MANAGE_LOCATION_ADD_EDIT_LOCATION: string;
    SETTINGS_MANAGE_LOCATION_ADD_EDIT_LOCATION_STORE_ID: string;

    WALK_THROUGH: string;

    TRANSACTIONS_DETAILS: string;


    // no-dashboard version
    MARKET: string;
    MARKET_LEARN_MORE: string;
    MARKET_ADD_LISTING: string;
    MARKET_LISTINGS: string;
    MARKET_LISTINGS_DETAILS: string;
    MARKET_LISTINGS_DETAILS_EDIT: string;
    MARKET_SELECT_PRODUCTS: string;
    MARKET_SELECT_PRODUCTS_SELECT_FROM_CAT: string;
}

export const MOBILE_ROUTES: ApplicationRoutes = {
    DASHBOARD_CONTAINER: '/mobile/main',
    DASHBOARD_CONTAINER_HOME: '/mobile/main/dashboard',
    DASHBOARD_CONTAINER_MARKET: '/mobile/main/market',
    DASHBOARD_CONTAINER_SETTINGS: '/mobile/main/settings',
    DASHBOARD_CONTAINER_TRANSACTIONS: '/mobile/main/transactions',

    AUTH: '',
    AUTH_LOGIN: '/mobile/auth/login',
    AUTH_REGISTRATION: '/mobile/auth/registration',
    AUTH_FORGOT_PASSWORD: '/mobile/auth/forgot-password',
    AUTH_FORGOT_PASSWORD_RESET_SUCCESS: '/mobile/auth/forgot-password/reset-success',

    ON_BOARDING: '/mobile/onboarding',
    ON_BOARDING_BS_TYPE_SELECT: '/mobile/onboarding/business-type',
    ON_BOARDING_CONTACT_DETAILS: '/mobile/onboarding/contact-details',
    ON_BOARDING_BANK_DETAILS: '/mobile/onboarding/bank-details',
    ON_BOARDING_PICKUP_ADDR: '/mobile/onboarding/pick-up-address',

    CREATE_PICKUP: '/mobile/pickup',
    CREATE_PICKUP_SELECT_STORE: '/mobile/pickup/select-store',
    CREATE_PICKUP_SELECT_PRODUCTS: '/mobile/pickup/select-products',
    CREATE_PICKUP_SELECT_PRODUCTS_FROM_RECENTLY_USED: '/mobile/pickup/recently-used-products',
    CREATE_PICKUP_SELECT_PRODUCTS_SELECT_FROM_CAT: '/mobile/pickup/select-store',
    CREATE_PICKUP_SELECTED_PRODUCTS_FROM_RECENTLY_USED_TRUE: '/mobile/pickup/selected-products',
    CREATE_PICKUP_SELECTED_PRODUCTS: '/mobile/pickup/selected-products',
    CREATE_PICKUP_SELECT_TIME_SLOT: '/mobile/pickup/select-timeslot',
    CREATE_PICKUP_EDIT_STORE: '/mobile/pickup/select-store',
    CREATE_PICKUP_ADD_NEW_LOCATION: '',

    EDIT_STORE: '',
    SUSTAINABILITY_IMPACT_DETAILS: '/mobile/sustainability-impact-details',


    SETTINGS_SUPPORT: '/mobile/settings/support',
    SETTINGS_LOGIN_INFORMATION: '/mobile/settings/login-information',
    SETTINGS_LOGIN_INFORMATION_CHANGE_PASSWORD: '/mobile/settings/login-information/change-password',
    SETTINGS_BUSINESS_PROFILE: '/mobile/settings/business-profile',
    SETTINGS_ACCOUNT_INFORMATION: '/mobile/settings/account-information',
    SETTINGS_MANAGE_LOCATION: '/mobile/settings/manage-locations',
    SETTINGS_MANAGE_LOCATION_ADD_EDIT_LOCATION: '/mobile/settings/manage-locations/add-edit-location',
    SETTINGS_MANAGE_LOCATION_ADD_EDIT_LOCATION_STORE_ID: '',

    WALK_THROUGH: '/mobile/walkthrough',

    TRANSACTIONS_DETAILS: '/mobile/transactions',

    // no-dashboard version
    MARKET: '/mobile/market',
    MARKET_LEARN_MORE: '/mobile/market/learn-more',
    MARKET_ADD_LISTING: '/mobile/market/add-listing',
    MARKET_LISTINGS: '/mobile/market/listings',
    MARKET_LISTINGS_DETAILS: '/mobile/market/listings/details',
    MARKET_LISTINGS_DETAILS_EDIT: '/mobile/market/listings/detail/edit',
    MARKET_SELECT_PRODUCTS: '/mobile/market/select-product',
    MARKET_SELECT_PRODUCTS_SELECT_FROM_CAT: '/mobile/market/select-product/select-from-cat/'

};

export const DESKTOP_ROUTES: ApplicationRoutes = {
    DASHBOARD_CONTAINER: '/main',
    DASHBOARD_CONTAINER_HOME: '',
    DASHBOARD_CONTAINER_MARKET: '',
    DASHBOARD_CONTAINER_SETTINGS: '',
    DASHBOARD_CONTAINER_TRANSACTIONS: '',

    AUTH: '',
    AUTH_LOGIN: '/auth/login',
    AUTH_REGISTRATION: '/auth/registration',
    AUTH_FORGOT_PASSWORD: '/auth/forgot-password',
    AUTH_FORGOT_PASSWORD_RESET_SUCCESS: 'auth/forgot-password/reset-success',

    ON_BOARDING: '/onboarding',
    ON_BOARDING_BS_TYPE_SELECT: '',
    ON_BOARDING_CONTACT_DETAILS: '',
    ON_BOARDING_BANK_DETAILS: '',
    ON_BOARDING_PICKUP_ADDR: '',

    CREATE_PICKUP: '/main/transactions/pickup',
    CREATE_PICKUP_SELECT_STORE: '/main/create-pickup-modal/select-store',
    CREATE_PICKUP_SELECT_PRODUCTS: '/main/create-pickup-modal/select-products',
    CREATE_PICKUP_SELECT_PRODUCTS_FROM_RECENTLY_USED: '/main/create-pickup-modal/recently-used-products',
    CREATE_PICKUP_SELECT_PRODUCTS_SELECT_FROM_CAT: '/main/create-pickup-modal/select-store',
    CREATE_PICKUP_SELECTED_PRODUCTS_FROM_RECENTLY_USED_TRUE: '/main/create-pickup-modal/selected-products?navigateToRecent=true',
    CREATE_PICKUP_SELECTED_PRODUCTS: '/main/create-pickup-modal/selected-products',
    CREATE_PICKUP_SELECT_TIME_SLOT: '/main/create-pickup-modal/select-timeslot',
    CREATE_PICKUP_EDIT_STORE: '/main/create-pickup-modal/select-store',
    CREATE_PICKUP_ADD_NEW_LOCATION: '/main/create-pickup-modal/add-location',

    EDIT_STORE: '',
    SUSTAINABILITY_IMPACT_DETAILS: '/main/sustainability-impact-details',


    SETTINGS_SUPPORT: '/main/settings/support',
    SETTINGS_LOGIN_INFORMATION: '/main/settings/login-information',
    SETTINGS_LOGIN_INFORMATION_CHANGE_PASSWORD: '/main/settings/change-password',
    SETTINGS_BUSINESS_PROFILE: '/main/settings/business-profile',
    SETTINGS_ACCOUNT_INFORMATION: '/main/settings/account-information',
    SETTINGS_MANAGE_LOCATION: '/main/settings/manage-locations',
    SETTINGS_MANAGE_LOCATION_ADD_EDIT_LOCATION: '/main/settings/add-edit-location',
    SETTINGS_MANAGE_LOCATION_ADD_EDIT_LOCATION_STORE_ID: '',

    WALK_THROUGH: '/auth/login',

    TRANSACTIONS_DETAILS: '/main/transactions',

    // no-dashboard version
    MARKET: '',
    MARKET_LEARN_MORE: '',
    MARKET_ADD_LISTING: '',
    MARKET_LISTINGS: '',
    MARKET_LISTINGS_DETAILS: '',
    MARKET_LISTINGS_DETAILS_EDIT: '',
    MARKET_SELECT_PRODUCTS: '',
    MARKET_SELECT_PRODUCTS_SELECT_FROM_CAT: ''
};
