import {Injectable} from '@angular/core';
import {Product} from '../pickup/pickup.model';
import {ProductAddToCartComponent} from './components/product-add-to-cart/product-add-to-cart.component';
import {ModalController} from '@ionic/angular';
import {API} from '../shared/services/api.url';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';

@Injectable()
export class ProductService {
    window = window;
    constructor(private modalController: ModalController,
                private httpClient: HttpClient) {
    }

    async showProductAddToCartModal(productData?: Product,
                                    setSelectedQty?: number,
                                    addToCartBtnText?: string,
                                    showBackdrop?: boolean): Promise<HTMLIonModalElement> {
        const productAddToCartModal = await this.modalController.create({
            component: ProductAddToCartComponent,
            cssClass: ['bottom-sheet desktop-qty-modal'],
            showBackdrop: showBackdrop != null ? showBackdrop : true,
            backdropDismiss: window.IS_MOBILE,
            componentProps: {
                showModalClose: true,
                product: productData,
                setSelectedQty,
                addToCartBtnText
            },
            animated: window.IS_MOBILE
        });
        await productAddToCartModal.present();
        return productAddToCartModal;
    }

    // for store
    fetchProductCategoriesForStore(storeId, channel: string) {
        return this.httpClient.get(
            `${API.CATALOG_ROOT_CATEGORIES()}?channelName=${channel}&depth=1&onlyCustomerItems=true&` +
            `nonExchangeCatalog=true&active=true&customerConfigured=false&locationId=${storeId}`)
            .map((data: any) => {
                return data;
            });
    }

    fetchProductCategoryDataForStore(catId, storeId, channel: string) {
        return this.httpClient.get(
            `${API.CATALOG_CATEGORY_DETAILS()}?onlyCustomerItems=true&channelName=${channel}&nonExchangeCatalog=true&` +
            `customerConfigured=false&active=true&locationId=${storeId}&categoryId=${catId}`)
            .map((data: any) => {
                return data;
            });
    }

    fetchProductCategoryDescendantItems(catId, storeId, channel: string) {
        return this.httpClient.get(
            `${API.CATALOG_GET_ALL_ITEMS()}?onlyCustomerItems=true&channelName=${channel}&nonExchangeCatalog=true&` +
            `customerConfigured=false&active=true&categoryId=${catId}&locationId=${storeId}`)
            .map((data: any) => {
                return data;
            });
    }

    fetchProductsForAllCategoryItems(storeId, channel: string) {
        return this.httpClient.get(
            `${API.CATALOG_GET_ALL_ITEMS()}?onlyCustomerItems=true&channelName=${channel}&nonExchangeCatalog=true&` +
            `customerConfigured=false&active=true&locationId=${storeId}`)
            .map((data: any) => {
                return data;
            });
    }

    fetchProductsByAttributeIds(attrValues: any, storeId, channel: string) {
        // attributeValueId=71&attributeValueId=74
        const attrsQuery = this.convertAttributeValToQueryParamString(attrValues);
        return this.httpClient.get(
            `${API.CATALOG_GET_ALL_ITEMS()}?${attrsQuery}&nonExchangeCatalog=true&customerConfigured=false` +
            `&channelName=${channel}&active=true&locationId=${storeId}`)
            .map((data: any) => {
                return data;
            });
    }

    fetchRecentlyUsedProducts(storeId, channel: string) {
        return this.httpClient.get(
            `${API.CATALOG_GET_ALL_ITEMS()}?nonExchangeCatalog=true&customerConfigured=true` +
            `&recentlyUsedItems=true&channelName=${channel}&active=true&locationId=${storeId}`)
            .map((data: any) => {
                return data;
            });
    }


    searchAndFetchProductsForStore(query, storeId, channel: string) {
        query = query ? query.toString() : '';
        return this.httpClient.get(
            `${API.CATALOG_GET_ALL_ITEMS()}?&nonExchangeCatalog=true&customerConfigured=false` +
            `&channelName=${channel}&active=true&locationId=${storeId}&query=` + query)
            .map((data: Product[]) => {
                return this.processSearchResult(data);
            });
    }


    // for market
    fetchProductCategoriesForMarket() {
        return this.httpClient.get(
            `${API.CATALOG_ROOT_CATEGORIES()}?channelName=Marketplace&depth=0&active=true`)
            .map((data: any) => {
                return data;
            });
    }

    fetchProductCategoryDataForMarket(catId) {
        return this.httpClient.get(
            `${API.CATALOG_CATEGORY_DETAILS()}?channelName=Marketplace&active=true&categoryId=${catId}`)
            .map((data: any) => {
                return data;
            });
    }

    fetchProductCategoryDescendantItemsForMarket(catId) {
        return this.httpClient.get(
            `${API.CATALOG_GET_ALL_ITEMS()}?channelName=Marketplace&active=true&categoryId=${catId}`)
            .map((data: any) => {
                return data;
            });
    }

    fetchProductsByAttributeIdsForMarket(attrValues: any) {
        if (typeof attrValues === 'object') {
            attrValues = _.pickBy(attrValues, _.identity);
        }
        // attributeValueId=71&attributeValueId=74
        const attrsQuery = this.convertAttributeValToQueryParamString(attrValues);
        return this.httpClient.get(
            `${API.CATALOG_GET_ALL_ITEMS()}?${attrsQuery}&channelName=Marketplace&active=true`)
            .map((data: Product[]) => {
                return data;
            });
    }

    searchAndFetchProductsForMarket(query) {
        query = query ? query.toString() : '';
        return this.httpClient.get(
            `${API.CATALOG_GET_ALL_ITEMS()}?channelName=Marketplace&active=true&query=` + query)
            .map((data: Product[]) => {
                return this.processSearchResult(data);
            });
    }


    // utilities
    convertAttributeValToQueryParamString(attrValues: any) {
        let queryString = '';
        Object.keys(attrValues).forEach((val, key) => {
            if (Array.isArray(attrValues[val])) {
                attrValues[val].forEach((subVal, subKey) => {
                    queryString = queryString + '&attributeValueId=' + subVal;
                });
            } else {
                queryString = queryString + '&attributeValueId=' + attrValues[val];
            }
        });
        return queryString;
    }

    processSearchResult(searchResult: Product[]) {
        const categorizedItemsMap = {};
        searchResult.forEach((item, key) => {
            if (item.categoryNodes && item.categoryNodes[0]) {
                if (!categorizedItemsMap[item.categoryNodes[0].name]) {
                    categorizedItemsMap[item.categoryNodes[0].name] = [];
                }
                categorizedItemsMap[item.categoryNodes[0].name].push(item);
            }
        });
        Object.keys(categorizedItemsMap).forEach((cat) => {
            if (categorizedItemsMap[cat].lenght === 0) {
                delete categorizedItemsMap[cat];
            }
        });
        return categorizedItemsMap;
    }


    // common
    fetchProductCategoryAttributes(catId, attrValueMap?: object, prevAttrSequenceNo?: number) {

        /*const dataToSend = ;*/
        if (attrValueMap && Object.keys(attrValueMap).length > 0 && (prevAttrSequenceNo || prevAttrSequenceNo === 0)) {
            /* url = `${API.CATALOG_CAT_ATTR()}?categoryId.equals=${catId}&active.equals=true&sort=srNo,asc`;
             _.each( attrValueMap, ( val, key ) => {
                 url = url + `&parentAttributeId.equals=${val}&parentValueId.equals=${key}`;
             } );*/
            const url = `${API.CATALOG_CAT_CHILD_ATTR()}`;
            return this.httpClient.post(url, {
                catId,
                valueMap: _.pickBy(attrValueMap, _.identity),
                previousSeqNo: prevAttrSequenceNo
            }).map((data: any) => {
                return data;
            });
        } else {
            const url = `${API.CATALOG_CAT_ATTR()}?categoryId.equals=${catId}&active.equals=true&sort=srNo,asc`;
            return this.httpClient.get(url)
                .map((data: any) => {
                    return data;
                });
        }

    }

    fetchProductDetails(prodId: string | number) {
        return this.httpClient.get(`${API.ITEM_DETAILS()}/${prodId}`);
    }

    fetchProductStats(prodId: string | number, channel: string, noOfRecords: number) {
        return this.httpClient.get(`${API.ITEM_STATS()}/${prodId}/?channel=${channel}&noOfRecords=${noOfRecords}`);
    }
}
