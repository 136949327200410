<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button no-margin no-box-shadow slot="icon-only" (click)="closeModal()">
        <ion-icon name="arrow-back" mode="ios"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-searchbar #searchBar debounce="300" autocomplete="true" (ionChange)="searchItems(searchBar.value)" mode="md" placeholder="Search by items name, ID" searchIcon=""></ion-searchbar>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row margin-top>
    <ion-col size="12" *ngFor="let category of categorizedItems| keys" class="search-result-item">
      <ion-text color="dark" margin-start class="category-label">{{category}}</ion-text>
      <product-item-large
              *ngFor="let item of categorizedItems[category]"
              [item]="item"
              [textToHighlight]="searchBar.value"
              (onItemSelected)="itemSelected($event)"
              [autoSelect]="isAutoSelectable(item)"
              [selectedItemIdInGroup]="selectedItemId"
              [disable]="markAutoSelectedItemDisable(item)"></product-item-large>
      <hr/>
    </ion-col>
    <ion-col margin-top size="12" text-center *ngIf="showNoItems && isEmpty(categorizedItems)">
      <ion-text font-bold text-medium color="primary">Sorry!! No items found.</ion-text>
    </ion-col>

  </ion-row>
</ion-content>
