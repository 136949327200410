import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {PopoverMenu} from '../../models/common.model';

@Component({
  selector: 'app-popover-menus',
  templateUrl: './popover-menus.component.html',
  styleUrls: ['./popover-menus.component.scss'],
})
export class PopoverMenusComponent implements OnInit {

  @Input() menus: PopoverMenu[];

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  menuClicked(menu: PopoverMenu) {
    this.popoverController.dismiss(menu.dismissData, menu.dismissRole ).then();
  }
}
