<div class="inner-content" padding="" >
   <div class="error-section">
      <div  class="modal-close-btn">
         <ion-icon name="close" (click)="closeModal()" ></ion-icon>
      </div>
      <div>
         <ion-grid>
            <ion-row>
               <ion-col size="8" offset="2" class="error-title">
                  <ion-text >Log in error</ion-text>
               </ion-col>
               <ion-col size="8" offset="2" class="error-info-text">
                  <ion-text>Before proceeding to log in to our web portal please log in to our mobile app to complete your registration.</ion-text>
               </ion-col>
               <ion-col size="8" offset="2" class="error-info-text">
                  <ion-text>You can log in once the setup is complete. Thank you!</ion-text>
               </ion-col>
            </ion-row>
         </ion-grid>
      </div>
   </div>
</div>