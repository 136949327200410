import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {SelectProductsPage} from './select-products.page';
import {ProductModule} from '../../product/product.module';
import {AuthGuardService} from '../../auth/guards/auth-guard.service';
import {PickupTimelineModule} from "../../desktop/shared/components/pickup-timeline/pickup-timeline.module";


const routes: Routes = [
    {
        path: '',
        component: SelectProductsPage,
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        ProductModule,
        PickupTimelineModule
    ],
    declarations: [SelectProductsPage],
    entryComponents: []
})
export class SelectProductsPageModule {
}
