/**
 *  This file should contain all the global constants we need across the application
 *  except on view
 *  **/

export const GLOBALS = {
    HTTP_APP_KEY: 'X-recykalApp',
    REGX: {
        // tslint:disable-next-line:max-line-length
        EMAIL: '\\S+@\\S+\\.\\S+', // in email we are allowing heading & trailing whitespaces
        MOBILE_NO: '^[ ]*[0-9]{10,10}[ ]*$', // number (min/max 10) without spaces allowing heading & trailing whitespaces.
        PASSWORD: '^$|^[A-Za-z0-9]+',
        ALPHA_NUMERIC_WITHOUT_SPACES: '^[ ]*[a-zA-Z0-9]+[ ]*$', // without space in between
        NAME: '^[ ]*[a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*[ ]*$',
        UPI_ID: '^\\w+@\\w+$',
        PIN_CODE: '^[ ]*[1-9][0-9]{5}[ ]*$',
        GSTIN: '\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}',
        PAN: '[A-Za-z]{5}\\d{4}[A-Za-z]{1}',
        IFSC: '^[ ]*[A-Za-z]{4}0[A-Z0-9a-z]{6}[ ]*$'
    },
    ERROR_MSG: {
        EXCHANGE_BETA: 'This feature is currently available only for our beta users. ' +
            'We will let you know when we make this available for all.'
    },
    OTHER: {
      PRIVACY_POLICY_URL: 'http://staging.recykal.com/privacy-policy.html'
    }
};
