import { API } from '../shared/services/api.url';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CustomerBsType } from './customer.model';
import * as _ from 'lodash';
import { Device } from '@ionic-native/device/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AppPreferences } from '@ionic-native/app-preferences/ngx';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic-native/device/ngx/index";
import * as i3 from "@ionic-native/app-version/ngx/index";
import * as i4 from "@ionic-native/app-preferences/ngx/index";
var CustomerService = /** @class */ (function () {
    function CustomerService(httpClient, device, appVersion, appPreferences) {
        this.httpClient = httpClient;
        this.device = device;
        this.appVersion = appVersion;
        this.appPreferences = appPreferences;
        this.customerProfile = new BehaviorSubject(null);
        this.customerRole = new BehaviorSubject(null);
        this.customerDefaultStore = new BehaviorSubject(null);
        this.customerDefaultStorePartnerInfo = new BehaviorSubject(null);
        this.customerDeviceInfo = new BehaviorSubject({});
        this.customerBsTypes = CustomerBsType;
    }
    CustomerService.prototype.fetchCustomerProfile = function () {
        var _this = this;
        return this.httpClient.get("" + API.PROFILE())
            .map(function (data) {
            _this.customerProfile.next(data);
            return data;
        });
    };
    CustomerService.prototype.setCustomerBsType = function (accountData) {
        if (accountData && accountData.profile) {
            if (accountData.profile.canSaleOnExchange) {
                this.customerRole.next(CustomerBsType.AGGREGATOR);
            }
            else if (accountData.profile.canBuyOnExchange) {
                this.customerRole.next(CustomerBsType.RECYCLER);
            }
            else if ((accountData.profile.canSaleOnExchange === false
                && accountData.profile.canBuyOnExchange === false)) {
                this.customerRole.next(CustomerBsType.BULK_GENERATOR);
            }
        }
    };
    CustomerService.prototype.fetchCustomerDefaultStore = function () {
        var _this = this;
        return this.httpClient.get("" + API.CUSTOMER_DEFAULT_STORE())
            .map(function (data) {
            _this.customerDefaultStore.next(data);
            return data;
        });
    };
    CustomerService.prototype.setCustomerDefaultStoreInfo = function (defaultPartnerInfo) {
        this.customerDefaultStorePartnerInfo.next(defaultPartnerInfo);
    };
    CustomerService.prototype.sendCustomerDeviceInfo = function (deviceToken) {
        var _this = this;
        _.merge(this.customerDeviceInfo.value, this.device);
        this.customerDeviceInfo.value.deviceToken = deviceToken;
        // below promise all implementation will behave differently. It will always resolve and wont stop executing promises
        // event if any of promise in between fails
        Promise.all([
            this.appVersion.getVersionNumber(),
            this.appPreferences.fetch('referrer')
        ].map(function (promise) {
            return promise.then(function (value) {
                return { value: value, err: undefined, status: 'fulfilled' };
            }, function (err) {
                return { value: undefined, err: err, status: 'rejected' };
            });
        })).then(function (results) {
            if (results[0].status === 'fulfilled' && results[0].value) {
                _this.customerDeviceInfo.value.appVersion = results[0].value;
            }
            if (results[1].status === 'fulfilled' && results[1].value) {
                _this.customerDeviceInfo.value.referrer = results[1].value;
            }
            _this.customerDeviceInfo.next(_this.customerDeviceInfo.value);
            console.log(results);
            return _this.httpClient.post("" + API.DEVICE_INFO(), _this.customerDeviceInfo.value).subscribe();
        }).catch(function () {
            return _this.httpClient.post("" + API.DEVICE_INFO(), _this.customerDeviceInfo.value).subscribe();
        });
    };
    CustomerService.prototype.getCustomerDeviceInfo = function () {
        return this.customerDeviceInfo.getValue();
    };
    CustomerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerService_Factory() { return new CustomerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Device), i0.ɵɵinject(i3.AppVersion), i0.ɵɵinject(i4.AppPreferences)); }, token: CustomerService, providedIn: "root" });
    return CustomerService;
}());
export { CustomerService };
