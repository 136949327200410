import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ModalController, NavController, ToastController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';

import {IframeComponent} from './components/iframe-component/iframe.component';
import {HelperService} from './services/helper.service';
import {API} from './services/api.url';
import {HttpClient} from '@angular/common/http';
import {OtpPageViews, PortalConfig} from './models/common.model';
import {ICity} from '../models/city.model';
import {Observable} from 'rxjs';
import 'rxjs-compat/add/operator/first';
import 'rxjs-compat/add/operator/map';
import {CustomerBsType, CustomerStats, UserAccount} from '../customer/customer.model';
import {Storage} from '@ionic/storage';
import {CustomerService} from '../customer/customer.service';
import {LocationPermissionComponent} from './components/location-permission/location-permission.component';
import {Device} from '@ionic-native/device/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {HtmlModalComponent} from './components/html-modal/html-modal.component';
import {AuthService} from '../auth/services/auth.service';
import {BUGSNAG_CLIENT} from '../third-party/bugsnag/bugsnag.module';
import {City} from '../pickup/pickup.model';
// tslint:disable-next-line:max-line-length
import {OnboardingPendingErrorComponent} from '../desktop/auth/pages/auth-container/components/onboarding-pending-error/onboarding-pending-error.component';


@Injectable({
    providedIn: 'root'
})
export class SharedService {
    httpCallInProgress = new BehaviorSubject(false);
    customerStats = new BehaviorSubject<CustomerStats>(null);
    cityList: ICity[];
    privacyPolicyModal: any;
    portalConfig: PortalConfig;
    public customerRole: CustomerBsType;
    public customerBsTypes = CustomerBsType;
    constructor(public toastController: ToastController,
                private router: Router,
                private authService: AuthService,
                private modalController: ModalController,
                private httpClient: HttpClient,
                private helper: HelperService,
                private storage: Storage,
                private customerService: CustomerService,
                private navCtrl: NavController,
                private device: Device,
                private activatedRoute: ActivatedRoute,
                private appVersion: AppVersion) {
    }

    setHttpCallInProgress(val: boolean) {
        this.httpCallInProgress.next(val);
    }

    getHttpCallInProgress() {
        return this.httpCallInProgress.getValue();
    }

    async openExternalUrl(url: string, title?: string) {
        this.privacyPolicyModal = await this.modalController.create({
            component: IframeComponent,
            componentProps: {
                url,
                showModalClose: true,
                title
            },
            cssClass: 'modal-page'
        });
        this.privacyPolicyModal.onDidDismiss().then(result => {
            // console.log(result);
        });
        return await this.privacyPolicyModal.present();
    }

    fetchCustomerStats() {
        return this.httpClient.get(`${API.STATS()}`)
            .map((data: any) => {
                this.customerStats.next(data);
                return data;
            });
    }

    fetchCustomerProfile() {
        return this.httpClient.get(`${API.PROFILE()}`)
            .map((data: any) => {
                this.customerStats.next(data);
                return data;
            });
    }

    getCities(): Observable<ICity[]> {
        return new Observable((observe) => {
            if (this.cityList && this.cityList.length > 0) {
                observe.next(this.cityList);
            } else {
                this.httpClient.get(`${API.CITIES()}`).first().subscribe((data: any) => {
                    this.cityList = data;
                    observe.next(data);
                });
            }
        });
    }

    getCitiesByZipCode(zipCode: string | number) {
        return new  Observable( (observe) => {
            if (zipCode && zipCode.toString().length > 0 ) {
                this.httpClient.get(`${API.CITIES()}/?zipcode=` + `${zipCode}`).subscribe( (data: City[]) => {
                    observe.next(data);
                });
            } else {
                observe.next([]);
            }
        });
    }

    searchCity(query: string) {
        return this.httpClient.get(`${API.CITIES()}/?query=` + `${query}`);
    }

    getPortalConfig(): Observable<PortalConfig> {
        return new Observable((observe) => {
            if (this.portalConfig) {
                observe.next(this.portalConfig);
            } else {
                this.httpClient.get(`${API.PORTAL_CONFIG()}`).first().subscribe((data: any) => {
                    this.portalConfig = data;
                    observe.next(data);
                });
            }
        });
    }

    // dont place this methods in auth service
    checkEmailVerificationPendingAndRedirect() {
        this.storage.get('REGISTERING_USER_EMAIL').then(email => {
            if (email) {
                this.router.navigate(['auth/sign-up/otp-verification'],
                    {queryParams: {viewToRender: OtpPageViews.UNVERIFIED}, replaceUrl: true}).then();
            } else {
                this.goToLoginOrWalkThrough();
            }
        }, () => {
            this.goToLoginOrWalkThrough();
        });
    }
    checkAuthAccountDetailsAndRedirect() {
        const promise = new Promise((resolve, reject) => {
            if (this.authService.isAuthenticated()) {
                this.authService.fetchAccountDetails().subscribe((data: UserAccount) => {
                    if (data) {
                        BUGSNAG_CLIENT.user = {
                            id: data.id,
                            email: data.email,
                            name: data.firstName + data.lastName,
                        };
                    }
                    if (this.authService.getAccountInfo() && this.authService.getAccountInfo().registrationDone) {
                        // if onboarding is done
                        this.navCtrl.navigateRoot(window.ROUTES.DASHBOARD_CONTAINER).then();

                    } else {
                        // on boarding not done
                        if (window.IS_MOBILE) {
                            this.navCtrl.navigateRoot(window.ROUTES.ON_BOARDING).then();
                        } else {
                            this.openOnBoardingError();
                        }

                    }
                    resolve();
                }, (e) => {
                    reject(e);
                });
            } else {
                reject('Not authorized');
            }
        });
        return promise;
    }
    goToLoginOrWalkThrough() {
        this.storage.get('WALKTHROUGH_DISPLAYED').then((data) => {
            if (data) {
                if (!window.IS_MOBILE) {
                    if (window.location.href.indexOf('/auth/forgot-password?key=') > -1) {
                        const keyVal = window.location.href.split('=');
                        this.router.navigate([window.ROUTES.AUTH_FORGOT_PASSWORD], {queryParams: {key: keyVal[1]}}).then();
                    } else {
                        this.router.navigate([window.ROUTES.WALK_THROUGH], {replaceUrl: true}).then();
                    }
                } else {
                    this.router.navigate([window.ROUTES.AUTH_LOGIN], {replaceUrl: true}).then();
                }
            } else {
                if (window.location.href.indexOf('/auth/forgot-password?key=') > -1) {
                    const keyVal = window.location.href.split('=');
                    this.router.navigate([window.ROUTES.AUTH_FORGOT_PASSWORD], {queryParams: {key: keyVal[1]}}).then();
                } else {
                    this.router.navigate([window.ROUTES.WALK_THROUGH], {replaceUrl: true}).then();
                }
            }
        }, () => {
            this.router.navigate([window.ROUTES.WALK_THROUGH], {replaceUrl: true}).then();
        });
    }


    async showRequestLocationPermissionModal(componentProps: any) {
        const locationPermissionModal = await this.modalController.create({
            component: LocationPermissionComponent,
            backdropDismiss: true,
            cssClass: 'modal-auto-center',
            componentProps
        });
        locationPermissionModal.present().then();
        return locationPermissionModal;
    }

    async showHtmlModal(title, htmlContent) {
        const htmlModal = await this.modalController.create({
            component: HtmlModalComponent,
            backdropDismiss: true,
            componentProps: {
                title,
                htmlContent
            }
        });
        await htmlModal.present();
        return htmlModal;
    }

    async  openOnBoardingError() {
        const onBoardError = await this.modalController.create({
            component: OnboardingPendingErrorComponent,
            cssClass: ['custom-bottom-sheet desktop-onboard-error'],
            showBackdrop: true,
            backdropDismiss: true,
        });
        onBoardError.present().then();
        onBoardError.onDidDismiss().then( res => {
            this.authService.logout();
            setTimeout(() => {
                location.reload();
            }, 500);
        });
    }


}



