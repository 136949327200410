import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-onboarding-pending-error',
  templateUrl: './onboarding-pending-error.component.html',
  styleUrls: ['./onboarding-pending-error.component.scss'],
})
export class OnboardingPendingErrorComponent implements OnInit {

  constructor(
      private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  closeModal() {
    this.modalCtrl.dismiss(undefined, null);
  }
}
