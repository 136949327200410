import {environment} from '../../../environments/environment';

export class API {

    static BASE_URL = environment.api_url;
    public static removeWhiteSpaces(urlString) {
        return urlString.replace(/^\s+|\s+$/gm, '');
    }

    public static PORTAL_CONFIG() {
        return this.removeWhiteSpaces(`/core/config/portal_config`);
    }

    public static PRIVACY_POLICY_DETAILS() {
        return this.removeWhiteSpaces(`/stakeholders/privacy-policy`);
    }

    public static USER_POLICY_DETAILS() {
        return this.removeWhiteSpaces(`/stakeholders/user-policy`);
    }

    public static REGISTER_SEND_OTP() {
        return this.removeWhiteSpaces(`/stakeholders/registration/send-otp`);
    }
    public static REGISTER_VERIFY_OTP() {
        return this.removeWhiteSpaces(`/stakeholders/registration/verify-otp`);
    }


    public static LOGIN() {
        return this.removeWhiteSpaces(`/stakeholders/customer/authenticate`);
    }
    public static LOGIN_SEND_OTP() {
        return this.removeWhiteSpaces(`/stakeholders/login/send-otp`);
    }
    public static LOGIN_VERIFY_OTP() {
        return this.removeWhiteSpaces(`/stakeholders/login/verify-otp`);
    }
    public static CREATE_PASSWORD() {
        return this.removeWhiteSpaces(`/stakeholders/account/create_password`);
    }



    public static GOOGLE_LOGIN() {
        return this.removeWhiteSpaces('/stakeholders/customer/googleLogin');
    }
    public static RESET_PASSWORD_REQUEST() {
        return this.removeWhiteSpaces(`/stakeholders/account/reset_password/init`);
    }
    public static RESET_PASSWORD() {
        return this.removeWhiteSpaces(`/stakeholders/account/reset_password/finish`);
    }
    public static REGISTRATION() {
        return this.removeWhiteSpaces(`/stakeholders/registration`);
    }
    public static RESENDOTP() {
        return this.removeWhiteSpaces(`/stakeholders/customer/resend-otp`);
    }
    public static VERIFICATION() {
        return this.removeWhiteSpaces(`/stakeholders/customer/verification`);
    }
    public static ACCOUNT() {
        return this.removeWhiteSpaces(`/stakeholders/account`);
    }
    public static ACCEPT_PRIVACY_POLICY() {
       return this.removeWhiteSpaces(`/stakeholders/user-policy`);
    }
    public static REGISTRATION_STATUS() {
        return this.removeWhiteSpaces(`/stakeholders/customer/registration-status`);
    }
    public static BUSINESS_TYPES() {
        return this.removeWhiteSpaces(`/stakeholders/business-types`);
    }
    public static CUSTOMER_BUSINESS_TYPES() {
        return this.removeWhiteSpaces(`/stakeholders/customer/business-type`);
    }
    public static CONTACT_DETAILS() {
        return this.removeWhiteSpaces(`/stakeholders/customer/contact-details`);
    }
    public static BANK_DETAILS() {
        return this.removeWhiteSpaces(`/stakeholders/customer/bank-details`);
    }
    public static PICKUP_ADDRESS() {
        return this.removeWhiteSpaces('/stakeholders/customer/pickup-address');
    }
    public static ONBOARDING_DONE() {
        return this.removeWhiteSpaces('/stakeholders/customer/mark-as-onboard');
    }
    public static PROFILE() {
        return this.removeWhiteSpaces(`/core/customer/profile`);
    }
    public static STATS() {
        return this.removeWhiteSpaces(`/core/customer/stats`);
    }
    public static PICKUPS() {
        return this.removeWhiteSpaces(`/core/customer/pickups`);
    }
    public static FRANCHISE() {
        return this.removeWhiteSpaces(`/stakeholders/customer/franchise`);
    }
    public static UPDATEPROFILE() {
        return this.removeWhiteSpaces(`/stakeholders/customer/update-profile`);
    }
    public static CHANGEPASSWORD() {
        return this.removeWhiteSpaces(`/stakeholders/account/change-password`);
    }
    public static LOCATIONS() {
        return this.removeWhiteSpaces(`/core/locations`);
    }
    public static TIME_SLOTS() {
        return this.removeWhiteSpaces(`/core/time-slots`);
    }
    public static MYPICKUPS() {
        return this.removeWhiteSpaces(`/core/customer/pickups`);
    }
    public static TRANSACTIONS() {
        return this.removeWhiteSpaces(`/core/customer/order-payments`);
    }
    public static CUSTOMERITEMS() {
        return this.removeWhiteSpaces(`/core/customer/items`);
    }
    public static CITIES() {
        return this.removeWhiteSpaces(`/core/public/cities`);
    }
    public static CATALOG() {
        return this.removeWhiteSpaces(`/core/customer/catalog`);
    }
    public static CATALOG_ROOT_CATEGORIES() {
        return this.removeWhiteSpaces(`/catalog/categories`);
    }
    public static CATALOG_CAT_ATTR() {
        return this.removeWhiteSpaces(`/catalog/attributes`);
    }

    public static CATALOG_CAT_CHILD_ATTR() {
        return this.removeWhiteSpaces(`/catalog/child-attribute`);
    }

    public static CATALOG_GET_ALL_ITEMS() {
        return this.removeWhiteSpaces(`/catalog/items`);
    }

    public static CATALOG_CATEGORY_DETAILS() {
        return this.removeWhiteSpaces(`/catalog/sub-categories-and-items`);
    }
    public static PICKUPVALIDITY() {
        return this.removeWhiteSpaces(`/core/customer/check-pickup-validity`);
    }
    public static ORDERS() {
        return this.removeWhiteSpaces(`/core/customer/business/orders`);
    }
    public static CUSTOMER_DEFAULT_STORE() {
        return this.removeWhiteSpaces(`/core/customer/default-location`);
    }
    public static MANAGE_CATEGEORIES() {
        return this.removeWhiteSpaces(`https://wchat.freshchat.com/app/services/app/webchat/
        b2034893-6df5-445a-9bb2-416603c06386/faq/category?platform=web&locales=en-US%2Cen-US&since=&lastLocaleId=999`);
    }
    public static PROFORMA_INVOICE() {
        return this.removeWhiteSpaces(`/core/orders/proforma-invoice/pdf/`);
    }
    public static DOCUMENTS() {
        return this.removeWhiteSpaces(`/core/documents`);
    }
    public static DELETE_DOC() {
        return this.removeWhiteSpaces(`/core/documents/remove`);
    }
    public static ALL_BIDS() {
        return this.removeWhiteSpaces(`/catalog/listings/bids`);
    }
    public static ALL_OFFERS() {
        return this.removeWhiteSpaces(`/catalog/listings/offers`);
    }
    public static ALL_BIDS_BY_CAT() {
        return this.removeWhiteSpaces(`/catalog/listings/stat/bids/category`);
    }
    public static ALL_OFFERS_BY_CAT() {
        return this.removeWhiteSpaces(`/catalog/listings/stat/offers/category`);
    }
    public static BID_ITEMS() {
        return this.removeWhiteSpaces(`/catalog/listings/bids/item`);
    }

    public static OFFER_ITEMS() {
        return this.removeWhiteSpaces(`/catalog/listings/offers/item`);
    }
    public static ITEM_DETAILS() {
        return this.removeWhiteSpaces(`/catalog/items`);
    }
    public static ITEM_STATS() {
        return this.removeWhiteSpaces(`/core/orders/stat/item`);
    }

    public static ALL_CATEGORIES() {
        return this.removeWhiteSpaces(`/core/public/categories`);
    }
    public static DEVICE_INFO() {
        return this.removeWhiteSpaces(`/stakeholders/devices`);
    }
    public static SIGNED_URL() {
        return this.removeWhiteSpaces(`/core/documents/signed-url`);
    }

    public static CORE_DOCUMENTS_ATTACH() {
        return this.removeWhiteSpaces(`/core/documents/attach`);
    }

    public static MARKET_BID_STATS() {
        return this.removeWhiteSpaces(`/catalog/listings/stat/bids/item`);
    }
    public static MARKET_OFFER_STATS() {
        return this.removeWhiteSpaces(`/catalog/listings/stat/offers/item`);
    }
    public static MARKET_OFFER_TRANSACTIONS() {
        return this.removeWhiteSpaces(`/core/orders/sale-orders`);
    }
    public static MARKET_BID_TRANSACTIONS() {
        return this.removeWhiteSpaces(`/core/orders/purchase-orders`);
    }
    public static LISTING_STATUSES() {
        return this.removeWhiteSpaces(`/catalog/listing-statuses`);
    }


}
