import {Component, Input, OnInit} from '@angular/core';
import {RemoteConfig} from '../../shared/models/common.model';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-app-update-modal',
  templateUrl: './app-update-modal.component.html',
  styleUrls: ['./app-update-modal.component.scss'],
})
export class AppUpdateModalComponent implements OnInit {
  @Input() updateInfo: RemoteConfig;
  constructor(private platform: Platform) { }

  ngOnInit() {}

  updateClicked() {
    if (this.updateInfo && this.updateInfo.app_install_link) {
      if (this.platform.is('android') && this.updateInfo.app_install_link.android) {
        window.open(this.updateInfo.app_install_link.android, '_system');
      } else if (this.platform.is('ios') && this.updateInfo.app_install_link.ios) {
        window.open(this.updateInfo.app_install_link.ios, '_system');
      }
    }
  }
}
