import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../shared/services/api.url';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Storage } from '@ionic/storage';
import { CartData, CartSummery, PlaceOrderRequest } from './pickup.model';
import { Platform } from '@ionic/angular';
import * as _ from 'lodash';
import { OrderList } from '../models/OrderList';
import { ProductService } from '../product/product.service';
import { HelperService } from '../shared/services/helper.service';
import { FacebookEvents, MyEvents } from '../shared/my-events.const';
import { AnalyticsService } from '../shared/services/analytics.service';
import { Router } from '@angular/router';
import { TradeType } from '../shared/models/common.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/storage";
import * as i3 from "@ionic/angular";
import * as i4 from "../shared/services/helper.service";
import * as i5 from "../product/product.service";
import * as i6 from "../shared/services/analytics.service";
import * as i7 from "@angular/router";
var PickupService = /** @class */ (function () {
    function PickupService(httpClient, storage, plt, helper, productService, analyticsService, router) {
        var _this = this;
        this.httpClient = httpClient;
        this.storage = storage;
        this.helper = helper;
        this.productService = productService;
        this.analyticsService = analyticsService;
        this.router = router;
        this.catalogData = new BehaviorSubject([]);
        this.cartData = new BehaviorSubject(null);
        this.cities = new BehaviorSubject([]);
        this.cartDataSubscription = new Subscription();
        this.tradeTypes = TradeType;
        plt.ready().then(function () {
            _this.storage.get('active_pickup_cart').then(function (data) {
                if (data) {
                    _this.setCartData(data);
                }
                _this.setSyncOnCartDataWithStorage();
            }, function (error) {
                _this.setSyncOnCartDataWithStorage();
            });
        });
    }
    PickupService.prototype.setSyncOnCartDataWithStorage = function () {
        var _this = this;
        this.cartDataSubscription = this.cartData.subscribe(function (data) {
            if (data) {
                _this.storage.set('active_pickup_cart', data).then(function () {
                    console.log('Data Saved successfully to storage');
                }, function (error) {
                    console.log('Failed to save data to storage', error);
                });
            }
        });
    };
    PickupService.prototype.getTimeSlots = function (franchiseId, storeZip) {
        return this.httpClient.get(API.TIME_SLOTS() + "?franchiseId=" + franchiseId + "&zip=" + storeZip)
            .map(function (data) {
            return data;
        });
    };
    /*getCatalogs(storeid) {
        return this.httpClient.get(`${API.CATALOG()}?onlyCustomerItems=true&storeId=${storeid}`)
            .map((data: any) => {
                this.catalogData.next(data);
                return data;
            });
    }*/
    PickupService.prototype.getOrders = function (orderRequest) {
        var _this = this;
        return this.httpClient.post("" + API.ORDERS(), orderRequest)
            .map(function (data) {
            _this.getOrdersByFilters(false).subscribe();
            return data;
        });
    };
    PickupService.prototype.checkPickupValidity = function (cartobj) {
        return this.httpClient.post("" + API.PICKUPVALIDITY(), cartobj)
            .map(function (data) {
            // console.log(data);
            return data;
        }, function (error) {
            // console.log(error);
            return error.status;
        });
    };
    PickupService.prototype.setCartData = function (cartData) {
        this.recalculateCartSummery(cartData.cartItems, cartData.cartSummery);
        this.cartData.next(cartData);
    };
    PickupService.prototype.emptyCart = function () {
        this.cartData.next(new CartData(null, [], new CartSummery(0, 0, 0, 0, false)));
        this.catalogData.next([]);
    };
    PickupService.prototype.setCartStore = function (storeData) {
        var _this = this;
        // this.cartData.next({...this.cartData.value, storeData: storeData});
        // empty cart when store is changed
        // also fetch time slots
        var promise = new Promise(function (resolve, reject) {
            _this.getTimeSlots(storeData.partnerInfo.id, storeData.addressInfo.zipcode).subscribe(function (data) {
                storeData.timeslots = data;
                if (_this.cartData.value && _this.cartData.value.storeData && _this.cartData.value.storeData.id === storeData.id) {
                    _this.cartData.next(new CartData(storeData, _this.cartData.value.cartItems, _this.cartData.value.cartSummery));
                }
                else {
                    _this.catalogData.next([]);
                    _this.cartData.next(new CartData(storeData, [], new CartSummery(0, 0, 0, 0, false)));
                }
                resolve();
            }, function (err) {
                reject(err);
            });
        });
        return promise;
    };
    PickupService.prototype.setCartItems = function (cartItems) {
        // this.cartData.value.cartSummery = this.recalculateCartSummery(cartItems, this.cartData.value.cartSummery);
        this.cartData.next(tslib_1.__assign({}, this.cartData.value, { cartItems: cartItems, cartSummery: this.recalculateCartSummery(cartItems, this.cartData.value.cartSummery) }));
    };
    PickupService.prototype.setCartItem = function (item) {
        // check if item already exists else push
        if (this.cartData.value.cartItems.filter(function (pr) {
            return pr.id === item.id;
        }).length > 0) {
            var elementPos = this.cartData.value.cartItems.map(function (x) {
                return x.id;
            }).indexOf(item.id);
            this.cartData.value.cartItems[elementPos] = item;
        }
        else {
            this.cartData.value.cartItems.push(item);
        }
        this.cartData.value.cartSummery = this.recalculateCartSummery(this.cartData.value.cartItems, this.cartData.value.cartSummery);
        this.cartData.next(tslib_1.__assign({}, this.cartData.value, { cartItems: this.cartData.value.cartItems, cartSummery: this.cartData.value.cartSummery }));
    };
    PickupService.prototype.removeCartItem = function (itemId) {
        this.cartData.value.cartItems = this.cartData.value.cartItems.filter(function (pr) {
            return pr.id !== itemId;
        });
        this.cartData.value.cartSummery = this.recalculateCartSummery(this.cartData.value.cartItems, this.cartData.value.cartSummery);
        this.cartData.next(tslib_1.__assign({}, this.cartData.value, { cartItems: this.cartData.value.cartItems, cartSummery: this.cartData.value.cartSummery }));
    };
    PickupService.prototype.recalculateCartSummery = function (cartItems, cartSummery) {
        var _this = this;
        if (cartSummery && cartItems.length > 0) {
            cartSummery = new CartSummery(0, 0, 0, 0, false);
            cartItems.forEach(function (value, key) {
                if (value.tradeType === _this.tradeTypes.S) {
                    cartSummery.totalPriceToPay = cartSummery.totalPriceToPay + (value.price * value.quantity);
                }
                else {
                    cartSummery.totalPrice = cartSummery.totalPrice + (value.price * value.quantity);
                }
                cartSummery.totalQty = cartSummery.totalQty + value.quantity;
                cartSummery.totalWeight = cartSummery.totalWeight + ((value.weight * value.quantity) / 1000);
                if (value.tradeType === 'S') {
                    cartSummery.containsItemWithNegativePricing = true;
                }
            });
            return cartSummery;
        }
        else {
            return new CartSummery(0, 0, 0, 0, false);
        }
    };
    PickupService.prototype.recalculateCartSummeryAndSave = function () {
        if (this.cartData.value && this.cartData.value.cartItems.length > 0) {
            // this.cartData.value.cartSummery =
            // this.recalculateCartSummery(this.cartData.value.cartItems, this.cartData.value.cartSummery);
            this.cartData.next(tslib_1.__assign({}, this.cartData.value, { cartSummery: this.recalculateCartSummery(this.cartData.value.cartItems, this.cartData.value.cartSummery) }));
        }
    };
    PickupService.prototype.getCartDetails = function () {
        this.cartData.getValue();
    };
    PickupService.prototype.getOrdersByFilters = function (isLoadMore) {
        var _this = this;
        return new Observable(function (observer) {
            var orderObj = new OrderList();
            if (isLoadMore) {
                orderObj.page++;
            }
            if (orderObj && orderObj.result.length && !isLoadMore) {
                observer.next(orderObj);
                return observer.complete();
            }
            _this.query({
                page: orderObj.page,
                size: 10,
                sort: 'createdDate,desc',
            }).subscribe(function (orders) {
                orderObj.totalItemCount = _.toNumber(orders.headers.get('X-Total-Count'));
                _.merge(orderObj.result, orders.body);
                if (isLoadMore) {
                    observer.next(orders.body);
                    observer.complete();
                }
                else {
                    observer.next(orderObj);
                    observer.complete();
                }
            });
        });
    };
    PickupService.prototype.query = function (filterParams) {
        var options = new HttpParams({ fromObject: filterParams });
        return this.httpClient.get('/core/customer/orders', {
            params: options,
            observe: 'response'
        }).map(function (data) {
            return data;
        });
    };
    PickupService.prototype.setCartTimeslot = function (selectedTime) {
        this.cartData.next(tslib_1.__assign({}, this.cartData.value, { selectedTimeslot: selectedTime }));
    };
    PickupService.prototype.getPlaceOrderRequest = function () {
        var placeOrderRequest = new PlaceOrderRequest([], this.cartData.value.storeData.addressInfo, this.cartData.value.selectedTimeslot, this.cartData.value.storeData.id);
        this.cartData.value.cartItems.forEach(function (value) {
            placeOrderRequest.orderItems.push({
                itemId: value.id,
                qty: value.quantity,
                total: value.total,
            });
        });
        return placeOrderRequest;
    };
    PickupService.prototype.getCatalogRootCategoriesForSelectedStore = function (channel) {
        var _this = this;
        if (this.cartData.value &&
            this.cartData.value.storeData &&
            this.catalogData.value.length === 0) {
            this.productService.fetchProductCategoriesForStore(this.cartData.value.storeData.id, channel).subscribe(function (data) {
                data = data ? _.orderBy(data, 'seq', 'asc') : data;
                _this.catalogData.next(data);
            }, function (err) {
            });
        }
    };
    PickupService.prototype.getProductCategoryDataForSelectedStore = function (catData, pathToCat, channel) {
        var _this = this;
        if (this.cartData.value &&
            this.cartData.value.storeData &&
            catData &&
            catData.items === undefined &&
            catData.subCategories === undefined) {
            this.productService.fetchProductCategoryDataForStore(catData.id, this.cartData.value.storeData.id, channel).subscribe(function (data) {
                if (data) {
                    data.items = data.items ? _.orderBy(data.items, 'seq', 'asc') : data;
                    data.subCategories = data.subCategories ? _.orderBy(data.subCategories, 'seq', 'asc') : data;
                    // this.catalogData.next(data);
                    _this.helper.updateObject(_this.catalogData.value, data.items, pathToCat + '>items');
                    _this.helper.updateObject(_this.catalogData.value, data.subCategories, pathToCat + '>subCategories');
                    _this.catalogData.next(_this.catalogData.value);
                }
            }, function (err) {
            });
        }
    };
    PickupService.prototype.getProductCategoryDescendentItemsForSelectedStore = function (catData, pathToCat, channel) {
        var _this = this;
        if (this.cartData.value &&
            this.cartData.value.storeData &&
            (catData.items === undefined || (catData.items && catData.items.length === 0))) {
            this.productService.fetchProductCategoryDescendantItems(catData.id, this.cartData.value.storeData.id, channel).subscribe(function (data) {
                if (data) {
                    data.items = data.items ? _.orderBy(data.items, 'seq', 'asc') : data;
                    /*data.items.forEach((item, key) => {
                        if (key % 2) {
                            item.negativePricing = true;
                        }
                    });*/
                    _this.helper.updateObject(_this.catalogData.value, data, pathToCat + '>items');
                    // pushing empty cat array is required to show not items message in listing.
                    _this.helper.updateObject(_this.catalogData.value, [], pathToCat + '>subCategories');
                    _this.catalogData.next(_this.catalogData.value);
                }
            }, function (err) {
            });
        }
    };
    PickupService.prototype.getProductsByAttributeIdsForSelectedStore = function (attrValues) {
        return this.productService.fetchProductsByAttributeIds(attrValues, this.cartData.value.storeData.id, 'B2B');
    };
    PickupService.prototype.getRecentlyUsedProductsForSelectedStore = function () {
        return this.productService.fetchRecentlyUsedProducts(this.cartData.value.storeData.id, 'B2B').toPromise();
    };
    PickupService.prototype.showProductQtyEditForPickupRequest = function (productData, navigateToRecent, showBackdrop) {
        var _this = this;
        return this.productService.showProductAddToCartModal(productData, null, null, showBackdrop).then(function (modalElement) {
            modalElement.onDidDismiss().then(function (result) {
                if (result.data) {
                    if (result.data.selectedQty > 0) {
                        productData.quantity = result.data.selectedQty;
                        productData.total = Number(productData.price) * productData.quantity;
                        _this.setCartItem(productData);
                        _this.analyticsService.logEvent(MyEvents.ITEM_ADDED, {
                            itemName: productData.name, itemQty: productData.quantity,
                            itemTotal: productData.total, context: MyEvents.CONTEXT.REQUEST_PICKUP
                        });
                        _this.analyticsService.logFacebookEvent(FacebookEvents.StandardEvents.EVENT_NAME_ADDED_TO_CART, {
                            CONTENT_TYPE: 'PRODUCT_ITEM',
                            CONTENT_ID: productData.id,
                            CONTENT: productData.name,
                            CURRENCY: 'INR'
                        });
                        if (navigateToRecent) {
                            _this.router.navigate([window.ROUTES.CREATE_PICKUP_SELECTED_PRODUCTS_FROM_RECENTLY_USED_TRUE], { queryParamsHandling: 'preserve' }).then();
                        }
                        else {
                            _this.router.navigate([window.ROUTES.CREATE_PICKUP_SELECTED_PRODUCTS], { queryParamsHandling: 'preserve' }).then();
                        }
                    }
                    else {
                        _this.helper.showToast('Please input valid quantity', 'dark').then();
                    }
                }
            });
            return modalElement;
        });
    };
    PickupService.prototype.searchProductsForSelectedStore = function (query) {
        return this.productService.searchAndFetchProductsForStore(query, this.cartData.value.storeData.id, 'B2B');
    };
    PickupService.prototype.checkDescendentItemsExistsInProductCategory = function (pathToCat) {
        /*if (this.catalogData.value) {
            const catData = eval('this.catalogData.value' + pathToCat);
            if (catData && catData.items) {
                return !(catData.items.length);
            } else {
                return false;
            }
        } else {
            return false;
        }*/
    };
    PickupService.prototype.byString = function (o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, ''); // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            }
            else {
                return;
            }
        }
        return o;
    };
    PickupService.prototype.ngOnDestroy = function () {
        if (this.cartDataSubscription) {
            this.cartDataSubscription.unsubscribe();
        }
    };
    PickupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PickupService_Factory() { return new PickupService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.Platform), i0.ɵɵinject(i4.HelperService), i0.ɵɵinject(i5.ProductService), i0.ɵɵinject(i6.AnalyticsService), i0.ɵɵinject(i7.Router)); }, token: PickupService, providedIn: "root" });
    return PickupService;
}());
export { PickupService };
