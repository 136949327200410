<ion-header no-border no-shodow *ngIf="window.IS_MOBILE">
    <ion-toolbar no-border>
        <ion-buttons slot="start">
            <ion-back-button [icon]="'ios-arrow-back'"  [text]="''">
            </ion-back-button>
        </ion-buttons>
        <ion-title class="" modal-heading text-center>
            <ion-text>All Products</ion-text>
            <ion-row>
                <ion-col no-padding-left no-padding-right text-wrap size="12" text-medium="" style="line-height: 14px;"
                         color-medium *ngIf="cartItems?.length == 0">
                    Tap on product to get started with your pick up request
                </ion-col>
                <ion-col no-padding-left no-padding-right text-wrap size="12" text-medium="" style="line-height: 14px;"
                         color-medium *ngIf="cartItems?.length > 0">
                    Tap to add another product to your pick up request
                </ion-col>
            </ion-row>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button no-margin slot="icon-only" no-box-shadow (click)="openSearchItemsModal()">
                <ion-icon size="large" mode="ios" name="search"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content padding no-padding-top="">
    <ion-grid no-padding-left no-padding-right>
        <ion-row *ngIf="activePageView == SelectProductsPageViews.CATEGORIZED">
            <ion-col size="12" no-padding-left no-padding-right>
                <product-list-lazy
                        [showItemPrice]="true"
                        [allowLoadingSubCatForSpecificCats]="['E-Waste']"
                        [catalogDataObservable]="catalogDataObservable"
                        (onGetRootCategories)="getRootCategories()"
                        (onGetCategoryData)="getCategoryData($event[0], $event[1])"
                        (onGetCategoryDescendantItems)="getCategoryDescendantItems($event[0], $event[1])"
                        (onLeafWithAttrItemsClicked)="handelLeafClick($event)"
                        (onItemSelected)="categorizedProductSelected($event)"
                        [resetSelection]="resetSelection"
                        [itemSelectionMode]="productItemSelectionMode.SINGLE_SELECT"
                        [autoSelectedItems]="cartItems"
                        [disableAutoSelected]="true">
                </product-list-lazy>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer padding no-border class="desktop-footer-section" *ngIf="!window.IS_MOBILE">

</ion-footer>

