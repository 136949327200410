import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CitySearchModalComponent} from './city-search-modal.component';


import {RouterModule} from '@angular/router';


@NgModule({
    declarations: [CitySearchModalComponent],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    exports: [CitySearchModalComponent],
    entryComponents: [CitySearchModalComponent]
})
export class CitySearchModalComponentModule {
}
